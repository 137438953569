import React from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import UserIdentityInformation from './Components/UserIdentityInformation';
import { ABSHER_INFORMATION, PROFILE_INFORMATION } from 'Constants/QueriesKeys';
import UserIdentityInformationSkeleton from './Skeletons/UserIdentityInformationSkeleton';
import UpdatePassword from './Components/UpdatePassword';

export default function MyProfilePage() {
    const informationQuery = useQuery(PROFILE_INFORMATION, () =>
        http.get('/profile').then((response) => response.data),
    );

    const absherInformationQuery = useQuery(ABSHER_INFORMATION, () =>
        http.get('/absher').then((response) => response.data.data),
    );

    if (informationQuery.isLoading || absherInformationQuery.isLoading) {
        return (
            <div className="py-12 space-y-10">
                <UserIdentityInformationSkeleton />
            </div>
        );
    }

    const absherInformation = absherInformationQuery.data;

    return (
        <div className="py-12 space-y-5">
            <UserIdentityInformation absherInformation={absherInformation} informationData={informationQuery.data} />
            <UpdatePassword />
        </div>
    );
}
