import React, { useEffect } from 'react';
import { useState } from 'react';
import { http } from 'Utils/Http/Http';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { useTranslation } from 'react-i18next';
import { getRecaptchaToken } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent } from '@mui/material';
import SelectFeeOption from './Steps/SelectFeeOption';
import SuccessTransfer from './Steps/SuccessTransfer';
import { bankAccountAndAmountSchema } from './Schemas';
import { useQuery, useQueryClient } from 'react-query';
import { Btn, BtnBase, setProgress } from '@aseel/common-ui';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import BankAccountAndAmount from './Steps/BankAccountAndAmount';
import TransferToBankAccount from './Steps/TransferToBankAccount';
import { APPROVED_BANK_ACCOUNTS, DASHBOARD_TRANSACTIONS } from 'Constants/QueriesKeys';

const SUBMIT_BTN = 'SUBMIT_BTN';

export default function WithdrawDialog({ isDialogOpen, setIsDialogOpen }) {
    const { t } = useTranslation();
    const [step, setStep] = useState(0);
    const [feeOptions, setFeeOptions] = useState([]);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [transferServerErrorMsg, setTransferServerErrorMsg] = useState(null);
    const [isTransferRequestSent, setIsTransferRequestSent] = useState(false);

    const queryClient = useQueryClient();

    const bankAccountAndAmountForm = useForm({
        resolver: yupResolver(bankAccountAndAmountSchema),
    });

    const feeOptionsForm = useForm();

    const approvedAccountsQuery = useQuery(APPROVED_BANK_ACCOUNTS, () =>
        http.get('/bank-accounts/approved').then((response) => response.data.data),
    );

    const selectedBankAccountId = bankAccountAndAmountForm.watch('bank_account_id');
    const selectedBankAccount = approvedAccountsQuery.data?.find((el) => el.id === selectedBankAccountId);

    const feeValue = feeOptionsForm.watch('fee');
    const selectedFeeOption = feeOptions?.find((el) => String(el.value) === String(feeValue));
    const isFeeOptionSelected = Boolean(selectedFeeOption);

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        if (isDialogOpen) {
            setStep(0);
            bankAccountAndAmountForm.reset();
            feeOptionsForm.reset();
            setTransferServerErrorMsg(null);
        }
    }, [isDialogOpen]);

    const steps = [
        {
            Component: BankAccountAndAmount,
            props: {
                form: bankAccountAndAmountForm,
                approvedAccounts: approvedAccountsQuery.data,
                areAccountsLoading: approvedAccountsQuery.isLoading,
            },
        },
        {
            Component: SelectFeeOption,
            props: {
                selectedBankAccount: selectedBankAccount,
                form: feeOptionsForm,
                feeOptions: feeOptions,
                selectedFeeOption: selectedFeeOption,
            },
        },
        {
            Component: TransferToBankAccount,
            props: {
                form: feeOptionsForm,
                serverErrorMsg: transferServerErrorMsg,
                selectedFeeOption: selectedFeeOption,
                selectedBankAccount: selectedBankAccount,
            },
        },
        {
            Component: SuccessTransfer,
        },
    ];

    const handleBankAccountAndAmountSubmission = async () => {
        const isValid = await bankAccountAndAmountForm.trigger();

        if (!isValid) return;

        try {
            setProgress(SUBMIT_BTN, true);
            const response = await http.post('/investor/transfer/fees', bankAccountAndAmountForm.getValues(), {
                baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
            });
            const feeOptionsResponse = response.data.data;
            setFeeOptions(feeOptionsResponse);
            if (feeOptionsResponse.length === 1) {
                feeOptionsForm.setValue('fee', feeOptionsResponse[0].value);
                setStep(2);
            } else {
                feeOptionsForm.setValue('fee', null);
                setStep(1);
            }
        } catch (error) {
            handleError(error, bankAccountAndAmountForm.setError);
        }
        setProgress(SUBMIT_BTN, false);
    };

    const handleTransferToBankAccountSubmission = async () => {
        try {
            setIsTransferRequestSent(true);
            setProgress(SUBMIT_BTN, true);

            const token = await getRecaptchaToken(executeRecaptcha);

            let formData = {
                ...bankAccountAndAmountForm.getValues(),
                fee: feeOptionsForm.getValues('fee'),
                g_token: token,
            };

            await http.post('/transfer', formData);
            queryClient.invalidateQueries(DASHBOARD_TRANSACTIONS);
            setStep(3);
        } catch (error) {
            handleError(error, {
                statusHandlers: {
                    500: () => {
                        setTransferServerErrorMsg(t('messages.somethingWentWrong'));
                    },
                    400: (response) => {
                        setTransferServerErrorMsg(response.data?.message);
                    },
                },
            });
        }
        setProgress(SUBMIT_BTN, false);
        setIsTransferRequestSent(false);
    };

    const handleNext = () => {
        if (step === 0) {
            return handleBankAccountAndAmountSubmission();
        } else if (step === 1) {
            return setStep(2);
        } else if (step === 2) {
            return handleTransferToBankAccountSubmission();
        }
    };

    const handleBack = () => {
        if (feeOptions.length === 1) {
            setStep(step - 2);
            setTransferServerErrorMsg(null);
        } else {
            setStep(step - 1);
            setTransferServerErrorMsg(null);
        }
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
            <DialogContent>
                {steps.map(({ Component, props }, key) => (key === step ? <Component key={key} {...props} /> : null))}
                {step === 3 ? (
                    <div className="flex justify-end mt-5">
                        <BtnBase onClick={handleCloseDialog} fullWidth={false} variant="text" className="w-2/12">
                            {t('common.close')}
                        </BtnBase>
                    </div>
                ) : (
                    <div className="flex justify-end mt-12">
                        {step === 0 ? null : (
                            <BtnBase
                                onClick={handleBack}
                                fullWidth={false}
                                type="submit"
                                variant="text"
                                className="w-2/12"
                                disabled={isTransferRequestSent}>
                                {t('common.back')}
                            </BtnBase>
                        )}
                        <Btn
                            onClick={handleNext}
                            fullWidth={false}
                            variant="text"
                            className="w-2/12"
                            name={SUBMIT_BTN}
                            disabled={step === 1 && !isFeeOptionSelected && feeOptions.length > 1}>
                            {t(step === 2 ? 'wallet.transfer' : 'common.next')}
                        </Btn>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
}
