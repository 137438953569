import React from 'react';
import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import RoundedCard from 'Components/Cards/RoundedCard';

export default function TableSkeleton() {
    const skeletonSkeleton = [
        {
            component: Skeleton,
            width: 'w-2/6',
        },
        {
            component: Skeleton,
            width: 'w-2/6',
        },
        {
            component: Skeleton,
            width: 'w-2/6',
        },
        {
            component: Skeleton,
            width: 'w-2/6',
        },
        {
            component: Skeleton,
            width: 'w-2/6',
        },
    ];

    return (
        <RoundedCard className="pt-14 sm:px-10 px-2.5">
            <Typography variant="h4" className="mb-7">
                <Skeleton className="w-5/12 sm:w-3/12 md:w-2/12" />
            </Typography>
            <div className="sm:px-3 md:pt-2.5">
                <div className="md:flex hidden mb-5">
                    {skeletonSkeleton.map((item) => (
                        <div className="w-full">
                            <item.component className={item.width} />
                        </div>
                    ))}
                </div>
                <div className='mb-8'>
                    {Array(3)
                        .fill(0)
                        .map((item, i) => (
                            <div className="flex items-center justify-between md:block py-3" key={item.id}>
                                <div className="flex flex-col sm:flex-row md:hidden sm:items-center w-3/4 sm:w-full">
                                    <div className="sm:w-2/3">
                                        <Skeleton width={90} />
                                        <Skeleton width={180} />
                                    </div>
                                    <div className="sm:flex justify-center sm:w-2/3 sm:text-center">
                                        <Typography variant="body2" className="md:hidden">
                                            <Skeleton width={150} />
                                        </Typography>
                                    </div>
                                </div>
                                <div className="flex justify-center w-1/4 md:hidden">
                                    <Skeleton width={50} />
                                </div>
                                <div className="hidden md:flex text-center">
                                    <div className="w-full">
                                        <Skeleton className="md:w-2/6" />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton className="md:w-2/6" />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton className="md:w-2/6" />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton className="md:w-2/6" />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton className="md:w-2/6" />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </RoundedCard>
    );
}
