import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { i18n } from '@aseel/common-utils';
import { NavLinks } from '../Constants/NavLinks';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Divider, Typography, IconButton } from '@mui/material';
import DehazeRoundedIcon from '@mui/icons-material/DehazeRounded';
import aseelLogo from '@aseel/common-assets/assets/aseel-logo-dark.png';
import { Box } from '@mui/system';

const getNavProps = (menu) => {
    const props = {};

    if (menu.type === 'url') {
        props.component = 'a';
        props.href = menu.link;
    } else if (menu.type === 'button') {
        props.onClick = menu.onClick;
    }

    return props;
};

export default function NavLinksForMobile({ menus, palette }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <IconButton className="px-5" onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}>
                <DehazeRoundedIcon />
            </IconButton>
            {isMenuOpen ? (
                <div
                    className="absolute top-0 inset-x-0 mx-2.5 mt-2.5 pb-2.5 bg-white z-40 rounded-md"
                    style={{
                        boxShadow: '0px 0px 20px 2px rgba(189,189,189,0.3)',
                    }}>
                    <div className="flex justify-between items-center p-5">
                        <a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}`}>
                            <img src={aseelLogo} className="w-auto h-14 " alt="Aseel Logo" />
                        </a>
                        <IconButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <Divider className="my-2" />
                    {NavLinks().map((navItem) => (
                        <Typography
                            onClick={() => setIsMenuOpen(false)}
                            component={NavLink}
                            to={navItem.path}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: '600',
                                color: '#bdbdbd',
                                paddingLeft: 6,
                                paddingTop: 3,
                                paddingBottom: 3,
                            }}
                            activeStyle={{
                                color: palette.primary.main,
                            }}>
                            <navItem.icon fontSize="small" className="me-2.5" />
                            {navItem.text}
                        </Typography>
                    ))}
                    <Divider className="mt-3.5 mb-2.5" />
                    {menus.map((menu, key) => {
                        const props = getNavProps(menu);
                        return (
                            <Box className="py-2.5" sx={{ paddingLeft: 6 }} onClick={() => setIsMenuOpen(false)}>
                                <Typography {...props} key={key}>
                                    {menu.label}
                                </Typography>
                            </Box>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
}
