import useAuth from 'Hooks/UseAuth';
import { Redirect } from 'react-router-dom';

export default function GuestGuard(next) {
    const { user } = useAuth();

    if (user === null) {
        return next();
    }

    if (user.is_kyc_completed) {
        return <Redirect to="/app/overview" />;
    }

    return <Redirect to="/sign-up/profile" />;
}
