import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './MainEntry';
import reportWebVitals from './reportWebVitals';
import i18n from 'Utils/I18n';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClientProvider, QueryClient } from 'react-query';
import { http } from 'Utils/Http/Http';
import { BrowserRouter } from 'react-router-dom';
import { updateHttpInstanceLanguageHeader } from '@aseel/http';
import { setI18nInstance } from '@aseel/common-utils';
import 'react-toastify/dist/ReactToastify.min.css';
import { setErrorHandlerDefaultOptions } from '@aseel/http';
import { DialogToast } from '@aseel/common-ui';

setI18nInstance(i18n);

setErrorHandlerDefaultOptions({
    displayerFn: (message) =>
        DialogToast.error({
            message,
        }),
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

i18n.on('languageChanged', (language) => {
    window.localStorage.setItem('locale', language);
    updateHttpInstanceLanguageHeader(http, language);
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename="/">
            <QueryClientProvider client={queryClient}>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                    <App />
                </GoogleReCaptchaProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
