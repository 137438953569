import React from 'react';
import { useTheme } from '@mui/system';
import ShareFundBtns from 'Components/Funds/ShareFundBtns';
import { IconButton, Box, Menu } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';

export default function ShareMenu({ fundData, url }) {
    const { palette } = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ backgroundColor: palette.surface.gray20 }} className="mt-0 lg:mt-1.5">
            <IconButton size="large" className="rounded-none" onClick={handleClick}>
                <IosShareOutlinedIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <div className='p-2'>
                    <ShareFundBtns className="flex space-s-3" title={fundData.title} url={url} />
                </div>
            </Menu>
        </Box>
    );
}
