import React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashedBoxEmpty } from '@aseel/common-ui';
import { PROFESSIONAL_UPGRADE_REQUESTS } from 'Constants/QueriesKeys';
import { Card, CardContent, Typography } from '@mui/material';
import ShowProfessionalUpgradeRequest from './Components/ShowProfessionalUpgradeRequest';
import { ApprovalStatusChip, approvalStatusModalTypes } from '@aseel/common-components';
import ProfessionalUpgradeRequestsSkeleton from './Sketelons/ProfessionalUpgradeRequestsSkeleton';
import RoundedCard from 'Components/Cards/RoundedCard';

export default function ProfessionalUpgradeRequestsPage() {
    const { t } = useTranslation();
    const { push } = useHistory();
    const [upgradeRequestInfo, setUpgradeRequestInfo] = useState({
        show: false,
        upgradeRequestId: null,
    });

    const upgradeRequestsQuery = useQuery(PROFESSIONAL_UPGRADE_REQUESTS, () =>
        http.get('/professional-upgrade/requests').then((response) => response.data),
    );

    const upgradeRequests = upgradeRequestsQuery.data?.data;

    return upgradeRequestsQuery.isLoading ? (
        <ProfessionalUpgradeRequestsSkeleton />
    ) : (
        <div className="p-10">
            <Typography variant="h6" className="mb-2.5">
                {t('upgradeInvestorLevel.myUpgradeRequests')}
            </Typography>
            {upgradeRequests.length > 0 ? (
                <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {upgradeRequests.map((item) => (
                        <RoundedCard
                            className="cursor-pointer"
                            onClick={() =>
                                setUpgradeRequestInfo({
                                    show: true,
                                    upgradeRequestId: item.id,
                                })
                            }>
                            <CardContent>
                                <div className="space-y-2.5">
                                    <Typography>{item.created_at}</Typography>
                                    <ApprovalStatusChip
                                        label={item.status.description}
                                        status={item.status.value}
                                        modalType={approvalStatusModalTypes.investorUpgradeRequest}
                                    />
                                </div>
                            </CardContent>
                        </RoundedCard>
                    ))}
                </div>
            ) : (
                <DashedBoxEmpty
                    onClick={() => push('/app/professional-upgrade')}
                    hasButton
                    title={t('upgradeInvestorLevel.noUpgradeRequestFound')}>
                    {t('upgradeInvestorLevel.submitNewRequest')}
                </DashedBoxEmpty>
            )}

            <ShowProfessionalUpgradeRequest
                isOpen={upgradeRequestInfo.show}
                onClose={() =>
                    setUpgradeRequestInfo({
                        ...upgradeRequestInfo,
                        show: false,
                    })
                }
                upgradeRequestInfo={upgradeRequestInfo}
            />
        </div>
    );
}
