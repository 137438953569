import { Container, Typography } from '@mui/material';
import React from 'react';
import { Skeleton } from '@mui/material';
import { BtnBase } from '@aseel/common-ui';

export default function InvestInFundSkeleton() {
    return (
        <Container maxWidth="md" className="lg:py-10">
            <div>
                <Skeleton
                    style={{
                        paddingTop: '45%',
                    }}
                    variant="rectangular"></Skeleton>
            </div>
            <div className="mt-4">
                <Typography variant="h6" component="h2">
                    <Skeleton className="w-1/4" />
                </Typography>

                <Typography variant="h4" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
            </div>

            <div>
                <Skeleton
                    style={{
                        paddingTop: '45%',
                    }}
                    variant="rectangular"></Skeleton>
            </div>

            <div className="bg-gray-50 px-3 py-3.5">
                <div className="flex justify-between">
                    <Skeleton>
                        <BtnBase>Hi</BtnBase>
                    </Skeleton>

                    <Skeleton>
                        <BtnBase>Hi</BtnBase>
                    </Skeleton>
                </div>
            </div>
        </Container>
    );
}
