import { Skeleton } from '@mui/material';
import { Typography } from '@mui/material';

import React from 'react';

export default function UnitsToBeInvestedSkeleton() {
    return (
        <Typography variant="h5">
            <Skeleton className="sm:w-1/4" />
        </Typography>
    );
}
