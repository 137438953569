import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import DateRangePicker from '../DateRangePicker';
import { translateRHFErrorMsg } from '@aseel/common-utils';

export default function DateRangePickerController({
    name,
    rules,
    onFocus,
    control,
    defaultValue = [null, null],
    ...otherPickerProps
}) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <DateRangePicker
                        value={value}
                        onChange={onChange}
                        startError={Boolean(error && error[0])}
                        endError={Boolean(error && error[1])}
                        startHelperText={error && error[0] ? translateRHFErrorMsg(error[0]) : null}
                        endHelperText={error && error[1] ? translateRHFErrorMsg(error[1]) : null}
                        {...otherPickerProps}
                    />
                );
            }}
            defaultValue={defaultValue}
            rules={rules}
            onFocus={onFocus}
        />
    );
}

DateRangePickerController.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    ref: PropTypes.object,
    onFocus: PropTypes.func,
};
