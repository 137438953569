const next = (remainingStacks = null, children) => {
    const stack = remainingStacks.shift();

    if (stack === undefined) {
        return children;
    }

    return stack({ next: () => next(remainingStacks, children) });
};

export default function CTAStacks({ stacks = [], children }) {
    const result = next([...stacks], children);

    return result;
}
