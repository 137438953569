import React from 'react';
import useAuth from 'Hooks/UseAuth';
import i18n from 'i18next';
import { Redirect } from 'react-router-dom';
import { investorStatus } from 'Constants/InvestorStatus';
import { Card, CardContent, Container } from '@mui/material';
import searchImg from '@aseel/common-assets/assets/search-img.svg';
import TitleAndDescription from './Components/TitleAndDescription';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const titleDescriptionProps = () => ({
    [investorStatus.pendingReview]: {
        title: i18n.t('status.accIsReview'),
        description: i18n.t('status.yourAccIsReview'),
        hasContactUs: false,
        hasIcon: false,
        hasImage: true,
        image: searchImg,
    },
    [investorStatus.inReview]: {
        title: i18n.t('status.accIsReview'),
        description: i18n.t('status.yourAccIsReview'),
        hasContactUs: false,
        hasIcon: false,
        hasImage: true,
        image: searchImg,
    },
    [investorStatus.rejected]: {
        title: i18n.t('status.accIsRejected'),
        description: i18n.t('status.yourAccIsRejected'),
        hasContactUs: true,
        hasIcon: true,
        hasImage: false,
        Icon: WarningRoundedIcon,
    },
    [investorStatus.suspended]: {
        title: i18n.t('status.accIsSuspended'),
        description: i18n.t('status.yourAccIsSuspended'),
        hasContactUs: true,
        hasIcon: true,
        hasImage: false,
        Icon: WarningRoundedIcon,
    },
    [investorStatus.autoAmlCheckFailed]: {
        title: i18n.t('status.accIsReview'),
        description: i18n.t('status.yourAccIsReview'),
        hasContactUs: false,
        hasIcon: false,
        hasImage: true,
        image: searchImg,
    },
});

const displayableStatuses = [
    investorStatus.inReview,
    investorStatus.pendingReview,
    investorStatus.rejected,
    investorStatus.suspended,
    investorStatus.autoAmlCheckFailed,
];

export default function InvestorStatus() {
    const { user } = useAuth();

    const userStatus = user?.status.value;

    if (displayableStatuses.includes(userStatus)) {
        const props = titleDescriptionProps()[userStatus];

        return (
            <div className="flex items-center justify-center flex-grow py-10 my-6 sm:py-0">
                <Container maxWidth="sm">
                    <Card>
                        <CardContent>
                            <div className="flex justify-center">
                                <TitleAndDescription userStatus={userStatus} {...props} />
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        );
    }

    return <Redirect to="/app/overview" />;
}
