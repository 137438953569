import { Container, Typography } from '@mui/material';
import React from 'react';
import { Skeleton } from '@mui/material';

function FundSummaryItem() {
    return (
        <div className="py-2">
            <Typography className="w-2/3" variant="h4" color="primary">
                <Skeleton />
            </Typography>

            <Typography className="w-1/3" variant="body1" color="textSecondary">
                <Skeleton />
            </Typography>
        </div>
    );
}
export default function FundSkeleton() {
    return (
        <Container maxWidth="lg" className="py-8 lg:py-10">
            <div className="mb-4 w-1/2">
                <Typography color="primary" variant="h4" component="h1">
                    <Skeleton />
                </Typography>
            </div>
            <div className="mb-8 md:flex">
                <div className="md:w-3/5 md:me-6">
                    <Skeleton variant="rectangular" width="100%">
                        <div style={{ paddingTop: '85%' }} />
                    </Skeleton>
                </div>

                <div className="md:w-2/5">
                    <div>
                        <Typography fontFamily="sans-serif" className="mb-3 font-semibold" color="secondary" variant="h3" component="h2">
                            <Skeleton />
                        </Typography>

                        <Typography className="mb-1" color="textSecondary">
                            <Skeleton />
                        </Typography>

                        <Skeleton />
                    </div>

                    <div className="mt-2 divide-y divide-gray-300">
                        {Array(3)
                            .fill(1)
                            .map(() => (
                                <FundSummaryItem />
                            ))}

                        <Skeleton variant="text" width="100%">
                            <div className="h-24" />
                        </Skeleton>
                    </div>
                </div>
            </div>

            <div>
                <Skeleton variant="rectangular" height={40} width="100%" className="mb-4"></Skeleton>
            </div>

            <div>
                <Skeleton variant="rectangular" height={500} width="100%" className="mb-4"></Skeleton>
            </div>
        </Container>
    );
}
