import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import RoundedCard from 'Components/Cards/RoundedCard';
import TableSkeleton from '../Skeletons/TableSkeleton';
import { BtnBase, DashedBoxEmpty } from '@aseel/common-ui';
import { PAGE_BANK_ACCOUNTS } from 'Constants/QueriesKeys';
import TablePagination from 'Components/Table/TablePagination';
import CreateBankAccountDialog from './Components/CreateBankAccountDialog';
import { Typography } from '@mui/material';
import { ApprovalStatusChip, approvalStatusModalTypes } from '@aseel/common-components';

export default function BankAccounts() {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const bankAccountsQuery = useQuery([PAGE_BANK_ACCOUNTS, { page }], () =>
        http
            .get('/bank-accounts', {
                params: {
                    page: page,
                },
            })
            .then((response) => response.data),
    );

    const handleOpenCreateBankAccountDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseCreateBankAccountDialog = () => {
        setIsDialogOpen(false);
    };

    const bankColumns = [
        {
            headerName: t('wallet.name'),
            className: 'w-3/12',
        },
        {
            headerName: t('wallet.bankName'),
            className: 'w-2/12',
        },
        {
            headerName: t('wallet.iban'),
            className: 'w-4/12',
        },
        {
            headerName: t('common.status'),
            className: 'w-3/12',
            center: true,
        },
    ];

    const currentPage = bankAccountsQuery.data?.meta.current_page;
    const lastPage = bankAccountsQuery.data?.meta.last_page;
    const firstPage = bankAccountsQuery.data?.meta.from;

    if (bankAccountsQuery.isLoading) {
        return <TableSkeleton />;
    }

    const bankRows = bankAccountsQuery.data.data;

    return (
        <RoundedCard className="pt-8 pb-2.5 md:px-10 px-4">
            <CreateBankAccountDialog
                isOpen={isDialogOpen}
                onClose={handleCloseCreateBankAccountDialog}
                bankAccountsQuery={bankAccountsQuery}
            />

            <div className="mb-4 sm:justify-between sm:flex">
                <Typography variant="h4" className="mb-2 font-semibold text-gray-800 sm:mb-0">
                    {t('wallet.bankAccounts')}
                </Typography>
                <BtnBase fullWidth={false} onClick={handleOpenCreateBankAccountDialog}>
                    {t('wallet.addAccount')}
                </BtnBase>
            </div>
            {bankRows.length ? (
                <div className="md:py-2.5">
                    <div className="hidden mb-3 md:flex">
                        {bankColumns.map((item) => (
                            <Typography className={item.className} fontWeight={800} variant="body2" color="grey.600">
                                {item.headerName}
                            </Typography>
                        ))}
                    </div>
                    <div>
                        {bankRows.map((item) => (
                            <div className="py-3" key={item.id}>
                                <div className="flex items-center md:hidden">
                                    <div className="w-3/4 sm:flex sm:items-center">
                                        <div className="sm:w-1/2">
                                            <Typography variant="body2" color="textSecondary">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body2" color="grey.800">
                                                {item.bank}
                                            </Typography>
                                        </div>
                                        <div className="sm:w-1/2">
                                            <Typography variant="body2" color="grey.800">
                                                {item.iban}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="flex justify-end w-1/4 sm:block">
                                        <ApprovalStatusChip
                                            label={item.status.description}
                                            status={item.status.value}
                                            modalType={approvalStatusModalTypes.general}
                                        />
                                    </div>
                                </div>

                                <Box className="hidden md:flex" sx={{ color: 'grey.800' }}>
                                    <Typography variant="body1" className="w-3/12">
                                        {item.name}
                                    </Typography>
                                    <Typography variant="body1" className="w-2/12">
                                        {item.bank}
                                    </Typography>
                                    <Typography variant="body1" className="w-4/12">
                                        {item.iban}
                                    </Typography>
                                    <div className="flex items-center justify-center w-3/12 md:block">
                                        <ApprovalStatusChip
                                            label={item.status.description}
                                            status={item.status.value}
                                            modalType={approvalStatusModalTypes.general}
                                        />
                                    </div>
                                </Box>
                            </div>
                        ))}
                    </div>
                    <TablePagination
                        beforeOnClick={() => setPage(currentPage - 1)}
                        beforeDisabled={firstPage === currentPage}
                        nextOnClick={() => setPage(currentPage + 1)}
                        nextDisabled={currentPage === lastPage}
                    />
                </div>
            ) : (
                <DashedBoxEmpty title={t('wallet.noBankAccounts')} />
            )}
        </RoundedCard>
    );
}
