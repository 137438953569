import React from 'react';
import i18n from 'Utils/I18n';
import { add, subYears } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';
import { Alert, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputController } from '@aseel/common-ui';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AdapterJalali from '@date-io/date-fns-jalali';
import enLocaleJalali from 'Utils/DateFnsJalali/Locale/en-US';
import arLocaleJalali from 'Utils/DateFnsJalali/Locale/ar-SA';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePickerController from 'Components/DatePickerController';
import RadioGroupController from 'Components/FormControls/Controller/RoundedRadioGroupController';

const localeMap = {
    en: enLocale,
    ar: arLocale,
};

const localeJalaliMap = {
    en: enLocaleJalali,
    ar: arLocaleJalali,
};

const birthDateType = () => [
    {
        label: i18n.t('kyc.hijri'),
        value: 'hijri',
    },
    {
        label: i18n.t('kyc.gregorian'),
        value: 'gregorian',
    },
];

export default function IdentityVerification({ control, serverErrors, typeOfBirthDate }) {
    const { t, i18n } = useTranslation();

    let serverErrorMessage;

    if (serverErrors.vid) {
        serverErrorMessage = <Alert severity="error">{t('messages.somethingWentWrong')}</Alert>;
    }

    return (
        <div>
            <div className="sm:py-5 sm:px-10">{serverErrorMessage}</div>
            <div className="block py-6 space-y-5 sm:flex sm:space-s-10 sm:py-0 sm:space-y-0">
                <div className="ms:w-1/3 sm:py-14 sm:ps-10 space-y-2.5">
                    <InputController control={control} name="nin" label={t('kyc.nationalIdIqama')} />
                </div>
                <Divider orientation="vertical" flexItem className="hidden my-6 sm:block" />
                <div className="items-center sm:flex sm:space-s-5">
                    <RadioGroupController
                        row
                        formControlLabelSx={{
                            width: {
                                xs: '48%',
                                sm: '90%',
                            },
                        }}
                        control={control}
                        name="birth_date_type"
                        options={birthDateType()}
                        RadioGroupClassName="flex justify-between block mb-3 sm:mb-0"
                    />
                    <div className="w-full sm:pe-10">
                        {typeOfBirthDate === 'hijri' ? (
                            <LocalizationProvider
                                dateAdapter={AdapterJalali}
                                locale={i18n.language === 'ar' ? localeJalaliMap.ar : localeJalaliMap.en}>
                                <DatePickerController
                                    maxDate={add(new Date(), {
                                        years: 22,
                                    })}
                                    openTo="year"
                                    name="birth_date"
                                    control={control}
                                    label={t('kyc.dateOfBirth')}
                                />
                            </LocalizationProvider>
                        ) : (
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={i18n.language === 'ar' ? localeMap.ar : localeMap.en}>
                                <DatePickerController
                                    maxDate={subYears(new Date(), 21)}
                                    openTo="year"
                                    name="birth_date"
                                    control={control}
                                    label={t('kyc.dateOfBirth')}
                                />
                            </LocalizationProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
