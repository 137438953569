import { Yup } from '@aseel/common-utils';

const generalInfoSchema = Yup.object().shape({
    general_info: Yup.object().shape({
        is_entrusted_in_public_functions: Yup.string().required(),
        have_relationship_with_person_in_public_functions: Yup.string().required(),
        is_beneficial_owner: Yup.string().required(),
        identity_of_beneficial_owner: Yup.string().when('is_beneficial_owner', {
            is: '0',
            then: Yup.string().required(),
        }),
        is_individual: Yup.string().required(),
        any_xp_in_fin_sector: Yup.string().required(),
        any_xp_related_to_fin_sector: Yup.string().required(),
        have_you_invested_in_real_estates: Yup.string().required(),
        investment_xp: Yup.string().required(),
    }),
    is_on_board: Yup.string().required(),
    education_level: Yup.string().required(),
    occupation: Yup.string().required(),
});

const financialStatusSchema = Yup.object().shape({
    net_worth: Yup.string().required(),
    current_invest: Yup.array().of(Yup.string()).min(1).required().nullable(),
    invest_objective: Yup.array().of(Yup.string()).min(1).required().nullable(),
    financial_info: Yup.object().shape({
        years_of_inv_in_securities: Yup.number().min(0).required().nullable(),
        extra_financial_information: Yup.string(),
    }),
    annual_income: Yup.string().required(),
    expected_invest_amount_per_opportunity: Yup.string().required(),
    expected_annual_invest: Yup.string().required(),
});

const identitySchema = Yup.object().shape({
    nin: Yup.string().required(),
    birth_date: Yup.date().required().nullable(),
    birth_date_type: Yup.string().required(),
});

const verifyCodeSchema = Yup.object().shape({
    code: Yup.string().required(),
});

const termsAndConditionsSchema = Yup.object().shape({
    accept: Yup.boolean().oneOf([true], 'acceptKycTC'),
});

export { termsAndConditionsSchema, identitySchema, financialStatusSchema, generalInfoSchema, verifyCodeSchema };
