import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.surface.gray30,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function CustomizedLinearProgress({ steps, activeStep }) {
    return (
        <Box sx={{ flexGrow: 1 }} className="space-y-3.5">
            <div className="flex items-center justify-between">
                <Typography variant="h6" className="font-semibold">
                    {steps[activeStep].label}
                </Typography>
                <Typography variant="h6" className="font-semibold text-gray-600">
                    {`${activeStep + 1} / ${steps.length}`}
                </Typography>
            </div>
            <BorderLinearProgress variant="determinate" value={Math.floor(((activeStep + 1) / steps.length) * 100)} />
        </Box>
    );
}
