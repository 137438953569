import { Box } from '@mui/system';
import { useTheme } from '@mui/system';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import { fundRequest } from './FundPage';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { FUNDS } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { getRecaptchaToken } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import InvestInFundSkeleton from './Skeletons/InvestInFundSkeleton';
import { Btn, BtnBase, DialogToast, setProgress } from '@aseel/common-ui';
import VerifyUserIdentityDialog from './Components/VerifyUserIdentityDialog';
import SubscriptionForm from './Components/InvestInFundSteps/SubscriptionForm';
import UnitsToBeInvested from './Components/InvestInFundSteps/UnitsToBeInvested';
import { Card, CardContent, Container, Divider, Typography } from '@mui/material';
import { acceptTearmsAndConditoinsSchema, codeSchema, unitsSchema } from './Schemas';
import SuccessfullyInvested from './Components/InvestInFundSteps/SuccessfullyInvested';

const btnNames = {
    next: 'NEXT_BTN',
    verify: 'VERIFY_BTN',
};

const styles = (fundQuery) => ({
    image: {
        paddingTop: '45%',
        backgroundImage: fundQuery?.data.images.length > 0 ? `url(${fundQuery?.data.images[0].url})` : undefined,
        backgroundColor: fundQuery?.data.images.length > 0 ? undefined : 'rgb(235, 235, 235)',
    },
});

export default function InvestInFundPage() {
    const { id } = useParams();
    const { t } = useTranslation();
    const { breakpoints, palette } = useTheme();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [activeStep, setActiveStep] = useState(0);
    const [investedUnits, setInvestedUnits] = useState(10);
    const [subscriptionToken, setSubscriptionToken] = useState({
        id: null,
        vid: null,
    });

    const [verifyOtpErrorMsg, setVerifyOtpErrorMsg] = useState(null);

    const isBreakpointGreaterThanSm = useMediaQuery(breakpoints.up('sm'));

    const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);

    const fundQuery = useQuery([FUNDS, { id }], () => fundRequest(id));

    const fundData = fundQuery.data;

    const canInvest = fundData?.can_invest;

    const unitsForm = useForm({
        resolver: (values, context, options) => {
            return yupResolver(unitsSchema(context?.min_inv_share, context?.max_inv_share))(values, context, options);
        },
        defaultValues: {
            shares: null,
        },
        context: fundQuery.data,
    });

    const acceptTermsAndConditions = useForm({
        resolver: yupResolver(acceptTearmsAndConditoinsSchema),
    });

    const codeForm = useForm({
        resolver: yupResolver(codeSchema),
    });

    const handleVerifyingOtpAndInvest = async ({ code }) => {
        try {
            const token = await getRecaptchaToken(executeRecaptcha);

            let formData = {
                code,
                g_token: token,
                vid: subscriptionToken.vid,
            };

            setProgress(btnNames.verify, true);
            setVerifyOtpErrorMsg(null);
            await http.post(`/invest/${subscriptionToken.id}/verify-otp`, formData);
            const investToken = await getRecaptchaToken(executeRecaptcha);
            const response = await http.post(`/invest/${subscriptionToken.id}`, { ...formData, g_token: investToken });
            setInvestedUnits(response.data.data.invested_shares);
            setActiveStep(2);
            setIsVerifyDialogOpen(false);
        } catch (error) {
            handleError(error, {
                displayerFn: () => setVerifyOtpErrorMsg(error.response.data.message),
                setError: codeForm.setError,
            });
        }
        setProgress(btnNames.verify, false);
    };

    const steps = [
        {
            Component: UnitsToBeInvested,
            props: {
                unitsForm,
                fundId: id,
                minUnits: fundData?.min_inv_share,
                maxUnits: fundData?.max_inv_share,
            },
        },
        {
            Component: SubscriptionForm,
            props: {
                codeForm,
                unitsForm,
                fundId: id,
                verifyOtpErrorMsg,
                subscriptionToken,
                isVerifyDialogOpen,
                setIsVerifyDialogOpen,
                acceptTermsAndConditions,
                handleVerifyingOtpAndInvest,
            },
        },
        {
            Component: SuccessfullyInvested,
            props: {
                fundId: id,
                fundName: fundData?.title,
                investedUnits,
            },
        },
    ];

    if (fundQuery.isLoading) {
        return <InvestInFundSkeleton />;
    }

    const handleNext = () => {
        if (activeStep === 0) {
            handleCreateSubscriptionToken();
        } else if (activeStep === 1) {
            handleSendOtp();
        }
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setSubscriptionToken(null);
            acceptTermsAndConditions.reset({ accept: false });
            setActiveStep(0);
        }
    };

    const handleSendOtp = async () => {
        try {
            setProgress(btnNames.next, true);
            setVerifyOtpErrorMsg(null);

            const token = await getRecaptchaToken(executeRecaptcha);

            const response = await http.post(`/invest/${subscriptionToken.id}/send-otp`, { g_token: token });
            codeForm.reset();
            setIsVerifyDialogOpen(true);
            setSubscriptionToken({
                ...subscriptionToken,
                vid: response.data.data.vid,
            });
        } catch (error) {
            handleError(error);
        }
        setProgress(btnNames.next, false);
    };

    const handleCreateSubscriptionToken = async () => {
        const isValid = await unitsForm.trigger();

        if (!isValid) return;

        try {
            setProgress(btnNames.next, true);

            const token = await getRecaptchaToken(executeRecaptcha);

            const { data } = await http.post(`listings/${id}/subscription-token`, {
                ...unitsForm.getValues(),
                g_token: token,
            });
            setSubscriptionToken({
                ...subscriptionToken,
                id: data.data.reference,
            });
            setActiveStep(1);
        } catch (error) {
            handleError(error, {
                statusHandlers: {
                    400: (errResponse) =>
                        DialogToast.error({
                            message: errResponse.data.message,
                        }),
                },
            });
        }

        setProgress(btnNames.next, false);
    };

    const ActiveComponent = steps[activeStep].Component;

    const areTermsAndConditionsAccepted = acceptTermsAndConditions.watch('accept');

    if (!canInvest) {
        return <Redirect to={`/funds/${id}`} />;
    }

    return (
        <>
            <VerifyUserIdentityDialog
                control={codeForm.control}
                isOpen={isVerifyDialogOpen}
                verifyBtnName={btnNames.verify}
                verifyOtpErrorMsg={verifyOtpErrorMsg}
                setIsVerifyDialogOpen={setIsVerifyDialogOpen}
                onClose={() => setIsVerifyDialogOpen(false)}
                handleSubmit={codeForm.handleSubmit(handleVerifyingOtpAndInvest)}
            />

            <Container maxWidth="md" className="py-8 lg:py-10">
                <Card>
                    <div>
                        <Box className="bg-center bg-cover" sx={styles(fundQuery).image}></Box>
                    </div>
                    <CardContent>
                        <Typography className="text-gray-600" variant="h6" component="h1">
                            {t('fund.investIn')}
                        </Typography>
                        <Typography color="bg-gray-800" variant="h4" component="h1">
                            {fundData.title}
                        </Typography>
                    </CardContent>

                    <Divider />

                    <CardContent>
                        <ActiveComponent {...steps[activeStep].props} />
                    </CardContent>

                    <div className="bg-gray-50 px-3 py-3.5">
                        {activeStep === 2 ? null : (
                            <>
                                <div className="sm:flex justify-between space-y-2.5 sm:space-y-0">
                                    <BtnBase
                                        color="secondary"
                                        variant="text"
                                        component={Link}
                                        to={`/funds/${id}`}
                                        fullWidth={!isBreakpointGreaterThanSm}>
                                        {t('fund.goBackToFundPage')}
                                    </BtnBase>
                                    <div className=" sm:flex items-center space-y-2.5 sm:space-y-0">
                                        {activeStep > 0 ? (
                                            <BtnBase
                                                onClick={handleBack}
                                                variant="text"
                                                className="me-2"
                                                fullWidth={!isBreakpointGreaterThanSm}>
                                                {t('fund.changeUnits')}
                                            </BtnBase>
                                        ) : null}
                                        <Btn
                                            onClick={handleNext}
                                            name={btnNames.next}
                                            disabled={activeStep === 1 && !areTermsAndConditionsAccepted}
                                            fullWidth={!isBreakpointGreaterThanSm}>
                                            {t(activeStep === 1 ? 'common.invest' : 'common.next')}
                                        </Btn>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Card>
            </Container>
        </>
    );
}
