import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashedBoxEmpty } from '@aseel/common-ui';
import RoundedCard from 'Components/Cards/RoundedCard';
import TableSkeleton from '../Skeletons/TableSkeleton';
import { INVESTING_RECORDS } from 'Constants/QueriesKeys';
import { Menu, MenuItem, Typography } from '@mui/material';
import TablePagination from 'Components/Table/TablePagination';
import InvestingRecordsForXsAndSm from './Components/InvestingRecordsForXsAndSm';
import InvestingRecordsForMdAndLg from './Components/InvestingRecordsForMdAndLg';

export default function InvestingRecords() {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [listingRecord, setListingRecord] = useState(null);

    const open = Boolean(anchorEl);

    const { isLoading, data } = useQuery([INVESTING_RECORDS, page], () =>
        http
            .get('/investing-records', {
                params: {
                    page: page,
                },
            })
            .then((response) => response.data),
    );

    if (isLoading) {
        return <TableSkeleton />;
    }

    const investingRecordsData = data;

    const currentPage = data?.meta.current_page;
    const lastPage = data?.meta.last_page;
    const firstPage = data?.meta.from;

    const handleOpenRowMenu = (event, listingRecord) => {
        setListingRecord(listingRecord);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseRowMenu = () => {
        setAnchorEl(null);
    };

    const investingRecordsColumns = [
        {
            headerName: t('wallet.date'),
            className: 'w-1/6',
        },
        {
            headerName: t('wallet.listings'),
            className: 'w-1/6',
        },
        {
            headerName: t('wallet.amountWithoutFees'),
            className: 'w-1/6',
        },
        {
            headerName: t('wallet.vatAmount'),
            className: 'w-1/6',
        },
        {
            headerName: t('wallet.adminFees'),
            className: 'w-1/6',
        },
    ];

    return (
        <RoundedCard className="px-4 pt-8 md:px-10">
            <Typography variant="h4" className="mb-4 font-semibold text-gray-800">
                {t('wallet.investingRecords')}
            </Typography>
            {investingRecordsData.data.length ? (
                <div className="md:pt-2.5">
                    <div className="hidden mb-3 md:flex md:space-s-4 lg:space-s-6">
                        {investingRecordsColumns.map((item) => (
                            <Typography className={item.className} fontWeight={800} variant="body2" color="grey.600">
                                {item.headerName}
                            </Typography>
                        ))}
                    </div>
                    <InvestingRecordsForXsAndSm
                        open={open}
                        handleOpenRowMenu={handleOpenRowMenu}
                        investingRecordsData={investingRecordsData.data}
                        investingRecordsColumns={investingRecordsColumns}
                    />
                    <InvestingRecordsForMdAndLg
                        open={open}
                        handleOpenRowMenu={handleOpenRowMenu}
                        investingRecordsData={investingRecordsData.data}
                    />
                    <div className="my-5">
                        <TablePagination
                            beforeOnClick={() => setPage(currentPage - 1)}
                            beforeDisabled={firstPage === currentPage}
                            nextOnClick={() => setPage(currentPage + 1)}
                            nextDisabled={currentPage === lastPage}
                        />
                    </div>
                </div>
            ) : (
                <DashedBoxEmpty title={t('wallet.noTransactionsFound')} />
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseRowMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        boxShadow: '0px 0px 20px 2px rgba(189,189,189,0.3)',
                        borderRadius: 2.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                        },
                    },
                }}>
                {listingRecord?.subscription_form === null ? null : (
                    <MenuItem>
                        <Typography
                            download
                            component="a"
                            target="_blank"
                            fontWeight={900}
                            href={listingRecord?.subscription_form}
                            sx={{ color: ({ palette }) => palette.secondary.main }}>
                            {t('wallet.downloadSubscriptionForm')}
                        </Typography>
                    </MenuItem>
                )}
                {listingRecord?.zatca_invoice === null ? null : (
                    <MenuItem>
                        <Typography
                            download
                            component="a"
                            target="_blank"
                            fontWeight={900}
                            href={listingRecord?.zatca_invoice}
                            sx={{ color: ({ palette }) => palette.secondary.main }}>
                            {t('wallet.downloadInvoice')}
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </RoundedCard>
    );
}
