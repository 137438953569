import React from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Tab, Tabs } from '@mui/material';

const CustomTabs = styled(Tabs)({
    marginTop: 25,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 14,
    marginBottom: 10,
    backgroundColor: grey[200],
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: grey[600],
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 10,
        width: 100,
    },
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 100,
    [theme.breakpoints.up('sm')]: {
        minWidth: 250,
    },
    color: grey[400],
    fontWeight: 'bold',
    '&.Mui-selected': {
        color: grey[600],
    },
    textTransform: 'none',
}));

export default function RoundedTabs({ value, onChange, tabs, centered = true, tabProps, ...otherProps }) {
    return (
        <CustomTabs value={value} onChange={onChange} centered={centered} {...otherProps}>
            {tabs.map((tab) => {
                return <CustomTab value={tab.value} label={tab.label} {...tabProps} />;
            })}
        </CustomTabs>
    );
}
