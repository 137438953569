import dayjs from 'dayjs';
import React from 'react';
import ShareMenu from './ShareMenu';
import { useTranslation } from 'react-i18next';
import { Box, styled, useTheme } from '@mui/system';
import { BtnBase } from '@aseel/common-components/node_modules/@aseel/common-ui';
import { Divider as DividerMui, LinearProgress, linearProgressClasses, Typography } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 14,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.surface.gray30,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 10,
        backgroundColor: theme.palette.secondary.main,
    },
}));

function Divider({ palette }) {
    return (
        <DividerMui
            sx={{
                height: '1px',
                marginTop: 7,
                marginBottom: 5,
                border: `1px solid ${palette.surface.gray30}`,
            }}
        />
    );
}

export default function FundSummary({ fundData, handleInvest, canInvest, fundUrl }) {
    const { palette } = useTheme();
    const { t } = useTranslation();

    const fundSummary = [
        {
            title: t('fund.unitPrice'),
            value: `${fundData.unit_price} SAR`,
        },
        {
            title: t('fund.totalUnits'),
            value: fundData.total_shares,
        },
        {
            title: t('fund.minInvestmenAmount'),
            value: `${fundData.min_inv_amount_fmt} SAR`,
        },
        {
            title: t('fund.lastDateForInvestment'),
            value: dayjs(fundData.deadline).format('YYYY/MM/DD'),
        },
    ];

    return (
        <Box
            className="px-5 pt-8 pb-8 bg-white lg:w-1/2 md:mt-5 lg:mt-0"
            sx={{
                border: `2px solid ${palette.surface.gray30}`,
                borderRadius: 1.5,
            }}>
            <Typography className="font-bold text-gray-900" variant="h4">
                {fundData.title}
            </Typography>
            <div className="sm:flex sm:space-s-3 space-y-2.5 sm:space-y-0 mt-6 md:mt-6 lg:mt-10">
                <div className="space-y-2 sm:w-1/2 md:w-full lg:w-1/2">
                    <div className="flex items-center space-s-1.5">
                        <Typography className="font-bold text-gray-500 text-md">{t('fund.fundProvider')}</Typography>
                    </div>
                    <Box
                        className="flex items-center justify-center space-s-2.5"
                        sx={{
                            height: 100,
                            backgroundColor: palette.surface.gray20,
                            borderRadius: 1.5,
                        }}>
                        <Typography className="font-bold text-gray-800 truncate" variant="h5">
                            {fundData.authorized_entity}
                        </Typography>
                    </Box>
                </div>
                <div className="space-y-2 sm:w-1/2 md:w-full lg:w-1/2">
                    <Typography className="font-bold text-gray-500 text-md">
                        {t('fund.theTotalValueOfTheProject')}
                    </Typography>
                    <Box
                        className="flex items-center justify-center space-s-2.5"
                        sx={{ height: 100, backgroundColor: palette.surface.gray20, borderRadius: 1.5 }}>
                        <Typography className="text-gray-400" variant="h5">
                            SAR
                        </Typography>
                        <Typography className="font-bold text-gray-800 truncate" variant="h4">
                            {fundData.target_fmt}
                        </Typography>
                    </Box>
                </div>
            </div>
            <Divider palette={palette} />
            <div className="grid gap-5 sm:grid-cols-2">
                {fundSummary.map((item) => (
                    <div className={item.width}>
                        <Typography className="font-semibold text-gray-500 truncate text-md">{item.title}</Typography>
                        <Typography className="text-lg font-bold text-gray-800 truncate">{item.value}</Typography>
                    </div>
                ))}
            </div>
            <Divider palette={palette} />
            <Typography className="mb-2" color="textSecondary">
                {t('fund.raisedPercentage', { percentage: fundData.complete_percent })}
            </Typography>
            <BorderLinearProgress variant="determinate" value={fundData.complete_percent} />
            <Divider palette={palette} />
            <div className="flex items-center space-s-3.5">
                <BtnBase onClick={handleInvest} disabled={!canInvest} className="lg:mt-1.5 h-12">
                    {canInvest ? t('fund.investInFund', { fundName: fundData.title }) : t('fund.closedFund')}
                </BtnBase>
                <ShareMenu fundData={fundData} url={fundUrl} />
            </div>
        </Box>
    );
}
