import React from 'react';
import Chip from '@mui/material/Chip';
import { red, green } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

const styles = {
    inactive: {
        backgroundColor: red[100],
        color: red[900],
    },
    active: {
        backgroundColor: green[100],
        color: green[900],
    },
};

export default function ActiveChip({ label, isActive, ...otherProps }) {
    const { t } = useTranslation();
    
    return (
        <Chip
            size="small"
            label={isActive ? t('common.active') : t('common.inactive')}
            sx={isActive ? styles.active : styles.inactive}
            {...otherProps}
        />
    );
}
