import Header from './Header';
import { Box, useTheme } from '@mui/system';

export default function Example({ children }) {
    const { palette } = useTheme();

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <Box
                className="flex-grow flex flex-col px-2.5 sm:px-5 md:px-5 lg:px-40"
                sx={{ backgroundColor: palette.surface.gray30 }}>
                {children}
            </Box>
        </div>
    );
}
