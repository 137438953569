import { Skeleton } from '@mui/material';
import React from 'react';

export default function UserInformationSkeleton() {
    return (
        <div className="p-5 space-y-5">
            <div className="space-y-5">
                <Skeleton className="ms:w-1/4 w-1/2" />
                <div className="grid sm:grid-cols-4 grid-cols-1 gap-5">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
            </div>
            <div className="space-y-5">
                <Skeleton className="sm:w-1/4 w-1/2" />
                <div className="grid sm:grid-cols-4 grid-cols-1 gap-5">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
            </div>
        </div>
    );
}
