import React from 'react';
import { Skeleton } from '@mui/material';
import { CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import RoundedCard from 'Components/Cards/RoundedCard';

function OverviewSkeleton() {
    return (
        <div  className="space-y-10">
            <Typography variant="h3" className="pb-3">
                <Skeleton className='w-4/12 sm:w-3/12 md:w-2/12' />
            </Typography>
            <div className="md:flex md:items-center lg:space-x-4 space-y-8 md:space-y-0 mt-6">
                <div className="flex w-full lg:space-s-3.5 grid grid-cols-1 md:grid-cols-2 gap-5 lg:flex">
                    {Array(5)
                        .fill(0)
                        .map(() => (
                            <RoundedCard className="lg:w-1/5">
                                <CardContent className="space-y-1.5">
                                    <Typography variant="subtitle2" className="text-sm font-medium text-gray-500">
                                        <Skeleton className='w-4/12 sm:w-3/12 md:w-5/12' />
                                    </Typography>
                                    <Typography variant="h5" className="text-gray-900">
                                        <Skeleton className='w-6/12 sm:w-5/12 md:w-10/12' />
                                    </Typography>
                                </CardContent>
                            </RoundedCard>
                        ))}
                </div>
            </div>
            <div>
                <RoundedCard className=" sm:px-20 px-5 py-20 space-y-5">
                    <Typography variant="h4" className="text-gray-800">
                        <Skeleton className='w-4/12 sm:w-3/12 md:w-2/12' />
                    </Typography>
                    <Skeleton variant="rectangular" height="480px" width="100%" />
                </RoundedCard>
            </div>
        </div>
    );
}

export default OverviewSkeleton;
