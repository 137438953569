import React from 'react';
import RoundedCard from 'Components/Cards/RoundedCard';
import { CardContent, Skeleton, Typography } from '@mui/material';

export default function UserIdentityInformationSkeleton() {
    return (
        <div>
            <div>
                <Typography variant="h4" className="text-gray-800 mb-8">
                    <Skeleton className="w-4/12 sm:w-3/12 md:w-2/12" />
                </Typography>
                <div className="lg:flex lg:space-s-5 space-y-4 lg:space-y-0">
                    <RoundedCard className="lg:w-1/2 h-full py-12 px-6">
                        <CardContent>
                            <div className="mb-7 space-y-8">
                                <Typography variant="h6" className="text-gray-800">
                                    <Skeleton className="w-4/12 sm:w-3/12 md:w-2/12" />
                                </Typography>
                                <div className="grid grid-cols-2 gap-5">
                                    <div>
                                        <Typography className="text-gray-800">
                                            <Skeleton className="w-4/12 sm:w-3/12 md:w-5/12" />
                                        </Typography>
                                        <Typography>
                                            <Skeleton className="w-6/12 sm:w-5/12 md:w-8/12" />
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography className="text-gray-800">
                                            <Skeleton className="w-4/12 sm:w-3/12 md:w-5/12" />
                                        </Typography>
                                        <Typography>
                                            <Skeleton className="w-6/12 sm:w-5/12 md:w-8/12" />
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <Typography variant="h5" className="text-gray-800 mb-8">
                                <Skeleton className="w-4/12 sm:w-3/12 md:w-2/12" />
                            </Typography>
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-5">
                                {Array(7)
                                    .fill(0)
                                    .map(() => (
                                        <div>
                                            <Typography className="text-gray-800">
                                                <Skeleton className="w-4/12 sm:w-3/12 md:w-5/12" />
                                            </Typography>
                                            <Typography>
                                                <Skeleton className="w-6/12 sm:w-5/12 md:w-10/12" />
                                            </Typography>
                                        </div>
                                    ))}
                            </div>
                        </CardContent>
                    </RoundedCard>
                    <RoundedCard className="lg:w-1/2  py-12 px-6">
                        <CardContent>
                            <Typography variant="h5" className="text-gray-800 mb-8">
                                <Skeleton className="w-4/12 sm:w-3/12 md:w-2/12" />
                            </Typography>
                            <div className="space-y-4">
                                <Typography variant="body2" color="textSecondary">
                                    <Skeleton className="w-4/12 sm:w-3/12 md:w-2/12" />
                                </Typography>
                                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3.5">
                                    {Array(16)
                                        .fill(0)
                                        .map(() => (
                                            <div>
                                                <Typography className="text-gray-800">
                                                    <Skeleton className="w-4/12 sm:w-3/12 md:w-5/12" />
                                                </Typography>
                                                <Typography>
                                                    <Skeleton className="w-6/12 sm:w-5/12 md:w-10/12" />
                                                </Typography>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </CardContent>
                    </RoundedCard>
                </div>
            </div>
        </div>
    );
}
