import axios from 'axios';
import { merge, cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { i18n } from '@aseel/common-utils';
import { mapValidationErrors } from './MapValidationErrors';

export const setErrorHandlerDefaultOptions = (options) => {
    defaultOptions = merge(defaultOptions, options);
};

export const handleUnprocessableEntity = (response, options) => {
    const errors = response.data.errors;

    if (options.setError) {
        const setError = options.setError;
        mapValidationErrors(errors).forEach(({ name, message, type }) => {
            setError(name, { type, message });
        });
    }

    if (shouldDisplay(response, options.display)) {
        options.displayerFn(i18n.t('Some data are incorrect'));
    }
};

let defaultOptions = {
    display: true,
    displayerFn: (message) => toast.error(message),
    statusHandlers: {
        422: handleUnprocessableEntity,
    },
};

export function updateHttpInstanceLanguageHeader(instance, language) {
    instance.defaults.headers.common['X-App-Locale'] = language;
}

export const isAxiosError = (error) => {
    return axios.isAxiosError(error);
};

export const handleError = (error, options = {}) => {
    const mergedOptions = merge(defaultOptions, cloneDeep(options));

    const serverResponse = error.response;

    if (serverResponse) {
        handleErrorResponse(serverResponse, mergedOptions);
    } else {
        handleErrorRequest();
    }
};

export const handleErrorRequest = () => {
    toast.error(i18n.t('Please check your internet connectivity'));
};

export const handleErrorResponse = (response, options) => {
    const status = response.status;
    const statusHandler = options.statusHandlers[status];

    if (statusHandler && typeof statusHandler === 'function') {
        return statusHandler(response, options);
    }

    if (status >= 400 && status <= 499) {
        return handleClientError(response, options);
    }

    if (status >= 500 && status <= 599) {
        return handleServerError(response, options);
    }
};

const handleClientError = (response, options) => {
    let message = response.data.message;

    if (shouldDisplay(response, options.display) === false) {
        return;
    }

    if (message) {
        return options.displayerFn(message);
    } else {
        return options.displayerFn(i18n.t('The service is unavailable now. Please try again later'));
    }
};

const handleServerError = (response, options) => {
    const status = response.status;

    if (shouldDisplay(status, options.display)) {
        return options.displayerFn(i18n.t('The service is unavailable now. Please try again later'));
    }
};

const shouldDisplay = (response, displayOptions) => {
    const status = response.status;

    if (displayOptions === true) {
        return true;
    }

    if (displayOptions === false) {
        return false;
    }

    if (displayOptions[status] === undefined || displayOptions[status] === true) {
        return true;
    }

    return false;
};
