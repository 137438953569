import React, { useState } from 'react';
import { http } from 'Utils/Http/Http';
import { Yup } from '@aseel/common-utils';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { yupResolver } from '@hookform/resolvers/yup';
import RoundedCard from 'Components/Cards/RoundedCard';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IconButton, Typography } from '@mui/material';
import { Btn, InputController, PasswordInputController, setProgress } from '@aseel/common-ui';

const PASSWORD_BTN = 'PASSWORD_BTN';

const schema = Yup.object().shape({
    current_password: Yup.string().required(),
    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'passwordRule'),
    password_confirmation: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'passwordRule',
    ),
});

export default function UpdatePassword() {
    const [canEditPass, setCanEditPass] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();
    const { t } = useTranslation();
    const { control, handleSubmit, setError, watch } = useForm({
        resolver: yupResolver(schema),
    });

    const password = watch('password');

    const handleSubmitPassword = async (values) => {
        setProgress(PASSWORD_BTN, true);

        try {
            const token = await executeRecaptcha();
            const {
                data: { score },
            } = await http.get('/recaptcha/validate', {
                params: { token },
                baseURL: process.env.REACT_APP_BASE_URL,
            });

            if (score >= 0.5) {
                await http.put('/profile/update-password', values);
            }
            setCanEditPass(false);
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(PASSWORD_BTN, false);
    };

    const handleEditClick = () => {
        setCanEditPass(!canEditPass);
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitPassword)}>
            <RoundedCard className="py-12 sm:px-6 px-2.5">
                <div className="flex items-center pb-6 space-s-3">
                    <Typography variant="h5" fontWeight={900} color={grey[800]}>
                        {t('profile.password')}
                    </Typography>
                    <IconButton onClick={handleEditClick} size="large" className="mt-3">
                        <EditIcon fontSize="small" />
                    </IconButton>
                </div>
                <div className="grid grid-cols-1 gap-5 lg:grid-cols-3">
                    <div>
                        <InputController
                            control={control}
                            name="current_password"
                            label={t('password.oldPassword')}
                            type="password"
                            disabled={!canEditPass}
                        />
                    </div>
                    <div>
                        <PasswordInputController
                            control={control}
                            name="password"
                            label={t('password.newPassword')}
                            disabled={!canEditPass}
                            password={password}
                            hasCheckList
                        />
                    </div>
                    <div>
                        <PasswordInputController
                            control={control}
                            name="password_confirmation"
                            label={t('password.confirmationPass')}
                            disabled={!canEditPass}
                        />
                    </div>
                </div>
                <div className="flex justify-end pt-5">
                    <Btn type="submit" disabled={!canEditPass} name={PASSWORD_BTN} fullWidth={false}>
                        {t('common.update')}
                    </Btn>
                </div>
            </RoundedCard>
        </form>
    );
}
