import React from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { OVERVIEW_TIMELINE } from 'Constants/QueriesKeys';

function LineChart() {
    const timeLineQuery = useQuery([OVERVIEW_TIMELINE], () =>
        http.get('dashboard/overview-timeline').then((response) => response.data.data),
    );

    return (
        <>
            <Line
                data={{
                    labels: timeLineQuery.data?.labels,
                    datasets: [
                        {
                            label: 'Investments',
                            data: timeLineQuery.data?.inv_ledgers,
                            backgroundColor: ['rgb(236, 238, 96)'],
                            fill: 'origin',
                            tension: 0.3,
                        },
                        {
                            label: 'Balance',
                            data: timeLineQuery.data?.balance_ledgers,
                            backgroundColor: ['rgb(112, 193, 218)'],
                            fill: 'origin',
                            tension: 0.3,
                        },
                        {
                            label: 'Account Value',
                            data: timeLineQuery.data?.account_value,
                            backgroundColor: ['rgb(81, 224, 217)'],
                            fill: 'origin',
                            tension: 0.3,
                        },
                    ],
                }}
                height={140}
                width={140}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                }}
            />
        </>
    );
}

export default LineChart;
