import React from 'react';
import { Typography } from '@mui/material';
import { Input } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';

export default function UserIdentityInformation({ absherInformation, containerClassName }) {
    const { t } = useTranslation();

    const addresses = absherInformation?.identity?.addresses

    return (
        <div className={containerClassName}>
            <div className="space-y-5">
                <Typography variant="h6">{t('kyc.yourInformation')}</Typography>
                <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                    <Input label={t('kyc.firstName')} value={absherInformation.identity.first_name} disabled />
                    <Input label={t('kyc.secondName')} value={absherInformation.identity.second_name} disabled />
                    <Input label={t('kyc.thirdName')} value={absherInformation.identity.third_name} disabled />
                    <Input label={t('kyc.lastName')} value={absherInformation.identity.forth_name} disabled />
                    {absherInformation.identity.id_expiry_date ? (
                        <Input label={t('kyc.idExpiryDate')} value={absherInformation.identity.id_expiry_date} disabled />
                    ) : null}
                    <Input label={t('kyc.gender')} value={t(`common.${absherInformation.identity.gender}`)} disabled />
                    <Input label={t('kyc.nationality')} value={absherInformation.identity.nationality} disabled />
                </div>
            </div>

            <div className="space-y-4">
                <Typography variant="h6">{t('kyc.addressInformation')}</Typography>
                {addresses?.map((item, index) => (
                    <div key={index} className="space-y-3.5">
                        {addresses.length === 1 ? null : (
                            <Typography variant="body2" color="textSecondary">
                                {t('kyc.address', { index: index + 1 })}
                            </Typography>
                        )}
                        <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                            <Input label={t('kyc.unitNumber')} value={item.unit_number} disabled />
                            <Input label={t('kyc.buildingNumber')} value={item.building_number} disabled />
                            <Input label={t('kyc.streetName')} value={item.street_name} disabled />
                            <Input label={t('kyc.district')} value={item.district} disabled />
                            <Input label={t('kyc.city')} value={item.city} disabled />
                            <Input label={t('kyc.postCode')} value={item.post_code} disabled />
                            <Input label={t('kyc.additionalNumber')} value={item.additional_number} disabled />
                            <Input label={t('kyc.location')} value={item.location} disabled />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
