import React from 'react';
import { useTranslation } from 'react-i18next';
import { Btn, InputController } from '@aseel/common-ui';
import { Dialog, Divider, IconButton, Typography, Alert } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function VerifyUserIdentityDialog({
    isOpen,
    onClose,
    control,
    handleSubmit,
    verifyBtnName,
    verifyOtpErrorMsg,
    setIsVerifyDialogOpen,
}) {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit} className="space-y-2.5">
                <div className="flex justify-between px-5 pt-3 pb-2">
                    <Typography variant="h5">{t('fund.verifyIdentity')}</Typography>
                    <IconButton onClick={() => setIsVerifyDialogOpen(false)}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>
                <Divider />
                <div className="px-5 pt-2 pb-6 space-y-5">
                    {verifyOtpErrorMsg ? <Alert severity="error">{verifyOtpErrorMsg}</Alert> : null}
                    <div className="flex space-s-2.5">
                        <InputController control={control} name="code" label={t('fund.code')} />
                        <Btn variant="text" type="submit" name={verifyBtnName} fullWidth={false}>
                            {t('fund.verifySmsCode')}
                        </Btn>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}
