import { BtnBase } from '@aseel/common-ui';
import { Typography } from '@mui/material';
import useAuth from 'Hooks/UseAuth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function CompleteKYCCTA() {
    const { t } = useTranslation();

    return (
        <div className="text-center">
            <Typography variant="h4" className="mb-4">
                {t('authCTA.signInOrSignUp.startInvestingNow')}
            </Typography>

            <div className="max-w-sm mx-auto">
                <BtnBase component={Link} to="/sign-up/profile">
                    {t('authCTA.completeKYC.completeProfile')}
                </BtnBase>
            </div>
        </div>
    );
}

export default function CompleteKYC({ next }) {
    const {
        user: { is_kyc_completed },
    } = useAuth();

    if (is_kyc_completed === false) {
        return <CompleteKYCCTA />;
    }

    return next();
}
