import React from 'react';
import MyBalance from '../MyBalance';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { InputController } from '@aseel/common-ui';
import { Divider, FormHelperText, Typography } from '@mui/material';
import UnitsToBeInvestedSkeleton from './UnitsToBeInvestedSkeleton';
import { DASHBOARD_WALLET, UNITS_AMOUNTS } from 'Constants/QueriesKeys';

export default function UnitsToBeInvested({ unitsForm, minUnits, maxUnits, fundId }) {
    const { t } = useTranslation();

    const myWallet = useQuery(DASHBOARD_WALLET, () => http.get('/balance-inquiry').then((response) => response.data));

    const shares = unitsForm.watch('shares');

    const unitsQuery = useQuery(
        [UNITS_AMOUNTS, { fundId, units: shares }],
        () =>
            http
                .post(`/listings/${fundId}/shares-to-amount`, { shares: shares })
                .then((response) => response.data.data),
        {
            enabled: Boolean(shares > 0),
        },
    );

    return (
        <div>
            <div className="py-1">
                <Typography className="mb-4" variant="subtitle1" component="h2">
                    {t('fund.startInvesting')}
                </Typography>
                <div className="flex items-center">
                    <div className="w-1/2 me-3 lg:me-5">
                        <InputController
                            control={unitsForm.control}
                            name="shares"
                            label={t('fund.unitsToBeInvested')}
                            type="number"
                        />
                    </div>

                    <div className="w-1/2">
                        <Typography variant="body2" color="textSecondary">
                            {t('fund.amountIn')}
                        </Typography>
                        {unitsQuery.isLoading ? (
                            <UnitsToBeInvestedSkeleton />
                        ) : shares > 0 ? (
                            <Typography variant="h5">{unitsQuery.data?.amount}</Typography>
                        ) : (
                            <Typography variant="h5">---</Typography>
                        )}
                    </div>
                </div>
                <FormHelperText>{t('fund.minAndMaxAllowedUnits', { min: minUnits, max: maxUnits })}</FormHelperText>
            </div>

            <Divider className="my-4" />

            <MyBalance
                shares={shares}
                unitsQuery={unitsQuery}
                onRefresh={myWallet.refetch}
                isLoading={myWallet.isFetching}
                balance={myWallet.data?.balance}
            />
        </div>
    );
}
