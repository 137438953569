import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { Box } from '@mui/system';
import { green, red } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { DashedBoxEmpty } from '@aseel/common-ui';
import Typography from '@mui/material/Typography';
import RoundedCard from 'Components/Cards/RoundedCard';
import TableSkeleton from '../Skeletons/TableSkeleton';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { DASHBOARD_TRANSACTIONS } from 'Constants/QueriesKeys';
import TablePagination from 'Components/Table/TablePagination';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const moneyStyle = {
    credit: {
        color: green[500],
    },
    debit: {
        color: red[700],
    },
};

export default function MyTransactions() {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenRowMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseRowMenu = () => {
        setAnchorEl(null);
    };

    const myTransactions = useQuery([DASHBOARD_TRANSACTIONS, page], () =>
        http
            .get(`/transactions`, {
                params: {
                    page: page,
                },
            })
            .then((response) => response.data),
    );
    const transactionRows = myTransactions.data?.data;

    const transactionColumns = [
        {
            headerName: t('wallet.date'),
            className: 'w-2/12',
        },
        {
            headerName: t('wallet.description'),
            className: 'w-5/12',
        },
        {
            headerName: t('wallet.credit(+)'),
            className: 'w-2/12',
        },
        {
            headerName: t('wallet.debit(-)'),
            className: 'w-2/12',
        },
        {
            headerName: '',
            className: 'w-1/12',
        },
    ];

    const currentPage = myTransactions.data?.meta.current_page;
    const lastPage = myTransactions.data?.meta.last_page;
    const firstPage = myTransactions.data?.meta.from;

    if (myTransactions.isLoading) {
        return <TableSkeleton />;
    }

    return (
        <RoundedCard className="px-4 pt-8 md:px-10">
            <Typography variant="h4" className="mb-4 font-semibold text-gray-800">
                {t('wallet.transactions')}
            </Typography>
            {transactionRows.length ? (
                <div className="md:pt-2.5">
                    <div className="hidden mb-3 md:flex">
                        {transactionColumns.map((item) => (
                            <Typography className={item.className} fontWeight={800} variant="body2" color="grey.600">
                                {item.headerName}
                            </Typography>
                        ))}
                    </div>
                    <div>
                        {transactionRows.map((item, i) => (
                            <div className="flex items-center justify-between py-3 md:block" key={item.id}>
                                <div className="flex flex-col w-3/4 sm:flex-row md:hidden sm:items-center sm:w-full">
                                    <div className="sm:w-2/3">
                                        <Typography variant="caption" color="textSecondary" className="md:w-1/5">
                                            {item.created_at}
                                        </Typography>
                                        <Typography variant="body2" color="grey.800" className="md:w-2/5">
                                            {item.description.length > 0 ? item.description : '---'}
                                        </Typography>
                                    </div>
                                    <div className="sm:w-2/3 sm:text-center">
                                        {item.credit > 0 ? (
                                            <Typography
                                                variant="body2"
                                                className="font-semibold md:hidden"
                                                sx={moneyStyle.credit}>
                                                {item.credit}
                                            </Typography>
                                        ) : null}
                                        {item.debit < 0 ? (
                                            <Typography
                                                variant="body2"
                                                className="font-semibold md:hidden"
                                                sx={moneyStyle.debit}>
                                                {item.debit}
                                            </Typography>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="flex justify-center w-1/4 md:hidden">
                                    <IconButton
                                        size="small"
                                        className="font-semibold text-gray-800"
                                        sx={{ backgroundColor: ({ palette }) => palette.surface.gray30 }}
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="contained"
                                        disableElevation
                                        onClick={handleOpenRowMenu}>
                                        <KeyboardArrowDownIcon fontSize="small" />
                                    </IconButton>
                                </div>

                                <Box className="hidden md:flex md:space-s-2" sx={{ color: 'grey.800' }}>
                                    <Typography variant="body2" className="md:w-2/12">
                                        {item.created_at}
                                    </Typography>
                                    <Typography variant="body2" className="md:w-5/12">
                                        {item.description.length > 0 ? item.description : '---'}
                                    </Typography>
                                    <Typography variant="body2" className="md:w-2/12">
                                        {item.credit}
                                    </Typography>
                                    <Typography variant="body2" className="md:w-2/12">
                                        {item.debit}
                                    </Typography>
                                    <div className="md:w-1/12">
                                        <IconButton
                                            size="small"
                                            className="font-semibold text-gray-800"
                                            sx={{ backgroundColor: ({ palette }) => palette.surface.gray30 }}
                                            aria-expanded={open ? 'true' : undefined}
                                            variant="contained"
                                            disableElevation
                                            onClick={handleOpenRowMenu}>
                                            <KeyboardArrowDownIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </Box>
                            </div>
                        ))}
                    </div>
                    <div className="my-5">
                        <TablePagination
                            beforeOnClick={() => setPage(currentPage - 1)}
                            beforeDisabled={firstPage === currentPage}
                            nextOnClick={() => setPage(currentPage + 1)}
                            nextDisabled={currentPage === lastPage}
                        />
                    </div>
                </div>
            ) : (
                <DashedBoxEmpty title={t('wallet.noTransactionsFound')} />
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseRowMenu}
                onClick={handleCloseRowMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        boxShadow: '0px 0px 20px 2px rgba(189,189,189,0.3)',
                        borderRadius: 2.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                        },
                    },
                }}>
                <MenuItem disableRipple>
                    <Typography component="a" fontWeight={900} sx={{ color: ({ palette }) => palette.secondary.main }}>
                        {t('wallet.downloadReceipt')}
                    </Typography>
                </MenuItem>
            </Menu>
        </RoundedCard>
    );
}
