import { investorStatus } from 'Constants/InvestorStatus';
import useAuth from 'Hooks/UseAuth';
import { Redirect } from 'react-router-dom';

const notAllowedStatuses = [
    investorStatus.suspended,
    investorStatus.inReview,
    investorStatus.pendingReview,
    investorStatus.rejected,
    investorStatus.autoAmlCheckFailed,
];

export default function StatusGuard(next) {
    const { user } = useAuth();

    if (notAllowedStatuses.includes(user.status.value)) {
        return <Redirect to="/status" />;
    }

    return next();
}
