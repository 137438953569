import React from 'react';
import { Card, Skeleton, Box } from '@mui/material';

export default function SubmitProUpgradeRequestSkeleton() {
    return (
        <Box className="flex flex-col justify-center w-full lg:w-1/2 px-3 mx-auto" sx={{ minHeight: '90vh' }}>
            <Card>
                <div className="p-5">
                    <Skeleton className="w-1/4" />
                </div>
                <div className="pt-4 pb-5 px-5 space-y-6">
                    {Array(5)
                        .fill(0)
                        .map(() => (
                            <>
                                <Skeleton className="h-7" />
                            </>
                        ))}
                </div>
                <div className="flex justify-end p-5">
                    <Skeleton className="w-1/12" />
                </div>
            </Card>
        </Box>
    );
}
