import React from 'react';
import { Skeleton } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

function MyWalletSkeleton() {
    return (
        <div>
            <Typography variant="h4" className="pb-3">
                <Skeleton className="md:w-2/12 sm:w-2/12 w-4/12" />
            </Typography>
            <div>
                <Card className="md:flex flex-col md:flex-row md:items-center text-center hidden">
                    <CardContent className="flex flex-row-reverse justify-between md:block md:w-1/3">
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="150px" />
                            </Typography>
                        </div>
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="300px" />
                            </Typography>
                        </div>
                    </CardContent>
                    <CardContent className="flex flex-row-reverse justify-between md:block md:w-1/3">
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="150px" />
                            </Typography>
                        </div>
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="300px" />
                            </Typography>
                        </div>
                    </CardContent>
                    <CardContent className="flex lg:flex-row md:flex-col-reverse justify-between md:justify-center md:w-1/3 md:p-10 lg:space-x-2">
                        <div className="lg:w-36 md:w-full">
                            <Skeleton />
                        </div>
                        <div className="lg:w-36 md:w-full">
                            <Skeleton />
                        </div>
                    </CardContent>
                </Card>
                <Card className="flex flex-col md:flex-row md:items-center text-center md:hidden">
                    <CardContent className="flex flex-row-reverse justify-between md:block md:w-1/3">
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="110px" />
                            </Typography>
                        </div>
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="160px" />
                            </Typography>
                        </div>
                    </CardContent>
                    <CardContent className="flex flex-row-reverse justify-between md:block md:w-1/3">
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="90px" />
                            </Typography>
                        </div>
                        <div className="flex justify-center items-center space-x-3">
                            <Typography variant="h6">
                                <Skeleton width="210px" />
                            </Typography>
                        </div>
                    </CardContent>
                    <CardContent className="flex lg:flex-row md:flex-col-reverse justify-between md:justify-center md:w-1/3 md:p-10 lg:space-x-2">
                        <Skeleton width="100px" />
                        <Skeleton width="100px" />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default MyWalletSkeleton;
