import React from 'react';
import { Skeleton } from '@mui/material';
import { Typography } from '@mui/material';
import { FlexDataGrid } from '@aseel/common-ui';

function ReportSkeleton() {
    const reportColumns = [
        {
            field: 'title',
            headerName: <Skeleton width="600px" height="15px"/>,
            flex: 1,
            renderCell: () => {
                return (
                    <>
                        <Skeleton width="500px" height="15px"/>
                    </>
                );
            },
        },
        {
            field: 'subject',
            headerName: <Skeleton width="600px" height="15px"/>,
            flex:0.5 ,
            renderCell: () => {
                return (
                    <>
                        <Skeleton width="400px" height="15px"/>
                    </>
                );
            },
        },
       
    ];

    const reportRows = [
        {
            id: 1,
            title: 'default',
        },
        {
            id: 2,
            subject: 'default',
        },
        {
            id: 3,
            object: 'default',
        },
    ];

    return (
        <div className="px-6 py-10">
            <div>
                <Typography variant="h5" className="pb-3">
                    <Skeleton className="md:w-2/12 sm:w-5/12 w-4/12" />
                </Typography>
                <FlexDataGrid columns={reportColumns} rows={reportRows} hideFooter={true} />
            </div>
        </div>
    );
}

export default ReportSkeleton;
