export const FUNDS = 'FUNDS';
export const FUND_HELP = 'FUND_HELP';
export const DISCUSSIONS = 'DISCUSSIONS';
export const FUND_REPORTS = 'FUND_REPORTS';
export const DASHBOARD_UNITS = 'DASHBOARD_UNITS';
export const DASHBOARD_WALLET = 'DASHBOARD_WALLET';
export const DASHBOARD_OVERVIEW = 'DASHBOARD_OVERVIEW';
export const SUBSCRIPTION_FORMS = 'SUBSCRIPTION_FORMS';
export const PROFILE_INFORMATION = 'PROFILE_INFORMATION';
export const DASHBOARD_INVESTEMENTS = 'DASHBOARD_INVESTEMENTS';
export const DASHBOARD_TRANSACTIONS = 'DASHBOARD_TRANSACTIONS';
export const DASHBOARD_OVERVIEW_AVAILABLE_BALANCE = 'DASHBOARD_OVERVIEW_AVAILABLE_BALANCE';
export const PAGE_BANK_ACCOUNTS = 'PAGE_BANK_ACCOUNTS';
export const BANKS = 'BANKS';
export const CITIES = 'CITIES';
export const OVERVIEW_TIMELINE = 'OVERVIEW_TIMELINE';
export const UNITS_AMOUNTS = 'UNITS_AMOUNTS';
export const APPROVED_BANK_ACCOUNTS = 'APPROVED_BANK_ACCOUNTS';
export const ABSHER_INFORMATION = 'ABSHER_INFORMATION';
export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
export const PROFESSIONAL_UPGRADE_ITEMS = 'PROFESSIONAL_UPGRADE_ITEMS';
export const PROFESSIONAL_UPGRADE_REQUESTS = 'PROFESSIONAL_UPGRADE_REQUESTS';
export const CAN_SUBMIT_NEW_PRO_UPGRADE_REQUEST = 'CAN_SUBMIT_NEW_PRO_UPGRADE_REQUEST';
export const SYSTEM_SETTINGS = 'SYSTEM_SETTINGS';
export const INVESTING_RECORDS = 'INVESTING_RECORDS';
