import React from 'react';
import { Box } from '@mui/system';
import { IconButton, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function InvestingRecordsForMdAndLg({ investingRecordsData, handleOpenRowMenu, open }) {
    return (
        <div className='hidden md:block'>
            {investingRecordsData.map((item) => (
                <div className="flex items-center justify-between py-3 md:block" key={item.id}>
                    <Box className="hidden md:flex md:space-s-8" sx={{ color: 'grey.800' }}>
                        <Typography variant="body2" className="w-1/6">
                            {item.created_at}
                        </Typography>
                        <Typography variant="body2" className="w-1/6">
                            {item.listing}
                        </Typography>
                        <Typography variant="body2" className="w-1/6">
                            {item.amount_without_fees}
                        </Typography>
                        <Typography variant="body2" className="w-1/6">
                            {item.vat_amount}
                        </Typography>
                        <Typography variant="body2" className="w-1/6">
                            {item.admin_fees}
                        </Typography>
                        <div className="md:w-1/12">
                            {item.subscription_form === null && item.zatca_invoice === null ? null : (
                                <IconButton
                                    size="small"
                                    className="font-semibold text-gray-800"
                                    sx={{ backgroundColor: ({ palette }) => palette.surface.gray30 }}
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={(event) => handleOpenRowMenu(event, item)}>
                                    <KeyboardArrowDownIcon fontSize="small" />
                                </IconButton>
                            )}
                        </div>
                    </Box>
                </div>
            ))}
        </div>
    );
}
