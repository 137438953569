import { Typography } from '@mui/material';
import React from 'react';

export default function LocaleField({ name, children, variant = 'body1' }) {
    return (
        <div className="flex flex-wrap items-center w-full py-2">
            <Typography variant={variant} className="w-1/2 mb-0 md:order-1 md:w-auto">{name.ar}</Typography>
            <Typography variant={variant} className="order-3 w-full mb-0 text-center md:w-auto md:order-2 md:flex-1">
                {children}
            </Typography>
            <Typography variant={variant} className="w-1/2 mb-0 md:order-3 md:w-auto" style={{ direction: 'ltr' }}>
                {name.en}
            </Typography>
        </div>
    );
}
