import React from 'react';
import useAuth from 'Hooks/UseAuth';
import { Box, useTheme } from '@mui/system';
import { BtnBase } from '@aseel/common-ui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavLinks } from './Constants/NavLinks';
import { getNavProps } from 'Utils/GetNavProps';
import { Container, Divider, Typography } from '@mui/material';
import { ConfirmationDialog } from '@aseel/common-ui';
import NavLinksForMobile from './HeaderMenus/NavLinksForMobile';
import aseelLogo from '@aseel/common-assets/assets/aseel-logo-dark.png';
import symbolPart from '@aseel/common-assets/assets/symbol-part.svg';

export default function Header() {
    const auth = useAuth();
    const history = useHistory();
    const { push } = useHistory();
    const { palette } = useTheme();
    const { i18n, t } = useTranslation();

    const handleLangChange = () => {
        i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
    };

    const handleLogout = () => {
        ConfirmationDialog({
            text: t('common.logoutWarning'),
        })
            .then(() => {
                return auth.logout();
            })
            .then(() => {
                return history.push('/sign-in');
            });
    };

    return (
        <Box
            sx={{
                backgroundColor: palette.surface.gray20,
                borderBottom: `2px solid ${palette.surface.gray30}`,
                position: 'relative'
            }}>
            <Box
                className="absolute top-0 right-0 z-0 py-6 bg-no-repeat pr-28"
                sx={{
                    backgroundImage: `url(${symbolPart})`,
                }}></Box>
            <Container maxWidth="xl" className="flex items-center justify-between py-2">
                <div className="z-40 flex items-center md:space-s-8 lg:space-s-20">
                    <a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}`}>
                        <img src={aseelLogo} className="w-auto h-14 ps-2 sm:ps-0" alt="Aseel Logo" />
                    </a>
                    <div className="hidden md:space-s-5 lg:space-s-8 md:flex">
                        {NavLinks().map((navLink, key) => {
                            if (!navLink.isVisible(auth)) {
                                return null;
                            }

                            const props = getNavProps(navLink, auth);
                            return (
                                <Typography key={key} {...props}>
                                    {navLink.text}
                                </Typography>
                            );
                        })}
                    </div>
                </div>
                <div className="md:hidden">
                    <NavLinksForMobile
                        auth={auth}
                        NavLinks={NavLinks}
                        onLogout={handleLogout}
                        getNavProps={getNavProps}
                        onLangChange={handleLangChange}
                    />
                </div>

                <div className="items-center hidden md:flex">
                    <div
                        className="cursor-pointer me-3.5 rounded-md bg-white h-full"
                        style={{
                            boxShadow: '0px 0px 20px 2px rgba(189,189,189,0.3)',
                        }}
                        onClick={handleLangChange}>
                        <Typography fontWeight={600} textAlign="center" className="px-3 pb-1">
                            {i18n.language === 'en' ? 'ع' : 'En'}
                        </Typography>
                    </div>
                    <div className="flex space-s-8">
                        {auth.user ? null : (
                            <BtnBase variant="text" fullWidth={false} onClick={() => push('/sign-up')}>
                                {t('common.signUp')}
                            </BtnBase>
                        )}
                        <Divider
                            orientation="vertical"
                            flexItem
                            className="my-1.5 w-0.5"
                            sx={{ backgroundColor: palette.surface.gray20 }}
                        />
                        {auth.user ? (
                            <BtnBase fullWidth={false} onClick={handleLogout}>
                                {t('common.logout')}
                            </BtnBase>
                        ) : (
                            <BtnBase fullWidth={false} onClick={() => push('/sign-in')}>
                                {t('common.signIn')}
                            </BtnBase>
                        )}
                    </div>
                </div>
            </Container>
        </Box>
    );
}
