import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CheckboxController from 'Components/FormControls/Controller/CheckboxController';

function TermsAndConditions({ termsAndConditions, control, absherInformationQuery }) {
    const { t } = useTranslation();

    const absherInformation = absherInformationQuery.data;
    const name = generateFullName(absherInformation);
    const nin = absherInformation?.identity?.nin;

    return (
        <div dir="rtl">
            <div className="p-6 mb-4 overflow-auto bg-gray-100 rounded" style={{ maxHeight: '40vh' }}>
                <div dangerouslySetInnerHTML={{ __html: termsAndConditions }}></div>
            </div>
            <CheckboxController
                control={control}
                name="accept"
                controlLabelClassName="items-start"
                label={
                    <Trans t={t} i18nKey="kyc.acceptCheckbox">
                        <span className="font-semibold">{{ name }}</span>
                        <span className="font-semibold">{{ nin }}</span>
                    </Trans>
                }
            />
        </div>
    );
}

function generateFullName(absherInformation) {
    if (!absherInformation) {
        return '';
    }

    const identity = absherInformation.identity;

    return [
        identity.first_name,
        identity.second_name,
        identity.third_name,
        identity.forth_name,
    ]
        .filter((item) => Boolean(item))
        .join(' ');
}

export default TermsAndConditions;
