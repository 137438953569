import i18n from 'Utils/I18n';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

export const NavLinks = () => [
    {
        text: i18n.t('navLinks.statistics'),
        path: '/app/overview',
        icon: TimelineOutlinedIcon,
    },
    {
        text: i18n.t('navLinks.myWallet'),
        path: '/app/wallet',
        icon: AccountBalanceWalletOutlinedIcon,
    },
    {
        text: i18n.t('navLinks.myProfile'),
        path: '/app/profile',
        icon: PermIdentityOutlinedIcon,
    },
];
