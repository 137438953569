import useAuth from 'Hooks/UseAuth';
import { http } from 'Utils/Http/Http';
import { SignInContainer } from '@aseel/common-components';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ProjectLogoDark from '@aseel/common-assets/assets/aseel-logo-dark.png';
import { InputController, PasswordInputController, Btn, BtnBase } from '@aseel/common-ui';
import { Box } from '@mui/system';
import patternGray from '@aseel/common-assets/assets/pattern-random-gray.svg';
import { amber } from '@mui/material/colors';

const SmallCircle = ({ classes, sx }) => (
    <Box className={['absolute rounded-full h-2 w-2 z-20'].concat(classes).join(' ')} sx={{ ...sx }}></Box>
);

export default function SignInPage() {
    const { t } = useTranslation();
    const auth = useAuth();
    return (
        <SignInContainer
            signInRequest={(values) =>
                http
                    .post('/api/login', values, {
                        baseURL: process.env.REACT_APP_BASE_URL,
                    })
                    .then(() => auth.refreshCsrf())
                    .then(() => auth.fetchUserProfile())
            }
            redirectPath="/app/overview">
            {({ control, handleSignIn, signInBtnName }) => (
                <Box className="flex items-center justify-center h-screen overflow-x-hidden" bgcolor="surface.gray30">
                    <div className="w-full px-4">
                        <div className="relative max-w-lg mx-auto">
                            <Box sx={{ top: -50, right: -80, position: 'absolute', zIndex: 0 }}>
                                <img src={patternGray} />
                            </Box>

                            {/* top side - outer */}
                            <SmallCircle
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    top: { lg: -120, sm: -100 },
                                    left: { lg: -150, sm: -100 },
                                }}
                                classes="bg-blue-500"
                            />
                            <SmallCircle
                                sx={{ top: { lg: -100, sm: -80, xs: -50 }, left: { lg: 0, sm: 0, xs: 15 } }}
                                classes="bg-red-500"
                            />

                            {/* top side - inner */}
                            <SmallCircle
                                sx={{
                                    top: { lg: 50, sm: 50, xs: 60 },
                                    left: { lg: 40, sm: 40, xs: 20 },
                                    bgcolor: amber[500],
                                }}
                            />
                            <SmallCircle
                                sx={{ top: { lg: 30, sm: 30, xs: 20 }, left: { lg: 100, sm: 100, xs: 50 } }}
                                classes="bg-green-500"
                            />

                            <div className="relative z-10 p-6 py-8 bg-white rounded-lg sm:p-10">
                                <div>
                                    <a href={process.env.REACT_APP_HOME_URL}>
                                        <img className="w-auto h-16 mx-auto" src={ProjectLogoDark} alt="Logo" />
                                    </a>
                                    <Typography
                                        component="h2"
                                        className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                                        {t('signIn.signInToYourAccount')}
                                    </Typography>
                                </div>

                                <div className="mt-8">
                                    <div className="mt-6">
                                        <form className="space-y-4" onSubmit={handleSignIn}>
                                            <InputController
                                                control={control}
                                                name="email"
                                                label={t('formLabels.email')}
                                            />
                                            <PasswordInputController
                                                control={control}
                                                name="password"
                                                label={t('formLabels.password')}
                                            />

                                            <div className="flex items-center justify-between">
                                                <Typography>
                                                    <Link component={RouterLink} to="/forgot-password" color="grey.800">
                                                        {t('signIn.forgotYourPassword')}
                                                    </Link>
                                                </Typography>
                                            </div>

                                            <Btn type="submit" name={signInBtnName}>
                                                {t('signIn.signIn')}
                                            </Btn>

                                            <>
                                                <div class="relative">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-solid border-gray-300"></div>
                                                    </div>
                                                    <div class="relative flex justify-center">
                                                        <Typography
                                                            component="span"
                                                            className="px-2 text-sm text-gray-500 bg-white">
                                                            {t('common.or')}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <BtnBase
                                                    variant="outlined"
                                                    component={RouterLink}
                                                    to="/sign-up"
                                                    type="submit"
                                                    className="mt-2">
                                                    {t('signIn.signUp')}
                                                </BtnBase>
                                            </>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            )}
        </SignInContainer>
    );
}
