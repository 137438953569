import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BtnBase } from '@aseel/common-ui';
import clsx from 'clsx';
import { investorStatus } from 'Constants/InvestorStatus';

const dangerStatuses = [investorStatus.rejected, investorStatus.suspended];

export default function TitleAndDescription({
    title,
    Icon,
    userStatus,
    description,
    image,
    hasIcon = false,
    hasImage = false,
    hasContactUs = false,
}) {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex flex-col justify-center items-center space-y-2.5">
            {hasIcon ? (
                <div
                    className={clsx([
                        dangerStatuses.includes(userStatus) ? 'bg-yellow-100' : 'bg-green-100',
                        'flex justify-center items-center h-14 w-14 rounded-full',
                    ])}>
                    <Icon
                        fontSize="large"
                        className={clsx([
                            dangerStatuses.includes(userStatus) ? 'text-yellow-800' : 'text-green-800',
                            'mb-1',
                        ])}
                    />
                </div>
            ) : null}
            {hasImage ? (
                <div>
                    <img src={image} alt="" className='h-40' />
                </div>
            ) : null}

            <div className="text-center">
                <Typography variant="h6">{title}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    {description}
                </Typography>
                {hasContactUs ? (
                    <BtnBase
                        className="mt-3"
                        component="a"
                        fullWidth={false}
                        href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}/contact-us`}>
                        {t('common.contactUs')}
                    </BtnBase>
                ) : null}
            </div>
        </div>
    );
}
