import React from 'react';
import Chip from '@mui/material/Chip';
import { green, lime, orange, red, teal } from '@mui/material/colors';
import { approvalStatus, investorStatus, investorUpgradeRequestStatus } from '@aseel/constants';

export const approvalStatusModalTypes = {
    general: 1,
    investor: 2,
    investorUpgradeRequest: 3,
};

const styles = {
    [approvalStatusModalTypes.general]: {
        [approvalStatus.rejected]: {
            backgroundColor: red[100],
            color: red[900],
        },
        [approvalStatus.approved]: {
            backgroundColor: green[100],
            color: green[900],
        },
        [approvalStatus.pendingReview]: {
            backgroundColor: lime[100],
            color: lime[900],
        },
        [approvalStatus.inReview]: {
            backgroundColor: teal[100],
            color: teal[900],
        },
        [approvalStatus.needMoreInfo]: {
            backgroundColor: orange[100],
            color: orange[900],
        },
    },
    [approvalStatusModalTypes.investor]: {
        [investorStatus.rejected]: {
            backgroundColor: red[100],
            color: red[900],
        },
        [investorStatus.approved]: {
            backgroundColor: green[100],
            color: green[900],
        },
        [investorStatus.pendingReview]: {
            backgroundColor: lime[100],
            color: lime[900],
        },
        [investorStatus.inReview]: {
            backgroundColor: teal[100],
            color: teal[900],
        },
        [investorStatus.suspended]: {
            backgroundColor: red[100],
            color: red[900],
        },
    },
    [approvalStatusModalTypes.investorUpgradeRequest]: {
        [investorUpgradeRequestStatus.rejected]: {
            backgroundColor: red[100],
            color: red[900],
        },
        [investorUpgradeRequestStatus.approved]: {
            backgroundColor: green[100],
            color: green[900],
        },
        [investorUpgradeRequestStatus.pendingReview]: {
            backgroundColor: lime[100],
            color: lime[900],
        },
        [investorUpgradeRequestStatus.inReview]: {
            backgroundColor: teal[100],
            color: teal[900],
        },
    },
};

export default function ApprovalStatusChip({ label, status, modalType, ...otherProps }) {
    return <Chip size="small" label={label} sx={styles[modalType][status]} {...otherProps} />;
}
