import {  Skeleton } from '@mui/material';
import React from 'react';

export default function ShowProfessionalUpgradeRequestSkeleton() {
    return (
        <div>
            <div className="p-3 ">
                <div className="flex justify-between">
                    <Skeleton className="w-2/12" />
                    <Skeleton className="w-2/12" />
                </div>
                <div className="flex justify-between">
                    <Skeleton className="w-2/12" />
                    <Skeleton className="w-2/12" />
                </div>
            </div>
            <div className="p-3">
                <Skeleton className="w-1/4" />
                <Skeleton className="w-1/2" />
            </div>
            <div className="p-3">
                <Skeleton className="w-1/4" />
                <Skeleton className="w-1/2" />
                <div className="mt-3.5">
                    <Skeleton className="w-1/4" />
                    <Skeleton className="w-1/2" />
                </div>
            </div>
        </div>
    );
}
