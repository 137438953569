import React from 'react';
import { WhatsappShareBtn, TwitterShareBtn, FacebookShareBtn } from '@aseel/common-ui';
import { Box } from '@mui/system';

export default function ({ title, url, sx, className }) {
    return (
        <Box sx={sx} className={className}>
            <WhatsappShareBtn title={title} url={url} />
            <TwitterShareBtn title={title} url={url} />
            <FacebookShareBtn quote={title} url={url} />
        </Box>
    );
}
