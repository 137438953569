import React from 'react';
import { Card } from '@mui/material';

export default function RoundedCard({ children, ...props }) {
    return (
        <Card sx={{ borderRadius: 5 }} elevation={0} {...props}>
            {children}
        </Card>
    );
}
