import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { BtnBase } from '@aseel/common-components/node_modules/@aseel/common-ui';

export default function AfterRegistrationPage() {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    return (
        <div className="flex items-center justify-center flex-grow py-20 my-6 sm:px-0">
            <Container maxWidth="xs">
                <Card>
                    <CardContent>
                        <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <CheckRoundedIcon className="w-8 h-8 text-green-600" />
                        </div>
                        <div className="text-center">
                            <div className="my-3.5">
                                <Typography variant="h6" className="font-semibold">
                                    {t('afterRegistration.youRegisteredSuccessfully')}
                                </Typography>
                                <Typography variant="body1">{t('afterRegistration.youCanNow')}</Typography>
                            </div>
                            <div className="space-y-1.5">
                                <div className="space-y-1.5">
                                    <BtnBase onClick={() => history.push('/sign-up/profile')} color="primary">
                                        {t('afterRegistration.completeYourProfile')}
                                    </BtnBase>
                                    <BtnBase
                                        href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}#funds`}
                                        variant="outlined">
                                        {t('common.exploreFunds')}
                                    </BtnBase>
                                </div>
                                <Typography className="pt-1 text-xs text-gray-500">
                                    {t('afterRegistration.oneMoreStepToEnterTheWorldOfInvestingPleaseCompleteYourProfile')}
                                </Typography>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}
