import i18n from 'Utils/I18n';

export const NavLinks = () => [
    {
        type: 'url',
        text: i18n.t('footer.aboutUs'),
        isVisible: () => true,
        link: `${process.env.REACT_APP_HOME_URL}/${i18n.language}/about-us`,
    },
    {
        type: 'url',
        text: i18n.t('footer.howItWorks'),
        isVisible: () => true,
        link: `${process.env.REACT_APP_HOME_URL}/${i18n.language}#howItWorks`,
    },
    {
        type: 'url',
        text: i18n.t('footer.invest'),
        isVisible: () => true,
        link: `${process.env.REACT_APP_HOME_URL}/${i18n.language}#funds`,
    },
    {
        type: 'route',
        text: i18n.t('footer.dashboard'),
        isVisible: (auth) => auth.user && auth.user.is_kyc_completed,
        path: '/app/overview',
    },
];
