import { Card, CardContent, Container, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CountryCodes from 'Constants/CountryCodes.json';
import { yupResolver } from '@hookform/resolvers/yup';
import { Yup } from '@aseel/common-utils';
import { http } from 'Utils/Http/Http';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import useAuth from 'Hooks/UseAuth';
import { PasswordInputController, InputController, SelectController, Btn, setProgress } from '@aseel/common-ui';
import { handleError } from '@aseel/http';
import { useTheme } from '@mui/system';
import clsx from 'clsx';

const REGISTER = 'REGISTER_BTN';

const schema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    phone_country_iso2: Yup.string().default('SA').required().nullable(),
    phone: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'passwordRule'),
    password_confirmation: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'passwordRule',
    ),
});

function RegisterPage() {
    const { direction } = useTheme();

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            phone_country_iso2: 'SA',
        },
    });

    const auth = useAuth();

    const history = useHistory();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleRegister = async (values) => {
        setProgress(REGISTER, true);
        try {
            const token = await executeRecaptcha();
            const {
                data: { score },
            } = await http.get('/recaptcha/validate', {
                params: { token },
                baseURL: process.env.REACT_APP_BASE_URL,
            });

            if (score >= 0.5) {
                await http.post('/register', values);
                await auth.refreshCsrf();
                await auth.fetchUserProfile();
                history.push('/after-registration');
            }
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(REGISTER, false);
    };

    const { t } = useTranslation();

    return (
        <Container maxWidth="sm" className="flex flex-col items-center justify-center flex-1 h-full my-10 md:px-6 lg:my-20">
            <Card className="w-full overflow-visible">
                <CardContent>
                    <Typography className="mb-2" variant="h4" component="h1">
                        {t('register.title')}
                    </Typography>

                    <Typography className="mb-4 text-gray-700" variant="subtitle1" component="p">
                        {t('register.subtitle')}
                    </Typography>
                    {/* //"grid grid-cols-6 gap-4" */}
                    <form onSubmit={handleSubmit(handleRegister)}>
                        <div className="grid gap-4">
                            <InputController name="first_name" control={control} label={t('register.firstName')} />
                            <InputController name="last_name" control={control} label={t('register.lastName')} />
                            <InputController name="email" control={control} label={t('register.email')} />
                            <div className={clsx('flex', direction === 'ltr' && 'flex-row-reverse')}>
                                <InputController
                                    name="phone"
                                    control={control}
                                    label={t('register.phone')}
                                    className="w-7/12 ml-4 sm:w-2/3"
                                />
                                <SelectController
                                    control={control}
                                    name="phone_country_iso2"
                                    label={t('register.countryCode')}
                                    options={CountryCodes}
                                    getOptionValue={(option) => option.Iso2}
                                    getOptionLabel={(option) => option.Dial}
                                    className="w-5/12 sm:w-1/3"
                                    isClearable={false}
                                />
                            </div>

                            <div className="grid gap-4 md:grid-cols-2">
                                <PasswordInputController
                                    name="password"
                                    control={control}
                                    label={t('register.password')}
                                    hasCheckList
                                />
                                <PasswordInputController
                                    name="password_confirmation"
                                    control={control}
                                    label={t('register.confirmPassword')}
                                />
                            </div>

                            <Btn type="submit" name={REGISTER}>
                                {t('register.submit')}
                            </Btn>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </Container>
    );
}

export default RegisterPage;
