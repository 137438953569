import React from 'react';
import AppLayout from 'Layouts/AppLayout/AppLayout';
import WalletPage from 'Pages/Dashboard/Wallet/WalletPage';
import ProfilePage from 'Pages/Dashboard/Profile/ProfilePage';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import OverviewPage from 'Pages/Dashboard/Overview/OverviewPage';
import ReportsPage from 'Pages/Dashboard/Funds/Reports/ReportsPage';
import SubmitProUpgradeRequestPage from 'Pages/UpgradeProfile/SubmitProUpgradeRequestPage';
import ProfessionalUpgradeRequestsPage from 'Pages/UpgradeProfile/ProfessionalUpgradeRequestsPage';

function AppEntry() {
    const { path } = useRouteMatch();
    return (
        <AppLayout>
            <Switch>
                <Route path={`${path}/wallet`} component={WalletPage} />
                <Route path={`${path}/profile`} component={ProfilePage} />
                <Route exact path={`${path}/overview`} component={OverviewPage} />
                <Route exact path={`${path}/professional-upgrade`} component={SubmitProUpgradeRequestPage} />
                <Route exact path={`${path}/professional-upgrade/requests`} component={ProfessionalUpgradeRequestsPage} />
                <Route path={`${path}/funds/:fundId/reports`} component={ReportsPage} />
            </Switch>
        </AppLayout>
    );
}

export default AppEntry;
