import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useAuth from 'Hooks/UseAuth';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BankLogo from 'Assets/Bank-logo.jpeg';
import copy from 'copy-to-clipboard';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';

const DepositInfo = ({ isOpen, onClose }) => {
    const auth = useAuth();
    const { t } = useTranslation();

    const handleNameCopy = () => {
        copy(`${auth.user.first_name} ${auth.user.last_name}`);
    };

    const handleAccNumberCopy = () => {
        copy(auth.user.virtaul_account.number);
    };

    const handleIbanCopy = () => {
        copy(auth.user.virtaul_account.iban);
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogContent className="space-y-3.5 py-6">
                <img src={BankLogo} alt="Arab National Bank" className="w-32 pb-3" />
                <div>
                    <div className="flex">
                        <div>
                            <Typography variant="body1">{t('overview.name')}</Typography>
                            <Typography>
                                {auth.user.first_name} {auth.user.last_name}
                            </Typography>
                        </div>
                        <IconButton className="mt-3.5" onClick={handleNameCopy} size="large">
                            <FileCopyRoundedIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <div className="flex">
                        <div>
                            <Typography variant="body1">{t('overview.accNumber')}</Typography>
                            <Typography>{auth.user.virtaul_account.number} </Typography>
                        </div>
                        <IconButton className="mt-3" onClick={handleAccNumberCopy} size="large">
                            <FileCopyRoundedIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <div className="flex">
                        <div>
                            <Typography variant="body1">{t('overview.iban')}</Typography>
                            <Typography> {auth.user.virtaul_account.iban}</Typography>
                        </div>
                        <IconButton className="mt-3" onClick={handleIbanCopy} size="large">
                            <FileCopyRoundedIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DepositInfo;
