import { useTranslation } from 'react-i18next';

export const FooterSections = () => {
    const { i18n, t } = useTranslation();
    const locale = i18n.language;

    return [
        {
            title: i18n.t('footer.legal'),
            links: [
                {
                    text: t('footer.privacyPolicy'),
                    link: `${process.env.REACT_APP_HOME_URL}/${locale}/privacy-policy`,
                    type: 'url',
                },
                {
                    text: t('footer.ShariaaCompliance'),
                    link: `${process.env.REACT_APP_HOME_URL}/${locale}/sharia-compliance`,
                    type: 'url',
                },
                {
                    text: t('footer.termsAndConditions'),
                    link: `${process.env.REACT_APP_HOME_URL}/${locale}/terms-and-conditions`,
                    type: 'url',
                },
                {
                    text: t('footer.disclosurePolicy'),
                    link: `${process.env.REACT_APP_HOME_URL}/${locale}/disclosure-policy`,
                    type: 'url',
                },
            ],
        },
        {
            title: i18n.t('footer.company'),
            links: [
                {
                    text: t('footer.aboutUs'),
                    link: `${process.env.REACT_APP_HOME_URL}/${locale}/about-us`,
                    type: 'url',
                },
                {
                    text: t('footer.membersOfCouncil'),
                    link: `${process.env.REACT_APP_HOME_URL}/${locale}/members-council`,
                    type: 'url',
                },
                {
                    text: t('footer.contactUs'),
                    link: `${process.env.REACT_APP_HOME_URL}/${locale}/contact-us`,
                    type: 'url',
                },
            ],
        },
    ];
};
