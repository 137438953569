import React from 'react';
import { ButtonBase, Typography } from '@mui/material';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

export default function RiskAccordion({ value, files }) {
    return (
        <>
            <Typography className="text-gray-900 mb-2" dangerouslySetInnerHTML={{ __html: value }} />
            {files?.map((file) => (
                <div>
                    <ButtonBase component="a" href={file.url} target="_blank" className="items-center justify-start">
                        <GetAppRoundedIcon className="me-3" />
                        {file.name}
                    </ButtonBase>
                </div>
            ))}
        </>
    );
}
