import React from 'react';
import { FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputController, SelectController } from '@aseel/common-ui';
import RoundedRadioGroupController from 'Components/FormControls/Controller/RoundedRadioGroupController';
import RoundedCheckboxGroupController from 'Components/FormControls/Controller/RoundedCheckboxGroupController';

function FinancialStatus({ control, netWorth, currentInvestments, invObjectives, moneyRanges }) {
    const { t } = useTranslation();

    return (
        <div className="space-y-5">
            <RoundedRadioGroupController
                row
                control={control}
                name="net_worth"
                label={t('kyc.netWorth')}
                formControlLabelSx={{ width: { xs: '100%', sm: '40%' } }}
                options={netWorth.map(({ name, key }) => ({ label: name, value: key.toString() }))}
            />

            <RoundedCheckboxGroupController
                row
                control={control}
                name="current_invest"
                label={t('kyc.currentInvestments')}
                formControlLabelSx={{ width: { xs: '100%', sm: '40%' } }}
                options={currentInvestments.map(({ name, key }) => ({ label: name, value: key.toString() }))}
            />

            <RoundedCheckboxGroupController
                row
                label={t('kyc.investmentObjective')}
                control={control}
                name="invest_objective"
                formControlLabelSx={{ width: { xs: '100%', sm: '40%' } }}
                options={invObjectives.map(({ name, key }) => ({ label: name, value: key.toString() }))}
            />

            <InputController
                name="financial_info.years_of_inv_in_securities"
                control={control}
                label={t('kyc.noOfYearsOfInvInSecurities')}
                type="number"
            />

            <SelectController
                label={t('kyc.annualIncome')}
                control={control}
                name="annual_income"
                options={moneyRanges}
                getOptionValue={({ key }) => key}
                getOptionLabel={({ name }) => name}
            />

            <SelectController
                label={t('kyc.expectedInvestmetPerChance')}
                control={control}
                name="expected_invest_amount_per_opportunity"
                options={moneyRanges}
                getOptionValue={({ key }) => key}
                getOptionLabel={({ name }) => name}
            />

            <SelectController
                label={t('kyc.expectedInvestmetAnnually')}
                control={control}
                name="expected_annual_invest"
                options={moneyRanges}
                getOptionValue={({ key }) => key}
                getOptionLabel={({ name }) => name}
            />

            <div>
                <FormLabel className="block mb-2">
                    {t('kyc.anyOtherFinancialInformationOnTheClientsFinancialSituation')}
                </FormLabel>
                <InputController
                    row
                    control={control}
                    multiline
                    rows={5}
                    name="extra_financial_information.extra_financial_information"
                    placeholder={t('kyc.anyOtherFinancialInformationOnTheClientsFinancialSituation')}
                />
            </div>
        </div>
    );
}

export default FinancialStatus;
