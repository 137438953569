import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputController, SelectController } from '@aseel/common-components/node_modules/@aseel/common-ui';

export default function BankAccountAndAmount({ form, approvedAccounts, areAccountsLoading }) {
    const { t } = useTranslation();

    return (
        <div>
            <Typography variant="h5">{t('wallet.withdraw')}</Typography>
            <div className="space-y-5 mt-7">
                <SelectController
                    options={approvedAccounts}
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.name}
                    control={form.control}
                    label={t('wallet.bankAccount')}
                    name="bank_account_id"
                    isLoading={areAccountsLoading}
                />
                <InputController control={form.control} name="amount" label={t('wallet.amount_sar')} />
            </div>
        </div>
    );
}
