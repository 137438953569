import React from 'react';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@mui/material';
import RoundedCard from 'Components/Cards/RoundedCard';

function BoldGrayTypography({ children, ...otherProps }) {
    return (
        <Typography fontWeight={900} color={grey[800]} {...otherProps}>
            {children}
        </Typography>
    );
}

export default function UserIdentityInformation({ absherInformation, informationData }) {
    const { t } = useTranslation();

    const addresses = absherInformation?.identity?.addresses;

    const yourInformation =
        absherInformation.is_identity_verified === false
            ? []
            : [
                  {
                      text: t('profile.firstName'),
                      value: absherInformation.identity.first_name,
                  },
                  {
                      text: t('profile.secondName'),
                      value: absherInformation.identity.second_name,
                  },
                  {
                      text: t('profile.thirdName'),
                      value: absherInformation.identity.third_name,
                  },
                  {
                      text: t('profile.lastName'),
                      value: absherInformation.identity.forth_name,
                  },
                  {
                      text: t('profile.idExpiryDate'),
                      value: absherInformation.identity.id_expiry_date,
                  },
                  {
                      text: t('profile.gender'),
                      value: absherInformation.identity.gender,
                  },
                  {
                      text: t('profile.idExpiryDate'),
                      value: absherInformation.identity.id_expiry_date,
                  },
                  {
                      text: t('profile.nationality'),
                      value: absherInformation.identity.nationality,
                  },
              ];

    const nationalAddress = (item) => [
        {
            text: t('profile.unitNumber'),
            value: item.unit_number,
        },
        {
            text: t('profile.buildingNumber'),
            value: item.building_number,
        },
        {
            text: t('profile.streetName'),
            value: item.street_name,
        },
        {
            text: t('profile.district'),
            value: item.district,
        },
        {
            text: t('profile.city'),
            value: item.city,
        },
        {
            text: t('profile.postCode'),
            value: item.post_code,
        },
        {
            text: t('profile.additionalNumber'),
            value: item.additional_number,
        },
        {
            text: t('profile.location'),
            value: item.location,
        },
    ];

    return (
        <div>
            <BoldGrayTypography variant="h4" className="mb-8">
                {t('profile.personalInformation')}
            </BoldGrayTypography>
            <div className="space-y-4 lg:flex lg:space-s-5 lg:space-y-0">
                <RoundedCard className="py-12 lg:w-1/2">
                    <div className="mb-7 sm:px-6 px-2.5 space-y-8">
                        <BoldGrayTypography variant="h5">{t('profile.accountDetails')}</BoldGrayTypography>
                        <div className="grid grid-cols-2 gap-5">
                            <div>
                                <BoldGrayTypography>{t('profile.name')}</BoldGrayTypography>
                                <Typography>{`${informationData?.first_name} ${informationData?.last_name}`}</Typography>
                            </div>
                            <div>
                                <BoldGrayTypography>{t('profile.email')}</BoldGrayTypography>
                                <Typography>{informationData?.email}</Typography>
                            </div>
                        </div>
                    </div>
                    {absherInformation.is_identity_verified ? (
                        <>
                            <Divider className="mb-5" />
                            <div className="sm:px-6 px-2.5">
                                <BoldGrayTypography variant="h5" className="mb-8">
                                    {t('profile.yourInformation')}
                                </BoldGrayTypography>
                                <div className="grid grid-cols-2 gap-5 sm:grid-cols-4">
                                    {yourInformation.map((item) => (
                                        <div>
                                            <BoldGrayTypography> {item.text}</BoldGrayTypography>
                                            <Typography>{item.value}</Typography>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : null}
                </RoundedCard>
                {absherInformation.is_identity_verified ? (
                    <RoundedCard className="lg:w-1/2  py-12 sm:px-6 px-2.5">
                        <BoldGrayTypography variant="h5" className="mb-8">
                            {t('profile.nationalAddress.nationalAddress')}
                        </BoldGrayTypography>
                        <div className="space-y-4">
                            {addresses?.map((item, index) => (
                                <div key={index} className="space-y-3.5">
                                    {addresses.length === 1 ? null : (
                                        <Typography variant="body2" color="textSecondary">
                                            {t('profile.nationalAddress.address', { index: index + 1 })}
                                        </Typography>
                                    )}
                                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-3.5">
                                        {nationalAddress(item).map((item) => (
                                            <div>
                                                <BoldGrayTypography>{item.text}</BoldGrayTypography>
                                                <Typography>{item.value}</Typography>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </RoundedCard>
                ) : null}
            </div>
        </div>
    );
}
