import Menu from '@mui/material/Menu';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { BtnBase } from '@aseel/common-ui';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 185,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

const getNavProps = (menu) => {
    const props = {};

    if (menu.type === 'url') {
        props.component = 'a';
        props.href = menu.link;
    } else if (menu.type === 'button') {
        props.onClick = menu.onClick;
    }

    return props;
};

export default function ProfileMenu({ menus, auth, palette }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <BtnBase
                fullWidth={false}
                sx={{
                    backgroundColor: palette.surface.gray30,
                    ':hover': {
                        backgroundColor: palette.surface.gray30,
                    },
                }}
                className="text-gray-800 font-semibold rounded-full"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                endIcon={open ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}>
                <Typography
                    variant="body2"
                    fontWeight={600}>{`${auth.user.first_name} ${auth.user.last_name}`}</Typography>
            </BtnBase>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {menus?.map((menu) => {
                    const props = getNavProps(menu);
                    return (
                        <MenuItem {...props}>
                            <Typography>{menu.label}</Typography>
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </div>
    );
}
