import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export default function SuccessTransfer() {
    const { t } = useTranslation();

    return (
        <div className="mt-5">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <CheckRoundedIcon className="h-8 w-8 text-green-600" />
            </div>
            <div className="text-center mt-2">
                <Typography component="h1" variant="h6">
                    {t('wallet.successfulTransfer')}
                </Typography>
            </div>
        </div>
    );
}
