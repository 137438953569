import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
    iconContainer: {
        backgroundColor: 'secondary.main',
    },
};

export default function TimelineAccordion({ updates }) {
    const { t } = useTranslation();

    return updates === null ? (
        <div className="text-center">
            <Typography>{t('fund.updatesWillBeAvailableSoon')}</Typography>
        </div>
    ) : (
        <div className="flow-root">
            <ul className="-mb-8">
                {updates?.map((update, index) => (
                    <li key={index}>
                        <div className="relative pb-8">
                            {index !== updates.length - 1 ? (
                                <span
                                    className="absolute top-4 start-4 -ml-px h-full w-0.5 bg-gray-200"
                                    aria-hidden="true"
                                />
                            ) : null}
                            <div className="relative flex space-s-3">
                                <div>
                                    <Typography
                                        className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                        sx={styles.iconContainer}></Typography>
                                </div>
                                <div className="min-w-0 flex-1 flex justify-between items-start space-x-4">
                                    <div>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{
                                                __html: update.title,
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            component="time"
                                            dateTime={update.date}
                                            dangerouslySetInnerHTML={{
                                                __html: update.date,
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            dangerouslySetInnerHTML={{
                                                __html: update.description,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
