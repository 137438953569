import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded';
import { BtnBase } from '@aseel/common-components/node_modules/@aseel/common-ui';
import RoundedCard from 'Components/Cards/RoundedCard';

export default function UpgradeRequestIsInReviewNoticePage() {
    const { t } = useTranslation();
    const { push } = useHistory();

    return (
        <div className="flex flex-col justify-center flex-grow" style={{ minHeight: '90vh' }}>
            <Box className="lg:max-w-3xl py-5 sm:py-0 px-2 sm:px-3.5 md:px-6 lg:px-0 mx-auto h-full">
                <RoundedCard className="p-5 space-y-2.5">
                    <div className="bg-green-100 flex justify-center items-center h-14 w-14 rounded-full mx-auto my-0.5">
                        <CelebrationRoundedIcon fontSize="large" className="text-green-800" />
                    </div>
                    <Typography variant="h6" textAlign="center">
                        {t('upgradeInvestorLevel.upgradingToProfessionalInvestor')}
                    </Typography>
                    <Typography variant="body1" textAlign="center" color="grey.700">
                        {t('upgradeInvestorLevel.weAreReviewingYourRequestToUpgradeYourAccount')}
                    </Typography>
                    <div className="flex justify-center pt-3">
                        <BtnBase
                            color="secondary"
                            fullWidth={false}
                            onClick={() => push(`professional-upgrade/requests`)}>
                            {t('upgradeInvestorLevel.yourRequests')}
                        </BtnBase>
                    </div>
                </RoundedCard>
            </Box>
        </div>
    );
}
