import IconBtnBase from '../IconBtnBase';
import React from 'react';
import objectToGetParams from './Utils/ObjectToGetParams';
import TwitterIcon from '../../../Icons/TwitterIcon';

function twitterLink(url, { title, via, hashtags = [], related = [] }) {
    return (
        'https://twitter.com/share' +
        objectToGetParams({
            url,
            text: title,
            via,
            hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
            related: related.length > 0 ? related.join(',') : undefined,
        })
    );
}

export default function TwitterShareBtn({ url, title, via, hashtags, related }) {
    const link = twitterLink(url, { title, via, hashtags, related });

    return (
        <IconBtnBase
            component="a"
            href={link}
            target="_blank"
            size="small"
            sx={{
                backgroundColor: 'grey.400',
                '&:hover': {
                    backgroundColor: '#58A3E3',
                },
            }}>
            <TwitterIcon fontSize="small" className="text-white" />
        </IconBtnBase>
    );
}
