import React from 'react';
import useAuth from 'Hooks/UseAuth';
import { useTheme } from '@mui/system';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLinks } from './Constants/NavLinks';
import ProfileMenu from './HeaderMenus/ProfileMenu';
import { ConfirmationDialog } from '@aseel/common-ui';
import { NavLink, useHistory } from 'react-router-dom';
import NavLinksForMobile from './HeaderMenus/NavLinksForMobile';
import aseelLogo from '@aseel/common-assets/assets/aseel-logo-dark.png';

export default function Header() {
    const auth = useAuth();
    const history = useHistory();
    const { palette } = useTheme();
    const { i18n, t } = useTranslation();

    const handleLangChange = () => {
        i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
    };

    const handleLogout = () => {
        ConfirmationDialog({
            text: t('common.logoutWarning'),
        })
            .then(() => {
                return auth.logout();
            })
            .then(() => {
                return history.push('/sign-in');
            });
    };

    const menus = [
        {
            label: t('header.aboutUs'),
            link: `${process.env.REACT_APP_HOME_URL}/${i18n.language}/about-us`,
            type: 'url',
        },
        {
            label: t('header.invest'),
            link: `${process.env.REACT_APP_HOME_URL}/${i18n.language}#funds`,
            type: 'url',
        },
        {
            label: i18n.language === 'en' ? 'العربية' : 'English',
            onClick: handleLangChange,
            type: 'button',
        },
        {
            label: t('header.logout'),
            onClick: handleLogout,
            type: 'button',
        },
    ];

    return (
        <div
        className='flex justify-between items-center'
            style={{
                backgroundColor: palette.surface.gray10,
            }}>
            <div className="relative flex items-center justify-between h-20 px-2.5 sm:px-5 lg:px-40 w-full">
                <div className="flex items-center space-s-10">
                    <div className="lg:my-0 ml-0 lg:ml-4">
                        <a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}`}>
                            <img className="block w-auto h-14" src={aseelLogo} alt="Aseel" />
                        </a>
                    </div>
                    <div className="hidden md:flex space-s-14">
                        {NavLinks().map((navItem) => (
                            <div key={navItem.path}>
                                <Typography
                                    component={NavLink}
                                    to={navItem.path}
                                    sx={{
                                        height: {
                                            md: 80,
                                        },
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '600',
                                        color: '#bdbdbd',
                                    }}
                                    activeStyle={{
                                        color: palette.primary.main,
                                        borderBottom: `2px solid ${palette.primary.main}`,
                                    }}>
                                    <navItem.icon className="me-2" />
                                    <span>{navItem.text}</span>
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="hidden md:block">
                    <ProfileMenu auth={auth} menus={menus} palette={palette} />
                </div>
            </div>
            <div className="md:hidden sm:ml-2.5">
                <NavLinksForMobile menus={menus} palette={palette} />
            </div>
        </div>
    );
}
