import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';

const ValueContainer = (props) => {
    const { children } = props;
    return (
        <Box
            className="relative flex flex-wrap flex-1 items-center overflow-hidden"
            style={{ minHeight: '28px' }}>
            {children}
        </Box>
    );
};

ValueContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ValueContainer;
