import React, { useState } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { BtnBase, IconBtnBase } from '@aseel/common-ui';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import DepositInfo from 'Pages/Dashboard/Overview/Components/DepositInfo';

export default function MyBalance({ balance, onRefresh, isLoading, unitsQuery, shares }) {
    const [isDepositInfoShown, setIsDepositInfoShown] = useState(false);
    const { t } = useTranslation();

    const remainingAmountToInvest = unitsQuery.data?.remaining_amount_to_invest;

    const handleShowDepositInfo = () => setIsDepositInfoShown(true);
    const handleCloseDepositInfo = () => setIsDepositInfoShown(false);

    return (
        <>
            <DepositInfo onClose={handleCloseDepositInfo} isOpen={isDepositInfoShown} />
            <div className="space-y-1">
                <Typography variant="subtitle1" component="h2">
                    {t('common.myBalance')}
                </Typography>

                <Typography variant="h4" component="p">
                    SR {balance === undefined ? '---' : balance}
                </Typography>

                {unitsQuery && parseFloat(remainingAmountToInvest) > 0 ? (
                    <Typography className="pb-2.5">
                        <Trans t={t} i18nKey="fund.youNeedToDepositToBeAbleToInvestByUnits">
                            <span className="font-semibold">{{ shares }}</span>
                            <span>{{ remainingAmountToInvest }}</span>
                        </Trans>
                    </Typography>
                ) : null}

                <div className="flex items-center mt-2">
                    <div className="me-3">
                        <BtnBase color="green" fullWidth={false} onClick={handleShowDepositInfo}>
                            {t('common.deposit')}
                        </BtnBase>
                    </div>
                    <IconBtnBase isLoading={isLoading} onClick={onRefresh}>
                        <RefreshRoundedIcon />
                    </IconBtnBase>
                </div>
            </div>
        </>
    );
}
