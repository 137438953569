import React from 'react';

export default function SplitScreenWithImage({ children, imgSrc, imageWidth, childrenClassName }) {
    const authorizedEntityImage =
        '';

    const investorImage =
        'https://images.unsplash.com/photo-1559019095-21c6d71ec450?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=zane-lee-cYBmsy3nbk0-unsplash.jpg';

    return (
        <div className="flex min-h-screen bg-white">
            <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="w-full max-w-sm mx-auto lg:w-96">
                    {children}
                </div>
            </div>
            <div className="relative flex-1 hidden w-0 lg:block">
                <img className="absolute inset-0 object-cover w-full h-full" src={imgSrc} alt="" />
            </div>
        </div>
    );
}
