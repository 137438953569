import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';
import Carousel from 'react-material-ui-carousel';

export default function FundImages({ images }) {
    const { palette } = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    return (
        <div>
            {images.length > 0 ? (
                <Carousel
                    autoPlay={false}
                    indicators={false}
                    index={activeStep}
                    next={(next) => {
                        setActiveStep(next);
                    }}
                    prev={(prev) => setActiveStep(prev)}
                    navButtonsAlwaysVisible>
                    {images.map((item, i) => (
                        <Image key={item.id} url={item.url} />
                    ))}
                </Carousel>
            ) : (
                <Box
                    className="bg-center bg-cover"
                    sx={{
                        paddingTop: {
                            xs: '100%',
                            lg: '86.5%',
                        },
                        borderRadius: '6px',
                        backgroundColor: palette.surface.gray30,
                    }}></Box>
            )}
            {images.length > 0 ? (
                <div className="w-full flex flex-nowrap space-s-2.5 mt-1.5 overflow-x-auto rounded-lg">
                    {images.map((image, i) => (
                        <Box
                            className="bg-center bg-cover cursor-pointer"
                            onClick={() => setActiveStep(i)}
                            sx={{
                                padding: {
                                    xs: '10%',
                                    sm: '7%',
                                },
                                border: i === activeStep ? `2.5px solid ${palette.primary.main}` : null,
                                borderRadius: '6px',
                                backgroundImage: `url(${image.url})`,
                            }}></Box>
                    ))}
                </div>
            ) : null}
        </div>
    );
}

function Image({ url }) {
    return (
        <Box
            className="bg-center bg-cover"
            sx={{
                paddingTop: {
                    xs: '100%',
                    lg: '70%',
                },
                borderRadius: '6px',
                backgroundImage: `url(${url})`,
            }}></Box>
    );
}
