import React from 'react';
import { useTheme } from '@mui/system';
import { Typography } from '@mui/material';

export function DisplayerDetails({ Icon, title, location, children }) {
    const { palette } = useTheme();

    return (
        <div>
            <div
                className="p-2.5 rounded-md"
                style={{
                    backgroundColor: palette.surface.gray20,
                }}>
                <div className="flex items-center space-s-3">
                    <Icon
                        className="h-10 py-2 rounded-md"
                        fontSize="large"
                        sx={{ backgroundColor: palette.surface.gray31 }}
                    />
                    <Typography variant="h6" className="text-gray-800 font-semibold">
                        {title}
                    </Typography>
                </div>
                <Typography className="text-gray-500 text-md font-semibold mt-1.5">{location}</Typography>
            </div>
            {children}
        </div>
    );
}
