import * as React from 'react';
import { merge } from 'lodash';
import { Box, useTheme } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useRadioGroup } from '@mui/material';
import { RadioGroup } from '@aseel/common-ui';
import FormControlLabel from '@mui/material/FormControlLabel';

const RadioGroupIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CheckedRadioGroupIcon = styled(RadioGroupIcon)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.secondary.main,
    },
}));

function RoundedFormControlLabel({ sx, ...props }) {
    const { palette } = useTheme();
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return (
        <Box
            sx={merge(sx, {
                border: checked ? `1px solid ${palette.secondary.main}` : `2px solid ${palette.surface.gray30}`,
                backgroundColor: palette.surface.gray20,
            })}
            className={`flex items-center ps-4 pt-7 pb-6 py-5 h-12 mt-2 sm:me-2.5 rounded-md`}>
            <FormControlLabel {...props} />
        </Box>
    );
}

export default function RoundedRadioGroup({ ...props }) {
    return (
        <RadioGroup
            icon={<RadioGroupIcon />}
            checkedIcon={<CheckedRadioGroupIcon />}
            FormControlLabelComponent={RoundedFormControlLabel}
            {...props}
        />
    );
}
