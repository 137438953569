import { FormLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectController, InputController } from '@aseel/common-ui';
import YesNoRadioGroup from 'Pages/Kyc/Components/YesNoRadioGroup';
import RoundedRadioGroupController from 'Components/FormControls/Controller/RoundedRadioGroupController';

function GeneralInformation({ generalInfoForm, educations, levels, occupations }) {
    const { t } = useTranslation();

    const formValues = generalInfoForm.watch();

    return (
        <div className="space-y-5">
            <RoundedRadioGroupController
                row
                label={t('kyc.individualOrFirm')}
                control={generalInfoForm.control}
                name="general_info.is_individual"
                formControlLabelSx={{ width: { xs: '100%', sm: '10rem' } }}
                options={[
                    { value: '1', label: t('common.individual') },
                    { value: '2', label: t('common.company') },
                ]}
            />
            <YesNoRadioGroup
                label={t('kyc.didYouWorkInTheFinSector')}
                row
                control={generalInfoForm.control}
                name="general_info.any_xp_in_fin_sector"
            />

            <YesNoRadioGroup
                label={t('kyc.didYouWorkInRelatedFinSector')}
                row
                control={generalInfoForm.control}
                name="general_info.any_xp_related_to_fin_sector"
            />

            <YesNoRadioGroup
                label={t('kyc.didYouInvestInRealEstates')}
                row
                control={generalInfoForm.control}
                name="general_info.have_you_invested_in_real_estates"
            />

            <YesNoRadioGroup label={t('kyc.onBoard')} row control={generalInfoForm.control} name="is_on_board" />

            <RoundedRadioGroupController
                label={t('kyc.invXp')}
                row
                formControlLabelSx={{
                    width: {
                        xs: '100%',
                        sm: '10rem',
                    },
                }}
                control={generalInfoForm.control}
                name="general_info.investment_xp"
                options={levels.map(({ key, name }) => ({ label: name, value: key.toString() }))}
            />

            <YesNoRadioGroup
                label={t('kyc.isTheClientEntrustedWithProminentPublicFunctions')}
                row
                control={generalInfoForm.control}
                name="general_info.is_entrusted_in_public_functions"
            />
            <YesNoRadioGroup
                label={t('kyc.doesTheClientHaveARelationship')}
                row
                control={generalInfoForm.control}
                name="general_info.have_relationship_with_person_in_public_functions"
            />
            <YesNoRadioGroup
                label={t('kyc.isTheClientTheBeneficialOwnerOfTheAccountOrBusinessRelationship')}
                row
                control={generalInfoForm.control}
                name="general_info.is_beneficial_owner"
            />
            {formValues.general_info.is_beneficial_owner === '0' ? (
                <div>
                    <FormLabel className="block mb-2">
                        {t('kyc.theIdentityOfTheBeneficialOwnerOfTheAccountOrBusinessRelationship')}
                    </FormLabel>
                    <InputController
                        name="general_info.identity_of_beneficial_owner"
                        control={generalInfoForm.control}
                        placeholder={t('kyc.identityOfBeneficialOwner')}
                    />
                </div>
            ) : null}

            <SelectController
                label={t('kyc.education')}
                control={generalInfoForm.control}
                name="education_level"
                options={educations}
                getOptionValue={({ key }) => key}
                getOptionLabel={({ name }) => name}
            />

            <SelectController
                label={t('kyc.occupation')}
                control={generalInfoForm.control}
                name="occupation"
                options={occupations}
                getOptionValue={({ key }) => key}
                getOptionLabel={({ name }) => name}
            />
        </div>
    );
}

export default GeneralInformation;
