import React from 'react';
import { Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TransferToBankAccount({ selectedFeeOption, serverErrorMsg, selectedBankAccount }) {
    const { t } = useTranslation();

    return (
        <div>
            <div className="space-y-5">
                <Typography variant="h5">{t('wallet.transferConfirmation')}</Typography>
                {serverErrorMsg ? <Alert severity="error">{serverErrorMsg}</Alert> : null}
                <div className="ms-2 space-y-2.5">
                    <div>
                        <Typography variant="body2" color="textSecondary">
                            {t('wallet.bankAccount')}
                        </Typography>
                        <Typography className="font-medium">{selectedBankAccount.name}</Typography>
                    </div>
                    <div>
                        <Typography variant="body2" color="textSecondary">
                            {t('wallet.amount')}
                        </Typography>
                        <Typography className="font-medium">
                            {t('common.amount_in_sar', { amount: selectedFeeOption.breakdown.amount })}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body2" color="textSecondary">
                            {t('wallet.transferFee')}
                        </Typography>
                        <Typography className="font-medium">
                            {`${t('common.amount_in_sar', { amount: selectedFeeOption.value })} (${
                                selectedFeeOption.delivery_date
                            })`}
                        </Typography>
                    </div>

                    <div>
                        <Typography variant="body2" color="textSecondary">
                            {t('wallet.transferFeeVat', { percentage: selectedFeeOption.breakdown.vat_percentage })}
                        </Typography>
                        <Typography className="font-medium">
                            {t('common.amount_in_sar', { amount: selectedFeeOption.breakdown.fee_vat })}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body2" color="textSecondary">
                            {t('wallet.totalTransferAmount')}
                        </Typography>
                        <Typography className="font-medium">
                            {t('common.amount_in_sar', { amount: selectedFeeOption.breakdown.total })}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
