import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { handleError } from '@aseel/http';
import { useHistory } from 'react-router-dom';
import { setProgress } from '@aseel/common-ui';
import { getRecaptchaToken } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SIGN_IN_BTN = 'SIGN_IN_BTN';

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    remember_me: Yup.bool().required(),
});

export default function SignInContainer({ children, signInRequest, redirectPath = '/app/dashboard' }) {
    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            remember_me: false,
        },
    });

    const history = useHistory();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleLogin = async (values) => {
        setProgress(SIGN_IN_BTN, true);

        try {
            const token = await getRecaptchaToken(executeRecaptcha);

            const formData = { ...values, g_token: token };

            await signInRequest(formData);

            history.push(redirectPath);
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(SIGN_IN_BTN, false);
    };

    return children({ handleSignIn: handleSubmit(handleLogin), control, signInBtnName: SIGN_IN_BTN });
}
