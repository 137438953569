import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { green, red } from '@mui/material/colors';
import WithdrawDialog from './WithdrawDialog';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import RoundedCard from 'Components/Cards/RoundedCard';
import { DASHBOARD_WALLET } from 'Constants/QueriesKeys';
import MyWalletSkeleton from '../Skeletons/MyWalletSkeleton';
import { BtnBase, IconBtn, setProgress } from '@aseel/common-ui';
import DepositInfo from 'Pages/Dashboard/Overview/Components/DepositInfo';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

const BALANCE_REFRESH_BTN = 'BALANCE_REFRESH_BTN';

const getBalanceStyle = (balance) => ({
    color: balance === 0 ? 'black' : balance > 0 ? green[500] : red[700],
    fontWeight: 'bold',
});

export default function MyWallet() {
    const { t } = useTranslation();
    const [depositInfo, setDepositeInfo] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const myWallet = useQuery(DASHBOARD_WALLET, () => http.get('/balance-inquiry').then((response) => response.data));

    if (myWallet.isLoading) {
        return <MyWalletSkeleton />;
    }

    const handleShowDepositInfo = () => {
        setDepositeInfo(true);
    };

    const handleCloseDepositInfo = () => {
        setDepositeInfo(false);
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleRefetchBalance = () => {
        setProgress(BALANCE_REFRESH_BTN, true);
        myWallet.refetch().finally(() => {
            setProgress(BALANCE_REFRESH_BTN, false);
        });
    };

    return (
        <div>
            <Typography variant="h6" className="pb-10 font-semibold text-gray-800">
                {t('wallet.myWallet')}
            </Typography>
            <div>
                <RoundedCard className="flex flex-col p-5 sm:flex-row md:items-center">
                    <div className="md:flex sm:w-1/2 md:w-2/3">
                        <div className="md:w-1/2">
                            <div className="flex items-center">
                                <Typography variant="h6" className="text-gray-600">
                                    {t('wallet.availableBalance')}
                                </Typography>
                                <IconBtn onClick={handleRefetchBalance} className="sm:mt-1" name={BALANCE_REFRESH_BTN}>
                                    <RefreshIcon />
                                </IconBtn>
                            </div>
                            <Typography variant="h6" sx={getBalanceStyle(myWallet.data.balance)}>
                                {myWallet.data.balance} {t('common.sr')}
                            </Typography>
                        </div>
                        <div className="md:w-1/2 sm:mt-2.5 md:mt-0">
                            <div className="flex items-center">
                                <Typography variant="h6" className="text-gray-600">
                                    {t('wallet.totalAmountWithdrawn')}
                                </Typography>
                                <IconBtn
                                    onClick={handleRefetchBalance}
                                    className="invisible sm:mt-1"
                                    name={BALANCE_REFRESH_BTN}>
                                    <RefreshIcon />
                                </IconBtn>
                            </div>

                            <Typography variant="h6" className="font-medium">
                                0 {t('common.sr')}
                            </Typography>
                        </div>
                    </div>
                    <div className="mt-3 space-y-3 sm:mt-0 sm:flex md:justify-between sm:items-center sm:space-s-3 sm:space-y-0">
                        <div>
                            <BtnBase
                                onClick={handleOpenDialog}
                                startIcon={<ArrowDownwardRoundedIcon fontSize="small" />}>
                                {t('wallet.withdraw')}
                            </BtnBase>
                        </div>
                        <div>
                            <BtnBase
                                className="lg:mb-0"
                                onClick={handleShowDepositInfo}
                                startIcon={<ArrowUpwardRoundedIcon fontSize="small" />}>
                                {t('common.deposit')}
                            </BtnBase>
                        </div>
                    </div>
                </RoundedCard>
            </div>
            <DepositInfo isOpen={depositInfo} onClose={handleCloseDepositInfo} />
            <WithdrawDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
        </div>
    );
}
