import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RadioGroupController } from '@aseel/common-ui';

export default function SelectFeeOption({ form, feeOptions, selectedFeeOption, selectedBankAccount }) {
    const { t } = useTranslation();

    return (
        <div className="space-y-5">
            <Typography variant="h5">{t('wallet.transferFee')}</Typography>
            <div className="ms-2 space-y-2.5">
                <div>
                    <Typography variant="body2" color="textSecondary">
                        {t('wallet.bankAccount')}
                    </Typography>
                    <Typography className="font-medium">{selectedBankAccount?.name}</Typography>
                </div>
                <div>
                    <Typography variant="body2" color="textSecondary">
                        {t('wallet.amount')}
                    </Typography>
                    <Typography className="font-medium">
                        {t('common.amount_in_sar', { amount: feeOptions[0].breakdown.amount })}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2" color="textSecondary">
                        {t('wallet.transferFee')}
                    </Typography>

                    <div>
                        <RadioGroupController
                            options={feeOptions.map((el) => ({
                                label: `${t('common.amount_in_sar', { amount: el.breakdown.fee })}  (${
                                    el.delivery_date
                                })`,
                                value: el.value,
                            }))}
                            control={form.control}
                            name="fee"
                        />
                    </div>
                </div>
                {selectedFeeOption ? (
                    <>
                        <div>
                            <Typography variant="body2" color="textSecondary">
                                {t('wallet.transferFeeVat', { percentage: selectedFeeOption.breakdown.vat_percentage })}
                            </Typography>
                            <Typography className="font-medium">
                                {t('common.amount_in_sar', { amount: selectedFeeOption.breakdown.fee_vat })}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body2" color="textSecondary">
                                {t('wallet.totalTransferAmount')}
                            </Typography>
                            <Typography className="font-medium">
                                {t('common.amount_in_sar', { amount: selectedFeeOption.breakdown.total })}
                            </Typography>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
