import {
    termsAndConditionsSchema,
    identitySchema,
    financialStatusSchema,
    generalInfoSchema,
    verifyCodeSchema,
} from './Schemas';
import { format } from 'date-fns';
import useAuth from 'Hooks/UseAuth';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useForm } from 'react-hook-form';
import VerifyCode from './Steps/VerifyCode';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getRecaptchaToken } from '@aseel/common-utils';
import FinancialStatus from './Steps/FinancialStatus';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, Redirect } from 'react-router-dom';
import { ABSHER_INFORMATION } from 'Constants/QueriesKeys';
import GeneralInformation from './Steps/GeneralInformation';
import TermsAndConditions from './Steps/TermsAndConditions';
import { setProgress, Btn, BtnBase } from '@aseel/common-ui';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { handleError, mapValidationErrors } from '@aseel/http';
import IdentityVerification from './Steps/IdentityVerification';
import { CardContent, Container, Divider } from '@mui/material';
import { format as formatJalaliDate, newDate } from 'date-fns-jalali';
import KycLinearProgress from 'Pages/Kyc/Components/KycLinearProgress';

export const EntityType = {
    individual: '1',
    firm: '2',
};

const btnNames = {
    next: 'NEXT_BTN',
    submit: 'SUBMIT_BTN',
    verify: 'VERIFY_BUTTON',
};

export default function Kyc() {
    const auth = useAuth();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [vidAndNin, setVidAndNin] = useState({
        vid: null,
        nin: null,
    });
    const [isCodeVerificationDone, setIsCodeVerificationDone] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);

    const generalInfoForm = useForm({
        resolver: yupResolver(generalInfoSchema),
        defaultValues: {
            general_info: {
                is_entrusted_in_public_functions: '',
                have_relationship_with_person_in_public_functions: '',
                is_beneficial_owner: '',
                is_individual: '',
                any_xp_in_fin_sector: '',
                any_xp_related_to_fin_sector: '',
                have_you_invested_in_real_estates: '',
                investment_xp: '',
            },
            is_on_board: '',
        },
    });

    const financialStatusForm = useForm({
        resolver: yupResolver(financialStatusSchema),
        defaultValues: {
            more_financial_info: {
                years_of_inv_in_securities: null,
            },
        },
    });

    const identityForm = useForm({
        resolver: yupResolver(identitySchema),
        defaultValues: {
            birth_date_type: 'hijri',
        },
    });

    const verifyCodeForm = useForm({
        resolver: yupResolver(verifyCodeSchema),
    });

    const termsAndConditionsForm = useForm({
        resolver: yupResolver(termsAndConditionsSchema),
        defaultValues: {
            accept: false,
        },
    });

    const absherInformationQuery = useQuery(
        [ABSHER_INFORMATION, { locale: i18n.language }],
        () => http.get('/absher').then((response) => response.data.data),
        {
            onSuccess: (data) => {
                if (data.is_identity_verified) {
                    let birthDate;
                    if (data.identity.birth_date_type === 'hijri') {
                        const dateParts = data.identity.birth_date.split('-').map(Number);
                        birthDate = newDate(dateParts[0], dateParts[1], dateParts[2]);
                    } else {
                        birthDate = data.identity.birth_date;
                    }
                    identityForm.reset({
                        ...data,
                        nin: data.identity.nin,
                        birth_date: birthDate,
                        birth_date_type: data.identity.birth_date_type,
                    });
                    setIsCodeVerificationDone(true);
                }
            },
        },
    );

    const [activeStep, setActiveStep] = useState(0);

    const [kycOptions, setKycOptions] = useState({
        EDUCATION: [],
        OCCUPATION: [],
        CURRENT_INVESTMENT: [],
        MONEY_RANGE: [],
        LEVEL: [],
        INVESTMENT_OBJECTIVE: [],
        CASH_OUT_PERIOD: [],
        NET_WORTH: [],
        CITIES: [],
    });

    const [termsAndConditions, setTermsAndConditions] = useState(null);

    const dirtyIdentityFormFields = identityForm.formState.dirtyFields;

    const verifyCodeFormErrors = verifyCodeForm.formState.errors;

    const handleVerifyCode = async () => {
        let data = { ...verifyCodeForm.getValues(), ...vidAndNin };

        try {
            setProgress(btnNames.verify, true);

            const token = await getRecaptchaToken(executeRecaptcha);

            await http.post('/absher/verify', { ...data, g_token: token });
            await absherInformationQuery.refetch();
        } catch (error) {
            handleError(error, { setError: verifyCodeForm.setError });

            const validationErrors = error?.response?.status === 422 ? error.response.data.errors : null;
            if (validationErrors && validationErrors.vid) {
                setActiveStep(2);
            }
        }
        setProgress(btnNames.verify, false);
    };

    const typeOfBirthDate = identityForm.watch('birth_date_type');

    const steps = [
        {
            label: t('registration.steps.generalInfo'),
            Component: GeneralInformation,
            props: {
                generalInfoForm: generalInfoForm,
                educations: kycOptions.EDUCATION,
                occupations: kycOptions.OCCUPATION,
                levels: kycOptions.LEVEL,
            },
            canGoNext: generalInfoForm.trigger,
            progressValue: 20,
        },
        {
            label: t('registration.steps.finStatus'),
            Component: FinancialStatus,
            props: {
                control: financialStatusForm.control,
                moneyRanges: kycOptions.MONEY_RANGE,
                netWorth: kycOptions.NET_WORTH,
                currentInvestments: kycOptions.CURRENT_INVESTMENT,
                invObjectives: kycOptions.INVESTMENT_OBJECTIVE,
            },
            canGoNext: financialStatusForm.trigger,
            progressValue: 40,
        },
        {
            label: t('registration.steps.identityVerification'),
            Component: IdentityVerification,
            props: {
                control: identityForm.control,
                typeOfBirthDate: typeOfBirthDate,
                serverErrors: verifyCodeFormErrors,
            },
            canGoNext: identityForm.trigger,
            progressValue: 60,
        },
        {
            label: t('registration.steps.verifyCode'),
            Component: VerifyCode,
            props: {
                form: verifyCodeForm,
                setActiveStep: setActiveStep,
                verifyButtonName: btnNames.verify,
                handleVerifyCode: handleVerifyCode,
                absherInformationQuery: absherInformationQuery,
            },
            canGoNext: async () => {
                if (isCodeVerificationDone) {
                    return true;
                }

                return verifyCodeForm.trigger();
            },
            progressValue: 80,
        },
        {
            label: t('registration.steps.termsAndConditions'),
            Component: TermsAndConditions,
            props: {
                control: termsAndConditionsForm.control,
                termsAndConditions,
                absherInformationQuery: absherInformationQuery,
            },
            canGoNext: termsAndConditionsForm.trigger,
            progressValue: 100,
        },
    ];

    useEffect(() => {
        http.get('/register/options').then((response) => {
            setKycOptions(response.data.data);
        });

        http.get('/register/content/terms-and-conditions').then((response) => {
            setTermsAndConditions(response.data.data);
        });
    }, [i18n.language]);

    useEffect(() => {
        identityForm.setValue('birth_date', null);
    }, [typeOfBirthDate]);

    const handleNextStep = async () => {
        if (activeStep > steps.length) {
            return;
        }

        const canGoNext = await steps[activeStep].canGoNext();

        if (canGoNext === false) {
            return;
        }

        if (activeStep === steps.length - 1) {
            return handleSubmit();
        } else if (activeStep === 2 && Object.keys(dirtyIdentityFormFields).length > 0) {
            return handleSendSMSVerification();
        }
        setActiveStep((step) => step + 1);
    };

    const handleBackStep = () => {
        if (activeStep === 0) {
            return;
        }

        setActiveStep((step) => step - 1);
        setServerErrors([]);
    };

    const handleSubmit = async () => {
        let data = {
            ...generalInfoForm.getValues(),
            ...financialStatusForm.getValues(),
            ...termsAndConditionsForm.getValues(),
        };

        try {
            setProgress(btnNames.submit, true);

            const token = await getRecaptchaToken(executeRecaptcha);

            await http.put('/profile/investor', { ...data, g_token: token });
            await auth.fetchUserProfile();
            history.push('/app/overview');
        } catch (error) {
            const response = error?.response;

            if (response && response.status === 422) {
                setServerErrors(mapValidationErrors(response.data.errors));
            }
        }
        setProgress(btnNames.submit, false);
    };

    const handleSendSMSVerification = async () => {
        let data = { ...identityForm.getValues() };

        if (typeof data.birth_date !== 'string') {
            if (typeOfBirthDate === 'hijri') {
                data.birth_date = formatJalaliDate(data.birth_date, 'yyyy-MM-dd');
            } else {
                data.birth_date = format(data.birth_date, 'yyyy-MM-dd');
            }
        }

        try {
            setIsCodeVerificationDone(false);
            setProgress(btnNames.submit, true);

            const token = await getRecaptchaToken(executeRecaptcha);

            const response = await http.post('/absher/send-sms-verification', { ...data, g_token: token });
            await absherInformationQuery.refetch();
            setVidAndNin({
                vid: response.data.data.vid,
                nin: response.data.data.nin,
            });
            identityForm.reset(undefined, { keepValues: true, keepDirty: false });
            verifyCodeForm.reset();
            setActiveStep(3);
        } catch (error) {
            handleError(error, { setError: identityForm.setError });
        }
        setProgress(btnNames.submit, false);
    };

    if (auth.user.is_kyc_completed) {
        return <Redirect to="/app/overview" />;
    }

    const areTermsAndConditionsAccepted = termsAndConditionsForm.watch('accept');

    return (
        <Container maxWidth="md" className="z-10 my-10 bg-white">
            {serverErrors.length ? (
                <Alert severity="error" className="mb-4">
                    <AlertTitle>{t('common.someDataAreInvalid')}</AlertTitle>
                    <ul>
                        {serverErrors.map((error) => (
                            <li>{error.message}</li>
                        ))}
                    </ul>
                </Alert>
            ) : null}

            <div className="overflow-visible ">
                <CardContent>
                    <KycLinearProgress steps={steps} activeStep={activeStep} />

                    <div className="mt-10 mb-8">
                        {steps.map(({ Component, props }, key) =>
                            key === activeStep ? <Component key={key} {...props} /> : null,
                        )}
                    </div>

                    <Divider className="mb-6" />

                    <div className="flex justify-between space-s-2">
                        {activeStep > 0 ? (
                            <BtnBase onClick={handleBackStep} variant="text" fullWidth={false}>
                                {t('common.back')}
                            </BtnBase>
                        ) : (
                            <div></div>
                        )}

                        <Btn
                            onClick={handleNextStep}
                            fullWidth={false}
                            color="secondary"
                            name={btnNames.submit}
                            disabled={
                                (activeStep === 3 && isCodeVerificationDone === false) ||
                                (activeStep === 4 && !areTermsAndConditionsAccepted)
                            }>
                            {activeStep === steps.length - 1 ? t('common.submit') : t('common.next')}
                        </Btn>
                    </div>
                </CardContent>
            </div>
        </Container>
    );
}
