import React from 'react';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function InvestingRecordsForXsAndSm({
    investingRecordsColumns,
    investingRecordsData,
    handleOpenRowMenu,
    open,
}) {
    const { t } = useTranslation();

    return (
        <div className="md:hidden">
            <div className="hidden sm:flex">
                <div className="w-1/3"></div>
                <div className="flex w-3/4">
                    <Typography fontWeight={800} className="w-1/4" variant="caption" color="grey.500">
                        {t('wallet.amountWithoutFees')}
                    </Typography>
                    <Typography fontWeight={800} className="w-1/3" variant="caption" color="grey.500">
                        {t('wallet.vatAmount')}
                    </Typography>
                    <Typography fontWeight={800} variant="caption" color="grey.500">
                        {t('wallet.adminFees')}
                    </Typography>
                </div>
                <div className="w-1/6"></div>
            </div>
            <div className="space-y-5">
                {investingRecordsData.map((item, i) => (
                    <div className="flex items-center sm:space-y-3.5">
                        <div className="flex flex-col sm:hidden w-1/3">
                            {investingRecordsColumns.map((item) => (
                                <Typography variant="caption" color="textSecondary">
                                    {item.headerName}
                                </Typography>
                            ))}
                        </div>
                        <div className="flex flex-col w-3/4 sm:flex-row md:hidden sm:items-center sm:w-full">
                            <div className="sm:w-1/3">
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: {
                                            xs: grey[800],
                                            sm: 'textSecondary',
                                        },
                                    }}
                                    className="md:w-1/5">
                                    {item.created_at}
                                </Typography>
                                <Typography variant="body2" color="grey.800" className="md:w-2/5">
                                    {item.listing}
                                </Typography>
                            </div>
                            <div className="sm:flex sm:w-2/3">
                                <Typography variant="body2" className="w-1/3  md:hidden text-gray-800">
                                    {item.amount_without_fees}
                                </Typography>
                                <Typography variant="body2" className="w-1/3 md:hidden text-gray-800">
                                    {item.vat_amount}
                                </Typography>
                                <Typography variant="body2" className="w-1/3 md:hidden text-gray-800">
                                    {item.admin_fees}
                                </Typography>
                            </div>
                        </div>
                        <div className="flex justify-center w-1/4 md:hidden">
                            {item.subscription_form === null && item.zatca_invoice === null ? null : (
                                <IconButton
                                    size="small"
                                    className="font-semibold text-gray-800"
                                    sx={{ backgroundColor: ({ palette }) => palette.surface.gray30 }}
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={(event) => handleOpenRowMenu(event, item)}>
                                    <KeyboardArrowDownIcon fontSize="small" />
                                </IconButton>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
