import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import { DialogActions } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';

function ReportInfo({ isOpen, onClose, reportInfo }) {
    const { t } = useTranslation();
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogContent>
                <div className="space-y-4">
                    <Typography variant="h5" className="font-bold">
                        {reportInfo?.title}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: `${reportInfo?.description}`,
                        }}
                    />
                </div>

                <div className="mt-7">
                    <Typography variant="subtitle1">{t('report.attachments')}</Typography>
                    <div className="divide-y divide-gray-300">
                        {reportInfo?.files.map((item) => {
                            return (
                                <div className="flex items-center justify-between py-2" key={item.url}>
                                    <div className="flex-grow">
                                        <Typography variant="body1">{item.name}</Typography>
                                    </div>
                                    <div>
                                        <a href={item.url} target="_blank">
                                            <IconButton size="large">
                                                <GetAppIcon className="text-gray-500" />
                                            </IconButton>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <DialogActions>
                    <Button variant="text" onClick={onClose} color="primary">
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default ReportInfo;
