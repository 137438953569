import React from 'react';
import SendResetLink from '@aseel/common-components/src/Auth/SendResetLink';
import SplitScreenWithImage from '@aseel/common-components/src/Layouts/SplitScreenWithImage';
import { http } from 'Utils/Http/Http';

const sendResetLink = (values) =>
    http.post('/api/forgot-password', values, {
        baseURL: process.env.REACT_APP_BASE_URL,
    });

export default function SendResetLinkPage() {
    return (
        <SplitScreenWithImage imgSrc="https://images.unsplash.com/photo-1559019095-21c6d71ec450?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=zane-lee-cYBmsy3nbk0-unsplash.jpg">
            <SendResetLink
                imageClassName="w-auto h-16 mb-5"
                titleClassName="mb-5"
                buttonClassName="mt-6"
                hasLogoAndTitle
                fullWidth
                sendResetLink={sendResetLink}
            />
        </SplitScreenWithImage>
    );
}
