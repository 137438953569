import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.surface.gray26}`,

    borderRadius: '5px',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <Box
                className="p-1 bg-white"
                sx={{
                    boxShadow: '0px 0px 20px 2px rgba(189,189,189,0.3)',
                }}>
                <KeyboardArrowDownRoundedIcon size="large" className="text-black" />
            </Box>
        }
        {...props}
    />
))(({ theme, expanded }) => ({
    backgroundColor: theme.palette.surface.gray20,
    display: 'flex',
    paddingRight: 35,
    borderRadius: 1.5,
    borderTop: expanded ? `2px solid ${theme.palette.surface.gray30}` : null,
    borderRight: expanded ? `2px solid ${theme.palette.surface.gray30}` : null,
    borderLeft: expanded ? `2px solid ${theme.palette.surface.gray30}` : null,
    alignItems: 'center',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        height: 40,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.surface.gray30}`,
    borderRight: `2px solid ${theme.palette.surface.gray30}`,
    borderLeft: `2px solid ${theme.palette.surface.gray30}`,
    borderRadius: 1.5,
}));

export default function FundSectionAccordion({ title, Icon, children }) {
    const { palette } = useTheme();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion onChange={handleChange('panel1')}>
                <AccordionSummary expanded={expanded} aria-controls="panel1d-content" id="panel1d-header">
                    <Icon
                        className="h-10 py-2 rounded-md me-2.5"
                        fontSize="large"
                        sx={{ backgroundColor: palette.surface.gray31 }}
                    />
                    <Typography variant="h6" className="font-semibold text-gray-800">
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className='px-4 pb-2.5'>{children}</AccordionDetails>
            </Accordion>
        </div>
    );
}
