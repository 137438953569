import IconBtnBase from '../IconBtnBase';
import React from 'react';
import objectToGetParams from './Utils/ObjectToGetParams';
import WhatsappIcon from '../../../Icons/WhatsappIcon';

function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

export default function WhatsappShareBtn({ title, separator = ' ', url }) {
    const link =
        'https://' +
        (isMobileOrTablet() ? 'api' : 'web') +
        '.whatsapp.com/send' +
        objectToGetParams({
            text: title ? title + separator + url : url,
        });

    return (
        <IconBtnBase
            component="a"
            href={link}
            target="_blank"
            size="small"
            sx={{
                backgroundColor: 'grey.400',
                '&:hover': {
                    backgroundColor: '#25D366',
                },
            }}>
            <WhatsappIcon fontSize="small" className="text-white" />
        </IconBtnBase>
    );
}
