import { Yup } from '@aseel/common-utils';

export const unitsSchema = (min, max) => {
    let sharesSchema = Yup.number().integer();
    if (max !== undefined && min !== undefined) {
        sharesSchema = sharesSchema.max(max).min(min);
    }

    return Yup.object().shape({
        shares: sharesSchema.required().nullable(),
    });
};

export const acceptTearmsAndConditoinsSchema = Yup.object().shape({
    accept: Yup.boolean().oneOf([true], 'acceptTermsAndConditions'),
});

export const codeSchema = Yup.object().shape({
    code: Yup.string().required(),
});
