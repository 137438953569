import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NoSsr from '@mui/material/NoSsr';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';
import ReactSelect from 'react-select';
import AsyncReactSelect from 'react-select/async';
import CreatableReactSelect from 'react-select/creatable';
import AsyncCreatableReactSelect from 'react-select/async-creatable';

import Control from './Control';
import Menu from './Menu';
import MultiValue from './MultiValue';
import NoOptionsMessage from './NoOptionsMessage';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import DropdownIndicator from './DropdownIndicator';
import ClearIndicator from './ClearIndicator';
import IndicatorSeparator from './IndicatorSeparator';
import { useTranslation } from 'react-i18next';

const Select = ({
    id,
    label,
    placeholder,
    margin,
    helperText,
    error,
    isAsync,
    isCreatable,
    components,
    optional = false,
    ...otherProps
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);

    const focusHandler = () => {
        setIsFocused(true);

        if (otherProps.onFocus) {
            otherProps.onFocus();
        }
    };

    const blurHandler = () => {
        setIsFocused(false);

        if (otherProps.onBlur) {
            otherProps.onBlur();
        }
    };

    const selectStyles = {
        singleValue: (base) => {
            return {
                ...base,
                marginRight: 0,
                marginLeft: 0,
            };
        },
        input: (base) => {
            return {
                ...base,
                margin: 0,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            };
        },
        menuPortal: (base) => {
            return {
                ...base,
                zIndex: 5000,
            };
        },
    };

    // prettier-ignore
    const SelectControl = isAsync
        ? isCreatable
            ? AsyncCreatableReactSelect
            : AsyncReactSelect
        : isCreatable
            ? CreatableReactSelect
            : ReactSelect;

    const labelWithOptional = optional ? `${label} (${t('common.optional')})` : label;

    return (
        <NoSsr
            fallback={
                <TextField
                    id={id}
                    label={labelWithOptional}
                    variant="outlined"
                    margin={margin}
                    fullWidth
                    value="..."
                    error={error}
                    helperText={helperText}
                    disabled
                />
            }>
            <SelectControl
                styles={selectStyles}
                inputId={id}
                TextFieldProps={{
                    label: labelWithOptional,
                    fullWidth: true,
                    variant: 'outlined',
                    onBlur: blurHandler,
                    margin,
                    error,
                    helperText,
                    placeholder,
                    InputLabelProps: {
                        htmlFor: id,
                        shrink: otherProps.value.length > 0 || isFocused,
                    },
                }}
                components={{
                    Control,
                    Menu,
                    MultiValue,
                    NoOptionsMessage,
                    Option,
                    Placeholder,
                    SingleValue,
                    ValueContainer,
                    DropdownIndicator,
                    ClearIndicator,
                    IndicatorSeparator,
                    ...components,
                }}
                placeholder={placeholder}
                onFocus={focusHandler}
                {...otherProps}
            />
        </NoSsr>
    );
};

Select.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    margin: PropTypes.oneOf(['none', 'normal', 'dense']),
    error: PropTypes.bool,
    isAsync: PropTypes.bool,
    isCreatable: PropTypes.bool,
    helperText: PropTypes.string,
    components: PropTypes.shape(),
};

Select.defaultProps = {
    placeholder: '',
    margin: undefined,
    error: false,
    isAsync: false,
    isCreatable: false,
    helperText: null,
    components: null,
};

export default Select;
