import React from 'react';
import PropTypes from 'prop-types';
import RadioGroupMui from '../RadioGroup';
import { Controller } from 'react-hook-form';
import { translateRHFErrorMsg } from '@aseel/common-utils';

export default function RadioGroupController({
    ref,
    name,
    rules,
    control,
    errorPath,
    defaultValue = '',
    RadioGroupComponent,
    ...otherProps
}) {
    let RadioGroup = RadioGroupMui;

    if (RadioGroupComponent !== undefined) {
        RadioGroup = RadioGroupComponent;
    }
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, ref, ...otherRenderProps }, fieldState: { error } }) => {
                return (
                    <RadioGroup
                        helperText={translateRHFErrorMsg(error)}
                        error={Boolean(error)}
                        value={value}
                        {...otherProps}
                        {...otherRenderProps}
                    />
                );
            }}
            defaultValue={defaultValue}
        />
    );
}

RadioGroupController.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    ref: PropTypes.object,
    errorPath: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
