import React from 'react';
import TextField from '@mui/material/TextField';
import { DateRangePicker as DatePicker } from '@mui/lab';
import Box from '@mui/material/Box';

export default function DateRangePicker({
    startClassName,
    startHelperText,
    startError,
    endHelperText,
    endError,
    endClassName,
    value,
    onChange,
    startText,
    endText,
    ...otherProps
}) {
    return (
        <DatePicker
            startText={startText}
            endText={endText}
            value={value}
            onChange={onChange}
            renderInput={(startProps, endProps) => (
                <>
                    <TextField
                        className={startClassName}
                        helperText={startHelperText}
                        {...startProps}
                        error={startError}
                    />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField className={endClassName} helperText={endHelperText} {...endProps} error={endError} />
                </>
            )}
            inputFormat="YYYY-MM-DD"
            mask="____-__-__"
            {...otherProps}
        />
    );
}
