import React from 'react';
import { Menu as MenuComponent } from '@mui/material';

function Menu({ children, isMenuOpen, setIsMenuOpen }) {
    return (
        <div>
            <MenuComponent
                className="absolute"
                anchorEl={isMenuOpen}
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 15,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                {children}
            </MenuComponent>
        </div>
    );
}

export default Menu;
