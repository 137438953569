import './index.css';
import { Route, Switch } from 'react-router-dom';
import { setLocale } from 'yup';
import SignInPage from 'Pages/Auth/SignInPage';
import { useEffect, useMemo } from 'react';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import { AuthContext } from './Contexts/AuthContext';
import BasicLayoutEntry from 'BasicLayoutEntry';
import AppEntry from 'AppEntry';
import { GuardedRoute } from '@aseel/common-utils';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'Hooks/UseQueryParams';
import ModalContainer from 'react-modal-promise';
import './Assets/Fonts/Alata-Regular.woff';
import './Assets/Fonts/Alata-Regular.woff2';
import './Assets/Fonts/HelveticaNeueLTArabic-Bold.woff';
import './Assets/Fonts/HelveticaNeueLTArabic-Bold.woff2';
import './Assets/Fonts/HelveticaNeueLTArabic-Roman.woff';
import './Assets/Fonts/HelveticaNeueLTArabic-Roman.woff2';
import StatusGuard from 'Utils/Guards/StatusGuard';
import { updateHttpInstanceLanguageHeader } from '@aseel/http';
import KycGuard from 'Utils/Guards/KycGuard';
import SendResetLinkPage from 'Pages/Auth/SendResetLinkPage';
import ResetPasswordPage from 'Pages/Auth/ResetPasswordPage';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ToastContainer } from 'react-toastify';

// Create rtl cache

setLocale({
    mixed: {
        required: 'required',
    },
    string: {
        required: 'required',
    },
});

function App() {
    const { i18n } = useTranslation();
    const query = useQueryParams();

    const cacheRtl = useMemo(
        () =>
            createCache({
                key: 'muirtl',
                stylisPlugins: i18n.language === 'en' ? [] : [rtlPlugin],
            }),
        [i18n.language],
    );

    const [isFetchingUserData, setIsFetchingUserData] = useState(true);
    const [auth, setAuth] = useState({
        user: null,
        fetchUserProfile: () => {
            return http
                .get('/auth')
                .then((response) => {
                    setAuth({ ...auth, user: response.data.data });
                })
                .catch(() => {
                    setAuth({ ...auth, user: null });
                });
        },
        refreshCsrf: () => {
            return http.get(`/sanctum/csrf-cookie`, {
                baseURL: process.env.REACT_APP_BASE_URL,
            });
        },
        logout: () => {
            return http
                .post('/api/logout', undefined, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                })
                .then(() => {
                    setAuth({ ...auth, user: null });
                    return auth.refreshCsrf();
                });
        },
    });

    useEffect(() => {
        setIsFetchingUserData(true);
        auth.fetchUserProfile().finally(() => setIsFetchingUserData(false));
        auth.refreshCsrf();

        const queryLang = query.get('lang');
        if (['en', 'ar'].includes(queryLang) && queryLang !== i18n.language) {
            i18n.changeLanguage(queryLang);
            updateHttpInstanceLanguageHeader(http, i18n.language);
            window.localStorage.setItem('locale', i18n.language);
        }
    }, []);

    useEffect(() => {
        // prettier-ignore
        document.body.dir = (i18n.language === 'ar' ? 'rtl' : 'ltr');
    }, [i18n.language]);

    const theme = useMemo(() => {
        return responsiveFontSizes(
            createTheme({
                components: {
                    MuiTextField: {
                        defaultProps: {
                            color: 'secondary'
                        },
                    },
                },
                direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                palette: {
                    primary: {
                        main: '#0366ff',
                        dark: '#011291',
                    },
                    secondary: {
                        main: '#22b77c',
                        contrastText: '#fff',
                    },
                    surface: {
                        gray10: '#FCFCFF',
                        gray20: '#FAFAFA',
                        gray30: '#f2f4f7',
                    },
                    tertiary: {
                        main: '#FFE446',
                        contrastText: '#132A46',
                    },
                    green: {
                        main: '#4CAF50',
                        contrastText: '#fff',
                    },
                    gold: {
                        main: '#DCB807',
                        contrastText: '#fff',
                    },
                },
                typography: {
                    // fontFamily: `${i18n.language === 'ar' ? 'HelveticaNeueLT' : 'Alata'}`,
                    fontFamily: 'HelveticaNeueLT',
                },
                spacing: (factor) => `${0.25 * factor}rem`,
            }),
        );
    }, [i18n.language]);

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <AuthContext.Provider value={auth}>
                        <div>
                            {isFetchingUserData ? (
                                ' '
                            ) : (
                                <Switch>
                                    <GuardedRoute guards={[GuestGuard]} exact path="/sign-in" component={SignInPage} />

                                    <GuardedRoute
                                        guards={[AuthGuard, KycGuard, StatusGuard]}
                                        path="/app"
                                        component={AppEntry}
                                    />
                                    <Route exact path="/forgot-password" component={SendResetLinkPage} />
                                    <Route exact path="/reset-password" component={ResetPasswordPage} />
                                    <Route path="/" component={BasicLayoutEntry} />
                                </Switch>
                            )}
                        </div>
                    </AuthContext.Provider>
                </LocalizationProvider>
                <ToastContainer />
                <ModalContainer />
            </ThemeProvider>
        </CacheProvider>
    );
}

export default App;
