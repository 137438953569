import React from 'react';
import { Card, Skeleton, Box, CardContent } from '@mui/material';

export default function ProfessionalUpgradeRequestsSkeleton() {
    return (
        <Box className="p-10" sx={{ minHeight: '90vh' }}>
            <Skeleton className="w-1/4 mb-2.5" />
            <div className="grid sm:grid-cols-3 gap-5">
                {Array(5)
                    .fill(0)
                    .map(() => (
                        <>
                            <Card>
                                <CardContent>
                                    <div className="space-y-2.5">
                                        <Skeleton className="w-1/2" />
                                        <Skeleton className="w-1/2" />
                                    </div>
                                </CardContent>
                            </Card>
                        </>
                    ))}
            </div>
        </Box>
    );
}
