import IconBtnBase from '../IconBtnBase';
import React from 'react';
import objectToGetParams from './Utils/ObjectToGetParams';
import FacebookIcon from '../../../Icons/FacebookIcon';

const config = {
    height: 300,
    width: 400,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 300 / 2,
    top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 400 / 2,
};

function facebookLink(url, { quote, hashtag }) {
    return (
        'https://www.facebook.com/sharer/sharer.php' +
        objectToGetParams({
            u: url,
            quote,
            hashtag,
        })
    );
}

export default function FacebookShareBtn({ url, quote, hashtag }) {
    const handleClick = () => {
        window.open(
            facebookLink(url, { quote, hashtag }),
            '',
            Object.keys(config)
                .map((key) => `${key}=${config[key]}`)
                .join(', '),
        );
    };

    return (
        <IconBtnBase
            target="_top"
            onClick={handleClick}
            size="small"
            sx={{
                backgroundColor: 'grey.400',
                '&:hover': {
                    backgroundColor: '#4267B2',
                },
            }}>
            <FacebookIcon fontSize="small" className="text-white" />
        </IconBtnBase>
    );
}
