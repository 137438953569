import React from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import LocaleField from '../LocaleField';
import SectionTitle from '../SectionTitle';
import { useTranslation } from 'react-i18next';
import VerifyUserIdentityDialog from '../VerifyUserIdentityDialog';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import SubscriptionFormSkeleton from '../Skeletons/SubscriptionFormSkeleton';
import { SUBSCRIPTION_FORMS, TERMS_AND_CONDITIONS } from 'Constants/QueriesKeys';
import { CheckboxController } from '@aseel/common-components/node_modules/@aseel/common-ui';

export default function SubscriptionForm({ fundId, subscriptionToken, acceptTermsAndConditions }) {
    const { i18n, t } = useTranslation();

    const { isLoading, data } = useQuery([SUBSCRIPTION_FORMS, { fundId, token: subscriptionToken.id }], () =>
        http
            .get(`/listings/${fundId}/subscription-data/${subscriptionToken.id}`)
            .then((response) => response.data.data),
    );

    const termsAndConditionsQuery = useQuery([TERMS_AND_CONDITIONS, { locale: i18n.language }], () =>
        http.get(`/listings/${fundId}/terms-conditions`).then((response) => response.data.data),
    );

    if (isLoading || termsAndConditionsQuery.isLoading) {
        return <SubscriptionFormSkeleton />;
    }

    const termsAndConditions = termsAndConditionsQuery.data;

    return (
        <div style={{ direction: 'rtl' }}>
            <LocaleField name={{ en: 'Subscription form number', ar: 'رقم نموذج الاشتراك' }}>{data.id}</LocaleField>
            <LocaleField name={{ en: 'Date', ar: 'التاريخ' }}>{data.date}</LocaleField>

            <div className="flex">
                <FormControlLabel
                    control={<Checkbox checked={data.is_new_investor} />}
                    label="مستثمر جديد (New Investor)"
                />

                <FormControlLabel
                    control={<Checkbox checked={!data.is_new_investor} />}
                    label="مستثمر حالي (Existing Investor)"
                />
            </div>

            <LocaleField name={{ en: 'Investor No.', ar: 'رقم المستثمر' }}>{data.investor_id}</LocaleField>

            <SectionTitle className="mt-2" title={{ en: 'Investment Particulars', ar: 'تفاصيل الاستثمار' }} />

            <LocaleField name={{ en: 'Fund name', ar: 'اسم الصندوق' }}>{data.fund_name}</LocaleField>
            <LocaleField name={{ en: 'Fund code', ar: 'رقم الصندوق' }}>{data.fund_id}</LocaleField>
            <LocaleField name={{ en: 'Currency', ar: 'العملة' }}>الريال السعودي</LocaleField>
            <LocaleField name={{ en: 'Amount (in figure)', ar: 'المبلغ بالأرقام' }}>{data.amount}</LocaleField>
            <LocaleField name={{ en: 'Amount (in words)', ar: 'المبلغ كتابة' }}>
                {data.amount_words} ريال سعودي
            </LocaleField>
            <LocaleField name={{ en: 'Value date', ar: 'تاريخ التنفيذ' }}>{data.value_date}</LocaleField>
            <SectionTitle className="mt-2" title={{ en: "Investor's Particulars", ar: 'تفاصيل المستثمر' }} />
            <LocaleField name={{ en: 'Type of investor', ar: 'نوع المستثمر' }}></LocaleField>
            <div className="sm:flex sm:flex-wrap">
                <FormControlLabel className="flex-1" control={<Checkbox checked />} label="فرد (Individual)" />

                <FormControlLabel
                    className="flex-1"
                    control={<Checkbox checked={false} />}
                    readonly
                    label="شركة (Company)"
                />

                <FormControlLabel className="flex-1" control={<Checkbox checked={false} />} label="أخرى (Other)" />
            </div>

            <LocaleField name={{ en: 'Name', ar: 'الاسم' }}>{data.investor_name}</LocaleField>
            <LocaleField name={{ en: 'ID/Iqama/C.R. No.', ar: 'بطاقة/إقامة/سجل تجاري/ رقم' }}>
                {data.investor_national_id}
            </LocaleField>
            <LocaleField name={{ en: 'Nationality', ar: 'الجنسية' }}>{data.nationality}</LocaleField>
            <LocaleField name={{ en: 'City', ar: 'المدينة' }}>{data.investor_city}</LocaleField>
            <LocaleField name={{ en: 'P.Code', ar: 'الرمز البريدي' }}>{data.investor_po_code}</LocaleField>
            <LocaleField name={{ en: 'Mobile', ar: 'جوال' }}>
                <div dir="ltr">{data.investor_phone}</div>
            </LocaleField>

            <SectionTitle className="mt-2" title={{ en: 'Terms And Conditions', ar: 'الشروط والأحكام' }} />
            <div
                dir={i18n.language === 'en' ? 'ltr' : 'rtl'}
                className={`${i18n.language === 'en' ? 'text-left' : 'text-right'} py-2 max-h-96 overflow-y-auto`}>
                <Typography dangerouslySetInnerHTML={{ __html: termsAndConditions.description }}></Typography>
            </div>
            <div className="mt-4" dir={i18n.language === 'en' ? 'ltr' : 'rtl'}>
                <CheckboxController
                    name="accept"
                    label={t('fund.acceptCheckbox')}
                    controlLabelClassName="items-center"
                    control={acceptTermsAndConditions.control}
                />
            </div>
        </div>
    );
}
