import React from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { Dialog, Divider, Typography } from '@mui/material';
import { ApprovalStatusChip, approvalStatusModalTypes } from '@aseel/common-components';
import ShowProfessionalUpgradeRequestSkeleton from '../Sketelons/ShowProfessionalUpgradeRequestSkeleton';
import { PROFESSIONAL_UPGRADE_REQUESTS } from 'Constants/QueriesKeys';

export default function ShowProfessionalUpgradeRequest({ isOpen, onClose, upgradeRequestInfo }) {
    const { t } = useTranslation();

    const upgradeRequestQuery = useQuery(
        [PROFESSIONAL_UPGRADE_REQUESTS, { id: upgradeRequestInfo.upgradeRequestId }],
        () =>
            http
                .get(`/professional-upgrade/requests/${upgradeRequestInfo.upgradeRequestId}`)
                .then((response) => response.data.data),
        {
            enabled: Boolean(upgradeRequestInfo.upgradeRequestId),
        },
    );

    const upgradeRequest = upgradeRequestQuery.data;

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            {upgradeRequestQuery.isLoading ? (
                <ShowProfessionalUpgradeRequestSkeleton />
            ) : (
                <div>
                    <div className="p-5 flex justify-between">
                        <div>
                            <Typography variant="body2" color="textSecondary">{t('upgradeInvestorLevel.createdAt')}</Typography>
                            <Typography>{upgradeRequest?.created_at}</Typography>
                        </div>
                        <div>
                            <ApprovalStatusChip
                                label={upgradeRequest?.status.description}
                                status={upgradeRequest?.status.value}
                                modalType={approvalStatusModalTypes.investorUpgradeRequest}
                            />
                        </div>
                    </div>
                    <Divider />
                    {upgradeRequest?.reviewer_comment ? (
                        <>
                            <div className="p-5">
                                <Typography variant="body2" color="textSecondary">{t('upgradeInvestorLevel.comments')}</Typography>
                                <Typography>{upgradeRequest?.reviewer_comment}</Typography>
                            </div>
                            <Divider />
                        </>
                    ) : null}
                    {upgradeRequest?.items.map((item, i) => (
                        <>
                            <div className="p-5">
                                <Typography variant="body2" color="textSecondary">
                                    {t('upgradeInvestorLevel.condition', { itemIndex: i + 1 })}
                                </Typography>
                                <Typography>{item.text}</Typography>
                                <div className="mt-3.5">
                                    <Typography variant="body2" color="textSecondary">
                                        {t('upgradeInvestorLevel.attachedDocuments')}
                                    </Typography>
                                    {item.files.map((item) => (
                                        <Typography>{item.name}</Typography>
                                    ))}
                                </div>
                            </div>
                            <Divider />
                        </>
                    ))}
                </div>
            )}
        </Dialog>
    );
}
