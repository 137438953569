import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RoundedRadioGroupController from '../../../Components/FormControls/Controller/RoundedRadioGroupController';

export default function YesRadioButton({ name, control, ...otherProps }) {
    const { t } = useTranslation();

    const yesNoOptions = useMemo(
        () => [
            { value: '1', label: t('common.yes') },
            { value: '0', label: t('common.no') },
        ],
        [t],
    );

    return (
        <RoundedRadioGroupController
            name={name}
            control={control}
            options={yesNoOptions}
            formControlLabelSx={{
                width: {
                    xs: '100%',
                    sm: '10rem',
                },
            }}
            {...otherProps}
        />
    );
}
