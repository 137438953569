import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { i18n } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Divider, Typography, IconButton } from '@mui/material';
import DehazeRoundedIcon from '@mui/icons-material/DehazeRounded';
import aseelLogo from '@aseel/common-assets/assets/aseel-logo-dark.png';

export default function MenuForMobile({ NavLinks, auth, onLogout, onLangChange, getNavProps }) {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}>
                <DehazeRoundedIcon />
            </IconButton>
            {isMenuOpen ? (
                <div
                    className="absolute top-0 inset-x-0 mx-2 sm:mx-2.5 mt-2.5 pb-5 bg-white z-40 rounded-md"
                    style={{
                        boxShadow: '0px 0px 20px 2px rgba(189,189,189,0.3)',
                    }}>
                    <div className="flex items-center justify-between p-4">
                        <a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}`}>
                            <img src={aseelLogo} className="w-auto h-16" alt="Aseel Logo" />
                        </a>
                        <IconButton size="large" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <Divider className="my-2.5" />
                    <div className="space-y-2.5 px-5">
                        {NavLinks().map((navLink, key) => {
                            if (!navLink.isVisible(auth)) {
                                return null;
                            }

                            const props = getNavProps(navLink, auth);
                            return (
                                <div>
                                    <Typography {...props} key={key}>
                                        {navLink.text}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                    <div className="space-y-2.5 mt-2.5 px-5">
                        <Typography onClick={onLangChange}>{i18n.language === 'en' ? 'العربية' : 'English'}</Typography>
                        <div>
                            {auth.user ? null : (
                                <Typography component={NavLink} to="/sign-up">
                                    {t('common.signUp')}
                                </Typography>
                            )}
                        </div>
                        <div>
                            {auth.user ? (
                                <Typography onClick={onLogout}>{t('common.logout')}</Typography>
                            ) : (
                                <Typography component={NavLink} to="/sign-in">
                                    {t('common.signIn')}
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
