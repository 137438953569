import React from 'react';
import { Avatar, Divider, Typography } from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

export default function TeamAccordion({ teams }) {
    return (
        <div className="space-y-4">
            {teams?.map((team) => (
                <div>
                    <Typography
                        variant="h6"
                        dangerouslySetInnerHTML={{
                            __html: team.name,
                        }}
                    />
                    <Divider className="mb-3 mt-2" />

                    <ul className="grid gap-4 lg:grid-cols-4">
                        {team.members?.map((member) => (
                            <li className="flex items-center">
                                <Avatar className="me-2">
                                    <PersonRoundedIcon />
                                </Avatar>
                                <div>
                                    <Typography
                                        variant="body1"
                                        component="li"
                                        dangerouslySetInnerHTML={{
                                            __html: member.name,
                                        }}
                                    />

                                    <Typography
                                        variant="body2"
                                        className="text-xs"
                                        component="li"
                                        color="textSecondary"
                                        dangerouslySetInnerHTML={{
                                            __html: member.job_title,
                                        }}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}
