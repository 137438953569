import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import FileUploader from './FileUploader';
import { isPlainObject } from 'lodash';
import { translateRHFErrorMsg } from '@aseel/common-utils';

export default function UploaderController({
    name,
    control,
    path,
    rules,
    defaultValue = [],
    single = false,
    ...otherProps
}) {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange, ref, ...otherRenderProps }, fieldState: { error } }) => {
                let errors = {};

                if (isPlainObject(error)) {
                    // This case when one file is uploaded
                    if (error.message !== undefined && error.type !== undefined) {
                        errors[0] = translateRHFErrorMsg(error);
                    } else {
                        // This case when there mulitple uploads
                        Object.keys(error).forEach((errKey) => {
                            errors[errKey] = translateRHFErrorMsg(error[errKey]);
                        });
                    }
                }
                return (
                    <FileUploader
                        errors={errors}
                        files={value}
                        onChange={onChange}
                        single={single}
                        error={value?.length === 0 && errors[0]}
                        helperText={value?.length === 0 && errors[0]}
                        {...otherProps}
                        {...otherRenderProps}
                    />
                );
            }}
            defaultValue={defaultValue}
        />
    );
}

UploaderController.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    ref: PropTypes.object,
    errorPath: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
