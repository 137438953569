import { IconButton, useTheme } from '@mui/material';
import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export default function TablePagination({ beforeOnClick, beforeDisabled, nextOnClick, nextDisabled }) {
    const { direction } = useTheme();

    return (
        <div className="flex md:justify-end justify-between">
            <IconButton
                variant="text"
                onClick={beforeOnClick}
                disabled={beforeDisabled}
                size="large">
                <NavigateBeforeIcon className={direction === 'rtl' ? 'transform rotate-180' : null} />
            </IconButton>
            <IconButton
                variant="text"
                onClick={nextOnClick}
                disabled={nextDisabled}
                size="large">
                <NavigateNextIcon className={direction === 'rtl' ? 'transform rotate-180' : null} />
            </IconButton>
        </div>
    );
}
