import useAuth from 'Hooks/UseAuth';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FUNDS } from 'Constants/QueriesKeys';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FundImages from './Components/FundImages';
import Risk from './Components/FundSections/Risk';
import Team from './Components/FundSections/Team';
import CTAStacks from 'Components/Auth/CTAStacks';
import FundSummary from './Components/FundSummary';
import FundSkeleton from './Skeletons/FundSkeleton';
import { ButtonBase, Typography } from '@mui/material';
import Location from './Components/FundSections/Location';
import Timeline from './Components/FundSections/Timeline';
import { Dialog, DialogContent, Link } from '@mui/material';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { DisplayerDetails } from './Components/DisplayerDetails';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import DueDiligence from './Components/FundSections/DueDiligence';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import FundSectionAccordion from './Components/FundSectionAccordion';
import FinancialDetails from './Components/FundSections/FinancialDetails';
import CompleteKYC, { CompleteKYCCTA } from 'Components/Auth/CTA/CompleteKYC';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import SignInOrSignUp, { SignInOrSignUpCTA } from 'Components/Auth/CTA/SignInOrSignUp';
import { Box } from '@mui/system';

export const fundRequest = (fundId) => http.get(`/listings/${fundId}`).then((response) => response.data.data);

export default function FundPage() {
    const { id } = useParams();
    const { user } = useAuth();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    let ctaComponent = null;

    if (user === null) {
        ctaComponent = <SignInOrSignUpCTA />;
    } else if (user.is_kyc_completed === false) {
        ctaComponent = <CompleteKYCCTA />;
    }

    const handleInvest = () => {
        if (ctaComponent === null) {
            return history.push(`/funds/${id}/invest`);
        } else {
            showAuthCTA();
        }
    };

    const showAuthCTA = () => {
        setIsOpen(true);
    };

    const fundQuery = useQuery([FUNDS, { id, locale: i18n.language }], () => fundRequest(id));

    if (fundQuery.isLoading) {
        return <FundSkeleton />;
    }

    const fundData = fundQuery.data;

    const canInvest = fundData.can_invest;

    const fundUrl = `${window.location.origin}/funds/${id}`;

    return (
        <>
            <Dialog maxWidth="xs" fullWidth onClose={() => setIsOpen(false)} open={isOpen}>
                <DialogContent className="pb-4">
                    {ctaComponent}
                    <div>
                        <Link>{t()}</Link>
                    </div>
                </DialogContent>
            </Dialog>

            <Box className="py-8 lg:py-8 px-2.5 sm:px-5 lg:px-48 w-full">
                <div className="relative z-10 mb-8 block lg:flex lg:space-s-2.5">
                    <div className="mb-4 md:mb-0 lg:w-1/2">
                        <FundImages images={fundData.images} />
                    </div>
                    <FundSummary
                        canInvest={canInvest}
                        fundData={fundData}
                        handleInvest={handleInvest}
                        fundUrl={fundUrl}
                    />
                </div>
                <div className="space-y-5 lg:flex lg:space-s-9 lg:space-y-0">
                    <div className="flex flex-col space-y-2.5 lg:w-1/2">
                        <DisplayerDetails Icon={ListAltRoundedIcon} title={t('fund.details')}>
                            <Typography
                                className="p-3 text-gray-900"
                                dangerouslySetInnerHTML={{
                                    __html: fundData.property_details.description,
                                }}
                            />
                            {fundData.property_details?.files?.map((file) => (
                                <div>
                                    <ButtonBase
                                        component="a"
                                        href={file.url}
                                        target="_blank"
                                        className="items-center justify-start px-2 py-3">
                                        <GetAppRoundedIcon className="me-3" />
                                        {file.name}
                                    </ButtonBase>
                                </div>
                            ))}
                        </DisplayerDetails>
                    </div>
                    <div className="lg:w-1/2 space-y-2.5">
                        <FundSectionAccordion Icon={PaidOutlinedIcon} title={t('fund.financialDetails')}>
                            <CTAStacks stacks={[SignInOrSignUp, CompleteKYC]}>
                                <FinancialDetails
                                    value={fundData.financial_details?.content}
                                    files={fundData.financial_details?.files}
                                />
                            </CTAStacks>
                        </FundSectionAccordion>
                        <FundSectionAccordion Icon={FileDownloadOutlinedIcon} title={t('fund.dueDiligence')}>
                            <CTAStacks stacks={[SignInOrSignUp, CompleteKYC]}>
                                <DueDiligence
                                    files={fundData.due_diligence?.files}
                                    value={fundData.due_diligence?.content}
                                />
                            </CTAStacks>
                        </FundSectionAccordion>
                        <FundSectionAccordion Icon={AddLocationAltOutlinedIcon} title={t('fund.location')}>
                            <CTAStacks stacks={[SignInOrSignUp, CompleteKYC]}>
                                <Location
                                    Icon={AddLocationAltOutlinedIcon}
                                    value={fundData.location?.description}
                                    location={fundData.location?.gmap_url}
                                />
                            </CTAStacks>
                        </FundSectionAccordion>
                        <FundSectionAccordion Icon={ErrorOutlineOutlinedIcon} title={t('fund.risks')}>
                            <CTAStacks stacks={[SignInOrSignUp, CompleteKYC]}>
                                <Risk files={fundData.risks?.files} value={fundData.risks?.content} />
                            </CTAStacks>
                        </FundSectionAccordion>
                        <FundSectionAccordion Icon={GroupsOutlinedIcon} title={t('fund.team')}>
                            <CTAStacks stacks={[SignInOrSignUp, CompleteKYC]}>
                                <Team teams={fundData?.team} />
                            </CTAStacks>
                        </FundSectionAccordion>
                        <FundSectionAccordion Icon={UpdateOutlinedIcon} title={t('fund.updates')}>
                            <CTAStacks stacks={[SignInOrSignUp, CompleteKYC]}>
                                <Timeline updates={fundData.timeline} />
                            </CTAStacks>
                        </FundSectionAccordion>
                    </div>
                </div>
            </Box>
        </>
    );
}
