import React from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { Yup } from '@aseel/common-utils';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useRouteMatch } from 'react-router';
import { Card, Divider, Typography, Box } from '@mui/material';
import UpgradeRequestIsInReviewNoticePage from './UpgradeRequestIsInReviewNoticePage';
import { SYSTEM_SETTINGS, PROFESSIONAL_UPGRADE_ITEMS, CAN_SUBMIT_NEW_PRO_UPGRADE_REQUEST } from 'Constants/QueriesKeys';
import SubmitProUpgradeRequestSkeleton from './Sketelons/SubmitProUpgradeRequestSkeleton';
import { CheckboxController, UploaderController, Btn, setProgress, BtnBase } from '@aseel/common-ui';

const UPGRADE_BUTTON = 'UPGRADE_BUTTON';

const schema = Yup.object().shape({
    items: Yup.array().of(
        Yup.object().shape({
            checked: Yup.bool(),
            files: Yup.array().when('checked', {
                is: true,
                then: Yup.array().min(1),
                otherwise: Yup.array().nullable(),
            }),
        }),
    ),
});

export default function SubmitProUpgradeRequestPage() {
    const { push } = useHistory();
    const { path } = useRouteMatch();
    const { control, handleSubmit, watch, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            items: [],
        },
    });

    const { t, i18n } = useTranslation();

    const upgradeItemsQuery = useQuery(PROFESSIONAL_UPGRADE_ITEMS, () =>
        http.get('/professional-upgrade/items').then((response) => response.data.data),
    );

    const checkIfCanSubmitQuery = useQuery(CAN_SUBMIT_NEW_PRO_UPGRADE_REQUEST, () =>
        http.get('/professional-upgrade/requests/can-submit').then((response) => response.data.data),
    );

    const systemSettingsQuery = useQuery([SYSTEM_SETTINGS, { lang: i18n.language }], () =>
        http
            .get('/system-settings', {
                params: {
                    lang: i18n.language,
                },
            })
            .then((response) => response.data.data),
    );

    if (upgradeItemsQuery.isLoading || checkIfCanSubmitQuery.isLoading || systemSettingsQuery.isLoading) {
        return <SubmitProUpgradeRequestSkeleton />;
    }

    const beginnerLimit = systemSettingsQuery.data?.beginner_limit_per_listing;
    const upgradeItems = upgradeItemsQuery.data;
    const canSumbitNewRequest = checkIfCanSubmitQuery.data.result;

    const handleUpgradeInvestorProfile = async (values) => {
        setProgress(UPGRADE_BUTTON, true);

        try {
            const form = new FormData();
            values.items.forEach((item, index) => {
                if (!item.checked) {
                    return;
                }

                form.append(`items[${index}][id]`, upgradeItems[index].id);
                item.files.map((file, fileIndex) => {
                    if (file.file instanceof File) {
                        form.append(`items[${index}][files][${fileIndex}]`, file.file);
                    }
                });
            });
            await http.post('/professional-upgrade/requests', form);
            push(`${path}/requests`);
        } catch (error) {
            handleError(error, { setError: setError });
        }
        setProgress(UPGRADE_BUTTON, false);
    };

    if (!canSumbitNewRequest) {
        return <UpgradeRequestIsInReviewNoticePage />;
    }

    const formValues = watch();
    const isAnyConditionSelected = formValues.items.find((item) => item.checked === true);

    return (
        <div className="flex flex-col justify-center flex-grow" style={{ minHeight: '90vh' }}>
            <Box
                className="lg:max-w-3xl py-5 sm:py-0 px-2 sm:px-3.5 md:px-6 lg:px-0 mx-auto h-full"
                sx={{ height: '90vh' }}>
                <Card>
                    <Typography className="p-5" variant="h6">
                        {t('upgradeInvestorLevel.upgradingToProfessionalInvestor')}
                    </Typography>
                    <Divider />
                    <form onSubmit={handleSubmit(handleUpgradeInvestorProfile)}>
                        <div className="pt-4 pb-5 px-5 space-y-2.5" dir={i18n.language === 'en' ? 'ltr' : 'rtl'}>
                            <Typography>
                                {t('upgradeInvestorLevel.introductionToTheItemsConditions', {
                                    beginnerLimit: beginnerLimit,
                                })}
                            </Typography>
                            {upgradeItems.map((item, index) => (
                                <>
                                    <CheckboxController
                                        control={control}
                                        name={`items[${index}].checked`}
                                        label={item.text}
                                    />

                                    {formValues.items[index]?.checked ? (
                                        <UploaderController
                                            control={control}
                                            name={`items[${index}].files`}
                                            label={t('upgradeInvestorLevel.proofDocuments')}
                                        />
                                    ) : null}
                                </>
                            ))}
                        </div>
                        <Divider />
                        <div className="flex justify-between p-5">
                            <BtnBase variant="text" fullWidth={false} onClick={() => push(`${path}/requests`)}>
                                {t('upgradeInvestorLevel.yourRequests')}
                            </BtnBase>
                            <Btn
                                type="submit"
                                fullWidth={false}
                                color="green"
                                name={UPGRADE_BUTTON}
                                disabled={!Boolean(isAnyConditionSelected)}>
                                {t('upgradeInvestorLevel.submitRequest')}
                            </Btn>
                        </div>
                    </form>
                </Card>
            </Box>
        </div>
    );
}
