import React from 'react';
import Kyc from 'Pages/Kyc/Kyc';
import FundPage from 'Pages/Funds/FundPage';
import KycGuard from 'Utils/Guards/KycGuard';
import BasicLayout from 'Layouts/BasicLayout';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import StatusGuard from 'Utils/Guards/StatusGuard';
import { GuardedRoute } from '@aseel/common-utils';
import { Route, Switch, Redirect } from 'react-router-dom';
import RegisterPage from 'Pages/Registration/RegisterPage';
import InvestInFundPage from 'Pages/Funds/InvestInFundPage';
import InvestorStatusPage from 'Pages/InvestorStatus/InvestorStatusPage';
import AfterRegistrationPage from 'Pages/AfterRegistration/AfterRegistrationPage';

function BasicLayoutEntry() {
    return (
        <BasicLayout>
            <Switch>
                <GuardedRoute guards={[GuestGuard]} exact path="/sign-up" component={RegisterPage} />
                <GuardedRoute guards={[AuthGuard]} exact path="/after-registration" component={AfterRegistrationPage} />
                <GuardedRoute guards={[AuthGuard]} exact path="/sign-up/profile" component={Kyc} />
                <GuardedRoute guards={[AuthGuard, KycGuard]} exact path="/status" component={InvestorStatusPage} />
                <GuardedRoute
                    guards={[AuthGuard, StatusGuard]}
                    exact
                    path="/funds/:id/invest"
                    component={InvestInFundPage}
                />

                <Route exact path="/funds/:id" component={FundPage} />

                <Route path="/">
                    <Redirect to="/app/dashboard" />
                </Route>
            </Switch>
        </BasicLayout>
    );
}

export default BasicLayoutEntry;
