import React from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { TwitterIcon } from './Icons/TwitterIcon';
import { InstagramIcon } from './Icons/InstagramIcon';
import { FooterSections } from './Constants/FooterSections';
import aseelLogo from '@aseel/common-assets/assets/aseel-logo-white.png';
import patternGray from '@aseel/common-assets/assets/pattern-random-white.svg';
import { Container, Divider, Link, Typography, Tooltip } from '@mui/material';

const styles = {
    footerFirstSection: {
        padding: (theme) => `${theme.spacing(12)} ${theme.spacing(3)}`,
    },
    footerSecondSection: {
        backgroundColor: (theme) => theme.palette.primary.main,
        padding: (theme) => `${theme.spacing(6)} ${theme.spacing(3)}`,
        color: (theme) => theme.palette.primary.contrastText,
    },
};

export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <Box
            className="bg-no-repeat"
            sx={{
                backgroundColor: '#0e0e0e',
                backgroundImage: `url(${patternGray})`,
            }}>
            <Box sx={styles.footerFirstSection}>
                <Container maxWidth="xl">
                    <div className="grid gap-7 sm:gap-6 sm:grid-cols-3">
                        <div className="self-center">
                            <a className="block" href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}`}>
                                <img src={aseelLogo} className="w-auto mb-4 h-14 lg:h-16" alt="Aseel Logo" />
                            </a>
                            <Typography className="text-gray-300">{t('footer.investInTheBestWorldCities')}</Typography>
                            <div className="flex pt-5 space-s-3">
                                <div className="flex items-center justify-center w-12 h-12 bg-gray-800 rounded-full">
                                    <Tooltip title="Instagram" placement="top">
                                        <Typography
                                            component="a"
                                            href="https://www.instagram.com/investaseel"
                                            target="_blank">
                                            <InstagramIcon classes="text-white" />
                                        </Typography>
                                    </Tooltip>
                                </div>
                                <div className="flex items-center justify-center w-12 h-12 bg-gray-800 rounded-full">
                                    <Tooltip title="Twitter" placement="top">
                                        <Typography
                                            component="a"
                                            href="https://twitter.com/investaseel"
                                            target="_blank">
                                            <TwitterIcon classes="text-white" />
                                        </Typography>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        {FooterSections().map((section, index) => (
                            <div key={index}>
                                <Typography variant="h6" className="mb-4 text-gray-200">
                                    {section.title}
                                </Typography>
                                <ul>
                                    {section.links.map((link, linkIndex) => (
                                        <li key={linkIndex} className="py-1">
                                            <Link
                                                href={link.link}
                                                variant="body1"
                                                underline="none"
                                                className="text-gray-400 hover:text-gray-200">
                                                {link.text}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </Container>
                <Divider className="mx-auto mt-10 bg-gray-700" />
                <div className="flex justify-center space-s-2.5 pt-4 text-gray-300">
                    <Typography className="font-semibold">{t('footer.licenseNumber')}</Typography>
                    <Typography>2313 13123 1323</Typography>
                </div>
                <Typography className="text-gray-300 text-center pt-0.5">{t('footer.copyright')} </Typography>
            </Box>
        </Box>
    );
}
