import useAuth from 'Hooks/UseAuth';
import LineChart from './LineChart';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import DepositeInfo from './DepositInfo';
import { BtnBase } from '@aseel/common-ui';
import { Box, useTheme } from '@mui/system';
import { CardContent } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { investorLevel } from '@aseel/constants';
import Typography from '@mui/material/Typography';
import RoundedCard from 'Components/Cards/RoundedCard';
import OverviewSkeleton from '../Skeletons/OverviewSkeleton';
import { DASHBOARD_OVERVIEW, DASHBOARD_WALLET } from 'Constants/QueriesKeys';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';

export default function Overview() {
    const { user } = useAuth();
    let { t } = useTranslation();
    const { push } = useHistory();
    const { palette } = useTheme();
    const [depositInfo, setDepositeInfo] = useState(false);

    const overviewData = useQuery(DASHBOARD_OVERVIEW, () =>
        http.get('/dashboard/overview').then((response) => response.data.data),
    );

    const balanceInquiry = useQuery(DASHBOARD_WALLET, () =>
        http.get('/balance-inquiry').then((response) => response.data),
    );

    if (overviewData.isLoading || balanceInquiry.isLoading) {
        return <OverviewSkeleton />;
    }

    const handleShowDepositInfo = () => {
        setDepositeInfo(true);
    };

    const handleCloseDepositInfo = () => {
        setDepositeInfo(false);
    };

    const isInvestorBeginner = user.level.value === investorLevel.beginner;

    const overviewDate = [
        {
            title: t('overview.totalAccountValue'),
            value: overviewData.data.total_account_value,
            hasButton: false,
        },
        {
            title: t('overview.totalInvestment'),
            value: overviewData.data.total_investments,
            hasButton: false,
        },
        {
            title: t('overview.unrealizedGains'),
            value: overviewData.data.unrealized_gain,
            hasButton: false,
        },
        {
            title: t('overview.dividends'),
            value: overviewData.data.dividends,
            hasButton: false,
        },
        {
            title: t('overview.availableBalance'),
            value: balanceInquiry.data.balance,
            hasButton: true,
        },
    ];

    return (
        <div>
            <div className="sm:flex sm:justify-between sm:items-center">
                <Typography variant="h4" className="pb-3 font-semibold text-gray-800">
                    {t('overview.title')}
                </Typography>
                {isInvestorBeginner ? (
                    <div>
                        <BtnBase fullWidth={false} onClick={() => push(`professional-upgrade`)}>
                            {t('upgradeInvestorLevel.upgradeToProfessionalInvestor')}
                        </BtnBase>
                    </div>
                ) : null}
            </div>
            <div className="space-y-10">
                <div className="mt-6 space-y-8 md:flex md:items-center lg:space-x-4 md:space-y-0">
                    <div className="flex w-full lg:space-s-3.5 grid grid-cols-1 md:grid-cols-2 gap-5 lg:flex">
                        {overviewDate.map((item) => (
                            <RoundedCard className="lg:w-1/5">
                                <CardContent className="space-y-1.5">
                                    <div className="flex justify-between">
                                        <Typography className="text-xs font-medium text-gray-500">
                                            {item.title}
                                        </Typography>
                                        {item.hasButton ? (
                                            <Box
                                                className="flex space-s-0.5 cursor-pointer"
                                                sx={{
                                                    color: palette.primary.light,
                                                }}
                                                onClick={handleShowDepositInfo}>
                                                <ArrowUpwardRoundedIcon fontSize="small" />
                                                <Typography className="text-xs font-bold">
                                                    {t('common.deposit')}
                                                </Typography>
                                            </Box>
                                        ) : null}
                                    </div>
                                    <Typography variant="h4" className="text-gray-800 truncate pt-0.5">
                                        SR {item.value}
                                    </Typography>
                                </CardContent>
                            </RoundedCard>
                        ))}
                    </div>
                </div>
                <RoundedCard className="p-5 space-y-5 md:p-20">
                    <Typography variant="h4" fontWeight={600} className="text-gray-800">
                        {t('overview.investmentStats')}
                    </Typography>
                    <div style={{ height: '450px' }}>
                        <LineChart />
                    </div>
                </RoundedCard>
            </div>
            <DepositeInfo isOpen={depositInfo} onClose={handleCloseDepositInfo} />
        </div>
    );
}
