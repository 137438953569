import React from 'react';
import Overview from './Components/Overview';

export default function OverviewPage() {
    return (
        <div className="md:py-12 py-6 md:space-y-12 space-y-7">
            <Overview />
        </div>
    );
}
