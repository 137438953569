import useAuth from 'Hooks/UseAuth';
import { Redirect } from 'react-router-dom';

export default function KycGuard(next) {
    const { user } = useAuth();

    if (user.is_kyc_completed === false) {
        return <Redirect to="/sign-up/profile" />;
    }

    return next();
}
