import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { Box } from '@mui/system';
import patternGray from '@aseel/common-assets/assets/pattern-random-gray.svg';

function PatternGrayBackground({ classes, sx }) {
    return (
        <Box
            className={['bg-no-repeat'].concat(classes).join(' ')}
            sx={{
                backgroundImage: `url(${patternGray})`,
                ...sx,
            }}></Box>
    );
}

export default function BasicLayout({ children }) {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <Box className="relative flex flex-col flex-grow">
                {children}
                <PatternGrayBackground sx={{ zIndex: -1 }} classes="p-28 absolute top-0 left-0" />
                <PatternGrayBackground sx={{ zIndex: -1, top: '600px' }} classes="p-28 absolute right-0" />
            </Box>

            <Footer />
        </div>
    );
}
