import React from 'react';
import MyWallet from './Components/MyWallet';
import BankAccounts from './BankAccounts/BankAccounts';
import MyTransactions from './Components/MyTransactions';
import InvestingRecords from './InvestingRecords/InvestingRecords'

export default function DashboardWallet() {
    return (
        <div className="md:py-12 py-6 md:space-y-12 space-y-7 ">
            <MyWallet />
            <MyTransactions />
            <InvestingRecords />
            <BankAccounts />
        </div>
    );
}


