import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { BtnBase, DateRangePickerController, RadioGroupController } from '@aseel/common-ui';

export const exportingReportFormats = {
    xlsx: 'xlsx',
    csv: 'csv',
};

const schema = Yup.object().shape({
    extension: Yup.string().required(),
    date_range: Yup.array().of(Yup.date().required().nullable()),
});

const formDefaultValues = {
    extension: exportingReportFormats.xlsx,
};

export default function ExportReportDialog({
    isOpen,
    urlGenerator,
    defaultValues,
    isExportReportsDialogOpen,
    setIsExportReportsDialogOpen,
}) {
    const { t } = useTranslation();
    const anchorEl = useRef();

    const { control, reset, trigger, getValues } = useForm({
        resolver: yupResolver(schema),
        defaultValues: formDefaultValues,
    });

    useEffect(() => {
        if (isEqual(getValues(), defaultValues) === false && defaultValues !== undefined) {
            reset({
                ...defaultValues,
                ...formDefaultValues,
            });
        }

        if (isExportReportsDialogOpen && defaultValues === undefined) {
            reset();
        }
    }, [isExportReportsDialogOpen, defaultValues, getValues, reset]);

    const exportFormatOptions = [
        {
            value: exportingReportFormats.xlsx,
            label: t('exportReports.xlsx'),
        },
        {
            value: exportingReportFormats.csv,
            label: t('exportReports.csv'),
        },
    ];

    const handleExportReports = async () => {
        const isValid = await trigger();

        if (!isValid) {
            return;
        }

        const formValues = getValues();
        const dateRange = formValues.date_range;
        const startDate = dayjs(dateRange[0]).format('YYYY-MM-DD');
        const endDate = dayjs(dateRange[1]).format('YYYY-MM-DD');

        const formData = {};
        formData.start_date = startDate;
        formData.end_date = endDate;
        formData.extension = formValues.extension;

        if (!anchorEl.current) {
            return;
        }

        anchorEl.current.href = urlGenerator(formData);
        anchorEl.current.click();

        setIsExportReportsDialogOpen(false);
    };

    return (
        <Dialog open={isOpen} fullWidth>
            <DialogContent>
                <form className="space-y-4">
                    <div className="flex items-center justify-between">
                        <Typography variant="h6" className="font-medium">
                            {t('exportReports.exportReport')}
                        </Typography>
                        <IconButton onClick={() => setIsExportReportsDialogOpen(false)}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <DateRangePickerController
                        control={control}
                        name="date_range"
                        maxDate={dayjs()}
                        endText={t('exportReports.endDate')}
                        startText={t('exportReports.startDate')}
                    />
                    <RadioGroupController
                        row
                        control={control}
                        name="extension"
                        options={exportFormatOptions.map(({ label, value }) => ({ label: label, value: value }))}
                    />
                    <div className="flex justify-end">
                        <a ref={anchorEl} hidden href="#" download></a>
                        <BtnBase variant="text" fullWidth={false} onClick={handleExportReports}>
                            {t('exportReports.startExporting')}
                        </BtnBase>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}
