import React from 'react';
import LocaleField from '../LocaleField';
import SectionTitle from '../SectionTitle';
import {  Skeleton } from '@mui/material';

export default function SubscriptionFormSkeleton() {
    return (
        <div style={{ direction: 'rtl' }}>
            <LocaleField name={{ en: 'Subscription form number', ar: 'رقم نموذج الاشتراك' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Date', ar: 'التاريخ' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>

            <div className="flex">
                <Skeleton className="w-24 mx-auto" />
                <Skeleton className="w-24 mx-auto" />
            </div>

            <LocaleField name={{ en: 'Investor No.', ar: 'رقم المستثمر' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>

            <SectionTitle className="mt-2" title={{ en: 'Investment Particulars', ar: 'تفاصيل الاستثمار' }} />

            <LocaleField name={{ en: 'Fund name', ar: 'اسم الصندوق' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Fund code', ar: 'رقم الصندوق' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Currency', ar: 'العملة' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Amount (in figure)', ar: 'المبلغ بالأرقام' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Amount (in words)', ar: 'المبلغ كتابة' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Value date', ar: 'تاريخ التنفيذ' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <SectionTitle className="mt-2" title={{ en: "Investor's Particulars", ar: 'تفاصيل المستثمر' }} />
            <LocaleField name={{ en: 'Type of investor', ar: 'نوع المستثمر' }}></LocaleField>
            <div className="sm:flex sm:flex-wrap">
                <Skeleton className="w-24 mx-auto" />
                <Skeleton className="w-24 mx-auto" />
                <Skeleton className="w-24 mx-auto" />
            </div>

            <LocaleField name={{ en: 'Name', ar: 'الاسم' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'ID/Iqama/C.R. No.', ar: 'بطاقة/إقامة/سجل تجاري/ رقم' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Nationality', ar: 'الجنسية' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'P.O. Box', ar: 'صندوق بريد' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'City', ar: 'المدينة' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'P.Code', ar: 'الرمز البريدي' }}>
                <Skeleton className="w-24 mx-auto" />
            </LocaleField>
            <LocaleField name={{ en: 'Mobile', ar: 'جوال' }}>
                <div dir="ltr">
                    <Skeleton className="w-24 mx-auto" v />
                </div>
            </LocaleField>

            <SectionTitle className="mt-2" title={{ en: 'Terms And Conditions', ar: 'الشروط والأحكام' }} />
            <div className="pt-2.5">
                <Skeleton height="400px" variant="rectangular" />
            </div>
        </div>
    );
}
