import React from 'react';
import { Divider } from '@mui/material';
import LocaleField from './LocaleField';

export default function SectionTitle({ title }) {
    return (
        <div>
            <LocaleField variant="h6" name={title}></LocaleField>
            <Divider />
        </div>
    );
}
