import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Btn, InputController } from '@aseel/common-ui';
import UserIdentityInformation from '../Components/UserIdentityInformation';
import UserInformationSkeleton from '../Skeletons/UserInformationSkeleton';

export default function VerifyCode({ form, verifyButtonName, handleVerifyCode, absherInformationQuery }) {
    const { t } = useTranslation();

    const absherInformation = absherInformationQuery.data;

    if (absherInformation === undefined || absherInformation.isLoading) {
        return <UserInformationSkeleton />;
    }

    if (absherInformation.is_identity_verified) {
        return <UserIdentityInformation absherInformation={absherInformation} containerClassName="space-y-5 mt-5" />;
    }

    return (
        <div className="p-5">
            <Typography variant="h5">{t('kyc.verifyYourIdentity')}</Typography>
            <form onSubmit={form.handleSubmit(handleVerifyCode)}>
                <div className="py-3.5">
                    <Typography variant="body2">{t('kyc.youWillReceiveACodeOnYourPhoneNumberInAbsher')}</Typography>
                    <div className="py-2.5 space-y-1">
                        <div className="flex py-1.5 sm:w-1/2">
                            <InputController
                                control={form.control}
                                name="code"
                                label={t('kyc.code')}
                                className="me-5"
                            />
                            <Btn name={verifyButtonName} type="submit" variant="text" fullWidth={false}>
                                {t('kyc.verify')}
                            </Btn>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
