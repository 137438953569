import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router-dom';
import { Card, Typography } from '@mui/material';
import { FUND_REPORTS } from 'Constants/QueriesKeys';
import ReportInfo from 'Pages/Dashboard/Funds/Reports/Components/ReportInfo';
import ReportSkeleton from './Skeletons/ReportSkeleton';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/system';

const styles = {
    reportsContainer: {
        gridTemplateColumns: {
            xl: 'repeat(auto-fit, 400px) !important',
        },
    },
};

function ReportsPage() {
    const { fundId } = useParams();
    const [page, setPage] = useState(1);
    const [reportInfo, setReportInfo] = useState({
        show: false,
        report: null,
    });

    const handleShowReportInfo = (item) => {
        setReportInfo({
            show: true,
            report: item,
        });
    };

    const handleCloseReportInfo = () => {
        setReportInfo({
            ...reportInfo,
            show: false,
        });
    };

    const reports = useQuery([FUND_REPORTS, { reportId: fundId, page }], () =>
        http
            .get(`/listings/${fundId}/reports`, {
                params: {
                    page,
                },
            })
            .then((response) => response.data),
    );

    if (reports.isLoading) {
        return <ReportSkeleton />;
    }
    const reportsRows = reports.data.data;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className="px-6 py-10 space-y-5">
            <ReportInfo isOpen={reportInfo.show} onClose={handleCloseReportInfo} reportInfo={reportInfo.report} />
            <Box className="grid md:grid-cols-4 sm:grid-cols-2 md:gap-10 gap-8" sx={styles.reportsContainer}>
                {reportsRows.map((item) => (
                    <Card className="cursor-pointer" onClick={() => handleShowReportInfo(item)}>
                        <div className="ps-5 py-6">
                            <Typography variant="body2" color="textSecondary">
                                {item.created_at}
                            </Typography>
                            <Typography variant="subtitle1" className="font-bold">
                                {item.title}
                            </Typography>
                        </div>
                    </Card>
                ))}
            </Box>
            {reports.data?.meta.last_page === 1 ? null : (
                <Pagination count={reports.data?.meta.last_page} color="primary" page={page} onChange={handleChange} />
            )}
        </div>
    );
}

export default ReportsPage;
