import { NavLink } from 'react-router-dom';

export const getNavProps = (navItem, auth) => {
    if (!navItem.isVisible(auth)) {
        return null;
    }

    const props = {};

    if (navItem.type === 'route') {
        props.component = NavLink;
        props.to = navItem.path;
    } else if (navItem.type === 'url') {
        props.component = 'a';
        props.href = navItem.link;
    }

    return props;
};
