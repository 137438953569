import {
    FormControl,
    FormControlLabel as FormControlLabelMui,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup as RadioGroupMui,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export default function RadioGroup({
    options,
    label,
    onChange,
    name,
    row,
    helperText,
    error,
    value,
    icon,
    className,
    checkedIcon,
    RadioGroupClassName,
    FormControlLabelComponent,
    formControlLabelSx,
}) {
    let FormControlLabel = FormControlLabelMui;

    if (FormControlLabelComponent !== undefined) {
        FormControlLabel = FormControlLabelComponent;
    }

    return (
        <FormControl error={error} className={className || 'block'} component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroupMui value={value} className={RadioGroupClassName} row={row} name={name} onChange={onChange}>
                {options?.map((o) => (
                    <FormControlLabel
                        key={o.value}
                        name={name}
                        value={o.value}
                        label={o.label}
                        sx={formControlLabelSx}
                        control={<Radio checkedIcon={checkedIcon} icon={icon} />}
                    />
                ))}
            </RadioGroupMui>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

RadioGroup.propTypes = {
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    row: PropTypes.bool,
    error: PropTypes.bool,
    className: PropTypes.string,
};
