import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { BtnBase } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import ShareFundBtns from 'Components/Funds/ShareFundBtns';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export default function SuccessfullyInvested({ fundId, fundName, investedUnits }) {
    const { t, i18n } = useTranslation();
    const fundUrl = `${window.location.origin}/funds/${fundId}`;

    return (
        <div>
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                <CheckRoundedIcon className="w-8 h-8 text-green-600" />
            </div>
            <div className="mt-2 text-center">
                <Typography component="h1" variant="h6">
                    {t('fund.investmentWasSuccessfulTitle')}
                </Typography>
                <Typography variant="body1">
                    {t('fund.investmentWasSuccessfulMessage', { units: investedUnits, fundName })}
                </Typography>

                <div className="max-w-xs mx-auto mt-4">
                    <BtnBase component={Link} to="/app/overview" className="mb-2">
                        {t('common.myProfile')}
                    </BtnBase>
                    <BtnBase variant="outlined" href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}#funds`}>
                        {t('common.exploreFunds')}
                    </BtnBase>
                </div>

                <ShareFundBtns
                    className="flex justify-center mt-6 space-s-3"
                    title={t('fund.shareFundAfterInvestingText', { fundName })}
                    url={fundUrl}
                />
            </div>
        </div>
    );
}
